import dayjs from "dayjs";
import download from "downloadjs";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { statusColors } from "./statuses";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import ChipStatus from "../components/ChipStatus";
import useOpenNewForm from "../hooks/useOpenNewForm";
import Spinner from "../components/Spinners/Spinner";
import { useCallback, useMemo, useState } from "react";
import { clientInvoicesService } from "../api/clientInvoices";
import FormsDataGrid from "../components/DataGrid/FormsDataGrid";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import PersonIcon from "@mui/icons-material/Person";
import InfoModal from "../components/Modals/InfoModal";
import SubmitInvoicesForm from "../components/forms/ClientInvoices/ActionForms/SubmitInvoicesForm/SubmitInvoicesForm";

const groupByInvoiceNo = (rows) => {
  const grouped = [];

  rows?.forEach((row) => {
    const { InvoiceNo } = row;
    let group = grouped.find((g) => g.invoiceNo === InvoiceNo);

    if (!group) {
      group = {
        invoiceNo: InvoiceNo,
        rows: [],
      };
      grouped.push(group);
    }

    group.rows.push(row);
  });

  return grouped;
};

export function DetailPanelContent({ rows }) {
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);
  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/quotations/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 110,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 120,
    },
    {
      field: "D6InvoicedAmount",
      headerName: t("data_grid.headers.invoiced_amount"),
      width: 120,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.acceptance_status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Box sx={{ ml: 6.7 }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.WorkOrderD6No}
        hideFooter
        height="auto"
        backgroundDetails={theme === "dark" ? "#2b3238" : "#54585d"}
        titleDetails="11px"
        cellFontDetails="10.6px"
        headerHeight={24}
      />
    </Box>
  );
}

const ClientInvoices = () => {
  const { newForm } = useSelector((state) => state.theme);
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();
  const [t] = useTranslation("global");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isBulkExportClicked, setIsBulkExportClicked] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // General search input
  const statusNames = [
    {
      name: "Statement Phase",
      displayName: t("data_grid.tabs.statement_phase"),
    },
    { name: "Invoice Phase", displayName: t("data_grid.tabs.invoice_phase") },
    {
      name: "Submitted for Payment",
      displayName: t("data_grid.tabs.submitted_for_payment"),
    },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
  ];

  // Fetch invoices data
  const {
    data: rows = [],
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["clientInvoices"],
    queryFn: clientInvoicesService.getClientInvoices,
  });

  // Group the rows by InvoiceNo
  const groupedRows = useMemo(() => groupByInvoiceNo(rows), [rows]);

  // Handle row selection for bulk export
  const handleRowSelection = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  // Filter invoices by search input across all columns
  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]); // Show first row from each group in original order
    }

    return groupedRows
      .map((group) => group.rows[0]) // Get the first row of each group
      .filter((invoice) => {
        // Check if any of the fields contain the search input
        return Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
  }, [groupedRows, searchInput]);

  // Define detail panel content for grouped rows
  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows} />;
    },
    [groupedRows]
  );

  const exportButtonLabel =
    selectedRows.length > 0
      ? "data_grid.buttons.export_selected"
      : "data_grid.buttons.bulk_export";

  const handleBulkExport = () => {
    if (!isBulkExportClicked) {
      setIsBulkExportClicked(true);
      return;
    }
    if (selectedRows.length === 0) {
      setIsBulkExportClicked(false);
      return;
    }
    refetchInvoicesFile().then((response) => {
      download(response.data, "Statement", "application/pdf");
    });
  };

  // Handle open Submit modal
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);

  const actions = ["submit"];

  const actionsConfig = {
    submit: {
      label: t("data_grid.buttons.submit"),
      icon: <PersonIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: handleOpenSubmitModal,
    },
  };

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/clientinvoices/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 110,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 140,
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 140,
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 130,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 130,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.invoice_amount"),
      width: 90,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.invoice_type"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.assigned_on"),
      width: 140,
      flex: 1,
    },
    {
      field: "SignedDate",
      headerName: t("data_grid.headers.signed_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "PriceConfirmationDate",
      headerName: t("data_grid.headers.price_confirmation_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "TechnicalApprovalDate",
      headerName: t("data_grid.headers.technical_approval_date"),
      width: 140,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.invoice_status"),
      headerAlign: "center",
      width: 190,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "StatementStatus",
      headerName: t("data_grid.headers.statement_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  // Fetch invoices bulk export query
  const { refetch: refetchInvoicesFile } = useQuery({
    queryKey: ["clientInvoicesFile"],
    queryFn: () => clientInvoicesService.exportInvoices(selectedRows),
    enabled: false,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        enableRowSelection={isBulkExportClicked}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleRowSelection}
        rows={filteredInvoices} // Show filtered invoices based on search input
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.InvoiceNo}
        searchValueCustom
        customSearchValue={searchInput}
        customSearchOnChange={(e) => setSearchInput(e.target.value)}
        statusColors={statusColors}
        filterField="InvoiceStatus"
        showReloadOverlay={isRefetching}
        reloadFunction={refetch}
        builtInSearch={true}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={getDetailPanelContent} // Detail panel for grouped rows
        statusNames={statusNames}
        newButton
        newFunction={() => {
          if (newForm === "client_invoice" || newForm === null) {
            handleOpen("client_invoice");
          } else {
            setOpen(t("modules.new_form_confirmation_message"), () => () => {
              handleOpen("client_invoice");
            });
          }
        }}
        bulkExportBtn
        bulkExportFunction={handleBulkExport}
        bulkExportButtonLabel={exportButtonLabel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              CreationDate: false,
              D6Date: false,
              ERPUserNickName: false,
              SignedDate: false,
              PriceConfirmationDate: false,
              TechnicalApprovalDate: false,
              StatementStatus: false,
            },
          },
        }}
        actionsList={{
          actions,
          actionsConfig,
        }}
      />
      <InfoModal
        open={openSubmitModal}
        handleClose={handleCloseSubmitModal}
        disableCloseOnBackdropClick
        width="70rem"
        title={t("modules.client_invoice.submit_invoices_form.title")}
        content={<SubmitInvoicesForm handleClose={handleCloseSubmitModal} />}
      />
    </>
  );
};

export default ClientInvoices;
