import * as Yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../../components/Modals/InfoModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, TextField, Chip, CircularProgress } from "@mui/material";
import BasicDatePicker from "../../../../components/DateAndTime/DatePicker";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";

const InvoiceModal = ({
  open,
  handleClose,
  clientInvoicesInfo,
  selectedNo,
  loading,
}) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    govNo: Yup.string().required("Required"),
  });

  const initialValues = {
    govNo: clientInvoicesInfo?.GovernmentInvoice || "",
    invoiceDate:
      clientInvoicesInfo?.InvoiceDate === null
        ? dayjs().format("YYYY/MM/DD")
        : clientInvoicesInfo?.InvoiceDate,
    technicalDate:
      clientInvoicesInfo?.TechnicalApprovalDate === null
        ? ""
        : clientInvoicesInfo?.TechnicalApprovalDate,
    priceDate:
      clientInvoicesInfo?.PriceConfirmationDate === null
        ? ""
        : clientInvoicesInfo?.PriceConfirmationDate,
    signDate:
      clientInvoicesInfo?.SignedDate === null
        ? ""
        : clientInvoicesInfo?.SignedDate,
  };

  const {
    handleSubmit,
    values,
    resetForm,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      if (Object.keys(errors).length !== 0) {
        return;
      } else {
        invoice();
      }
    },
  });

  const handleCloseSave = () => {
    handleClose();
    resetForm();
  };
  const isValidDates = [
    "invoiceDate",
    "technicalDate",
    "priceDate",
    "signDate",
  ].every((date) => values[date] && dayjs(values[date]).isValid());

  const { mutateAsync: invoice, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientInvoices", {
        State: "Invoice",
        SelectedNo: selectedNo,
        GovernmentInvoice: values.govNo,
        SignedDate: dayjs(values.signDate).format("YYYY-MM-DD"),
        InvoiceDate: dayjs(values.invoiceDate).format("YYYY-MM-DD"),
        PriceConfirmationDate: dayjs(values.priceDate).format("YYYY-MM-DD"),
        TechnicalApprovalDate: dayjs(values.technicalDate).format("YYYY-MM-DD"),
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar(t("Invoiced Successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return [
            "clientInvoice",
            "statementsManagement",
            "dashboardData",
            "dashboardNoSubmissionDate",
            "dashboardNoGov",
          ].includes(query.queryKey[0]);
        },
      });
      handleCloseSave();
    },
  });

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.client_invoices.description.edit_gov_code")}
      width="28rem"
      content={
        loading ? (
          <Box
            display="flex"
            justifyContent="center"
            minHeight="15rem"
            minWidth="30rem"
            alignItems="center"
          >
            <CircularProgress
              sx={{
                color: "primary",
              }}
            />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box padding={1} display="flex" flexDirection="column" gap={1.5}>
              <Chip
                label={selectedNo}
                sx={{ borderRadius: 1, fontSize: "0.9rem" }}
              />
              <TextField
                label={t(
                  "info_modules.client_invoices.description.gov_invoice_no"
                )}
                size="small"
                value={values.govNo}
                onChange={(event) => {
                  setFieldValue("govNo", event.target.value);
                }}
                onBlur={handleBlur}
                error={errors.govNo && touched.govNo}
                helperText={errors.govNo && touched.govNo && errors.govNo}
              />
              <BasicDatePicker
                dateChange={dayjs(values.invoiceDate)}
                width="27rem"
                handleDateChange={(date) => {
                  setFieldValue("invoiceDate", date);
                }}
                label={t(
                  "info_modules.client_invoices.description.invoice_date"
                )}
              />
              <BasicDatePicker
                dateChange={dayjs(values.technicalDate)}
                width="27rem"
                handleDateChange={(date) => {
                  setFieldValue("technicalDate", date);
                }}
                label={t(
                  "info_modules.client_invoices.description.technical_approval_date"
                )}
                disabled={
                  clientInvoicesInfo?.StatementStatus ===
                    "Price Confirmation" ||
                  clientInvoicesInfo?.StatementStatus === "Signed" ||
                  clientInvoicesInfo?.StatementStatus === "Technical Approval"
                }
              />
              <BasicDatePicker
                dateChange={dayjs(values.priceDate)}
                width="27rem"
                handleDateChange={(date) => {
                  setFieldValue("priceDate", date);
                }}
                label={t(
                  "info_modules.client_invoices.description.price_confirmation_date"
                )}
                disabled={
                  clientInvoicesInfo?.StatementStatus ===
                    "Price Confirmation" ||
                  clientInvoicesInfo?.StatementStatus === "Signed"
                }
              />
              <BasicDatePicker
                dateChange={dayjs(values.signDate)}
                width="27rem"
                handleDateChange={(date) => {
                  setFieldValue("signDate", date);
                }}
                label={t(
                  "info_modules.client_invoices.description.signed_date"
                )}
                disabled={clientInvoicesInfo?.StatementStatus === "Signed"}
              />
              <ButtonGroup
                onClickClose={handleClose}
                isPending={isPending}
                onClickSave={handleSubmit}
                saveLabel={t("modules.buttons.save")}
                disabled={!isValidDates || isPending}
              />
            </Box>
          </form>
        )
      }
    />
  );
};

export default InvoiceModal;
