import { Box } from "@mui/material";
import ChipStatus from "../../../ChipStatus";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomAccordion from "../../../Accordion/CustomAccordion";
import { generateId } from "../../../../utils/globalFunctions";

const Review = ({
  workOrderRows,
  itemsRows,
  itemsColumns,
  isThreeStepProcess,
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [t] = useTranslation("global");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const workOrderColumns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.received_date"),
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      flex: 1,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Box sx={{ margin: "15px 2px" }}>
      {isThreeStepProcess === false && (
        <CustomAccordion
          title={t("modules.material_request.label.work_orders")}
          rows={workOrderRows}
          columns={workOrderColumns}
          height={300}
          getRowId={(row) => row.idWorkOrder}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        />
      )}

      <CustomAccordion
        title={t("modules.material_request.label.items")}
        rows={itemsRows}
        columns={itemsColumns}
        height={300}
        getRowId={(row) => row.idStoreItemsTemplateInfo || generateId()}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      />
    </Box>
  );
};
export default Review;
