import { Box } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Image from "@mui/icons-material/Image";
import { useConfirmationDialog } from "../../../../../contexts/ConfirmationDialogContext";
import ImageModal from "../../../../../components/Modals/ImageModal";
import { useState } from "react";
import { convertImage } from "../../../../../utils/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import { useSnackbar } from "notistack";
import InfoModal from "../../../../../components/Modals/InfoModal";
import NewDocument from "./NewDocument";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const Documents = ({ data }) => {
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [image, setImage] = useState(null);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleOpenImageModal = (documentNo) => {
    setIsImageLoading(true);
    setIsImageModalOpen(true);
    employeeRecordsService
      .getEmployeeDocument(documentNo)
      .then((data) => {
        const convertedImage = convertImage(data[0].EmpInfoDocument.data);
        setImage(convertedImage);
      })
      .then(() => setIsImageLoading(false));
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const { setOpen } = useConfirmationDialog();

  const { mutateAsync: deleteEmployeeDocument } = useMutation({
    mutationFn: employeeRecordsService.deleteEmployeeDocument,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["employeeInfo"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const columns = [
    {
      field: "EmpInfoDocumentName",
      headerName: t("data_grid.headers.document_name"),
      flex: 1,
    },
    {
      field: "EmpInfoDocumentDate",
      headerName: t("data_grid.headers.document_date"),
      flex: 1,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "Actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          key="image"
          icon={<Image />}
          label="Image"
          onClick={() => {
            handleOpenImageModal(params.row.idEmpInfoDocuments);
          }}
        />,
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => {
            setOpen(
              `${t("info_modules.employee_records.confirmation.delete")} 
              ${params.row.EmpInfoDocumentName} ?`,
              () => () => {
                deleteEmployeeDocument(params.row.idEmpInfoDocuments);
              },
              "error"
            );
          }}
        />,
      ],
    },
  ];

  // Handle Add new document
  const [newButtonOpen, setNewButtonOpen] = useState(false);

  const handleOpenNewButton = () => {
    setNewButtonOpen(true);
  };

  const handleCloseNewButton = () => {
    setNewButtonOpen(false);
  };

  return (
    <>
      <InfoCard
        addIcon
        onClick={handleOpenNewButton}
        title={t("info_modules.employee_records.cards.documents.title")}
        content={
          <Box>
            <FormsDataGrid
              height={data.length === 0 ? 100 : 400}
              rows={data}
              columns={columns}
              getRowId={(row) => row.idEmpInfoDocuments}
              hideFooter={data.length === 0}
            />
          </Box>
        }
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
      />
      <InfoModal
        open={newButtonOpen}
        handleClose={handleCloseNewButton}
        title={t(
          "info_modules.employee_records.cards.documents.new_form.title"
        )}
        content={<NewDocument handleClose={handleCloseNewButton} />}
      />
    </>
  );
};

export default Documents;
