import { useEffect } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import ChipStatus from "../../../ChipStatus";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const SelectWorkOrder = ({
  rows,
  onRowSelection,
  selectedRows,
  setSelectedRows,
  setNextDisabled,
  isUseTemplate,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.work_order_no"),
      width: 120,
    },

    {
      field: "WorkOrderDiscription",
      headerName: t("data_grid.headers.description"),
      width: 140,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 110,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 110,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 100,
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.date"),
      width: 120,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 120,
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.status"),
      width: 150,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const handleRowSelectionModelChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
    const selectedSubProjects = rows
      .filter((row) => newSelectedRows.includes(row.idWorkOrder))
      .map((row) => row.SubProjectsName);
    if (new Set(selectedSubProjects).size > 1) {
      setNextDisabled(true);
      enqueueSnackbar(t("modules.error_messages.selected_work_orders"), {
        variant: "error",
      });
    } else {
      setNextDisabled(false);
    }
  };
  const handleSelectionTemplate = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.idWorkOrder}
        height={380}
        showQuickFilter
        checkboxSelection
        toolbar
        disableSelectionOnClick
        onRowSelectionModelChange={
          isUseTemplate
            ? handleSelectionTemplate
            : handleRowSelectionModelChange
        }
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
};
export default SelectWorkOrder;
