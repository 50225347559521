import Axios from "../network/Axios";

const FILE_FOLDERS_PATH = "/cloudstorage";

const getAllFilesAndFolders = async (path) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "Read",
      Directory: path,
    },
  });
  return response.data.msg;
};

// Get file
const getFile = async (path, fileName) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "DownloadFile",
      Directory: path,
      FileName: fileName,
    },
  });
  return response.data.msg;
};

// Get DB and Cloud data
const getDBAndCloudData = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "Migrate",
    },
  });
  return response.data;
};

const getMigrateStatus = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "MigrateStatus",
    },
  });
  return response.data;
};
const getLogs = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "GetLog",
    },
  });
  return response.data;
};
const getLogsByDate = async (startDate, endDate) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "GetLog",
      Start: startDate,
      End: endDate,
    },
  });
  return response.data;
};
const updateData = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "UpdateData",
    },
  });
  return response.data;
};
const migrationStart = async (action, deleteSrc) => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "MigrateStart",
      Action: action,
      Delete: deleteSrc,
    },
  });
  return response.data;
};
const cancelMigration = async () => {
  const response = await Axios.get(FILE_FOLDERS_PATH, {
    params: {
      State: "CancelMigration",
    },
  });
  return response.data;
};

export const filesFoldersService = {
  getAllFilesAndFolders,
  getFile,
  getDBAndCloudData,
  getMigrateStatus,
  getLogs,
  getLogsByDate,
  updateData,
  migrationStart,
  cancelMigration,
};
