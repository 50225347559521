import { useQuery } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../api/employeeRecords";
import FormSpinner from "../../../Spinners/FormSpinner";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertImage } from "../../../../utils/utils";

const NewInfoField = ({ label, name, errors, register }) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      sx={{
        position: "relative",
      }}
    >
      <TextField
        size="small"
        label={label}
        error={Boolean(errors[name])}
        helperText={errors[name]?.message}
        {...register(name)}
        sx={{
          flexGrow: 1,
        }}
        required
      />
    </Stack>
  );
};

const BasicInformation = ({
  isError,
  empCode,
  values,
  errors,
  dispatch,
  register,
}) => {
  const [t] = useTranslation("global");

  const { username, nickname } = values;

  const { data, isLoading } = useQuery({
    queryKey: ["employeeData"],
    queryFn: () => employeeRecordsService.GetEmployeeData(empCode),
  });

  if (isLoading) return <FormSpinner />;

  return (
    <Stack direction={"row"} justifyContent={"space-evenly"}>
      <Stack justifyContent={"center"} spacing={3}>
        <Stack spacing={1}>
          <Stack direction={"row"} spacing={1}>
            <Typography fontWeight={"500"}>
              {t("modules.users_management.new_form.labels.english_name")}:
            </Typography>
            <Typography>{data.EmpNameEn}</Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography fontWeight={"500"}>
              {t("modules.users_management.new_form.labels.arabic_name")}:
            </Typography>
            <Typography>{data.EmpName}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <NewInfoField
            label={t("modules.users_management.new_form.fields.username")}
            value={username}
            name="username"
            reactQueryKey="employeeUsername"
            getFunction="CheckUsername"
            errors={errors}
            isError={isError}
            dispatch={dispatch}
            register={register}
          />
          <NewInfoField
            label={t("modules.users_management.new_form.fields.display_name")}
            value={nickname}
            name="nickname"
            reactQueryKey="employeeNickname"
            getFunction="CheckNickname"
            errors={errors}
            isError={isError}
            dispatch={dispatch}
            register={register}
          />

          <TextField
            type="email"
            size="small"
            label={t("modules.users_management.new_form.fields.user_email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            {...register("email")}
            required
          />
        </Stack>
      </Stack>

      <Box
        sx={{
          border: "1px solid",
          borderColor: "secondary.main",
          borderRadius: 2,
          overflow: "hidden",
          width: "300px",
          height: "300px",
        }}
      >
        <img
          src={convertImage(data?.EmpPhoto?.data)}
          alt="employee"
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </Box>
    </Stack>
  );
};

export default BasicInformation;
