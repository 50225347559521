import { Box, Stack, Typography } from "@mui/material";
import FormsDataGrid from "../../../../../DataGrid/FormsDataGrid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Review = ({ selectedData, columns, formFields }) => {
  const [t] = useTranslation("global");

  return (
    <Stack spacing={2} pb={1}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>
            {t("modules.client_invoice.labels.submission_date")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("modules.client_invoice.fields.submission_date")}
              value={formFields.date.value}
              onChange={(date) => formFields.date.setValue(date)}
              format="YYYY/MM/DD"
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "14px",
                },
              }}
              slotProps={{
                textField: {
                  size: "small",
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <FormsDataGrid
        rows={selectedData || []}
        columns={columns}
        getRowId={(row) => row.InvoiceNo}
        height={300}
        disableColumnMenu
        disableColumnResize
      />
    </Stack>
  );
};

export default Review;
