import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "../../../network/Axios";
import AddItems from "./InfoModals/AddItems";
import { Tooltip, Box } from "@mui/material";
import Image from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import SubmitModal from "./InfoModals/SubmitModal";
import DeleteIcon from "@mui/icons-material/Delete";
import InvoiceModal from "./InfoModals/InvoiceModal";
import ChipStatus from "../../../components/ChipStatus";
import useImagePopup from "../../../hooks/useImagePopup";
import Spinner from "../../../components/Spinners/Spinner";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { generateId } from "../../../utils/globalFunctions";
import InfoModal from "../../../components/Modals/InfoModal";
import InvoicesSnapShot from "./InfoModals/InvoicesSnapShot";
import ImageModal from "../../../components/Modals/ImageModal";
import { useParams, useNavigate, Link } from "react-router-dom";
import ReplayTwoToneIcon from "@mui/icons-material/ReplayTwoTone";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import InvoiceDescriptionCard from "./InfoModals/InvoiceDescriptionCard";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import StatementStatusForm from "./InfoModals/StatementStatusForms";
import ChangeGovInv from "./InfoModals/ChangeGovInv";

const ClientInvoicesInfo = () => {
  const { clientInvoiceId } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditable, setIsEditable] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [changeGovInvModal, setChangeGovInvModal] = useState(false);
  const [addItemsModal, setAddItemsModal] = useState(false);
  const [d6Modal, setD6Modal] = useState(false);
  const [d6RefNo, setD6RefNo] = useState(null);
  const [statementStatusForm, setStatementStatusForm] = useState(false);

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmDeleteInvoice":
        return "deleteInvoice";
      case "confirmCancel":
        return "cancel";
      case "confirmChangeCreate":
        return "changeCreate";
      case "confirmRequestApproval":
        return "requestApproval";
      case "confirmDeleteItem":
        return "deleteItem";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "invoice":
        setInvoiceModal(true);
        break;
      case "submit":
        setSubmitModal(true);
        break;

      case "changeGovInv":
        setChangeGovInvModal(true);
        break;

      case "addItems":
        setAddItemsModal(true);
        break;
      case "statementStatusForm":
        setStatementStatusForm(true);
        break;
      case "confirmDeleteItem":
      case "confirmRequestApproval":
      case "confirmDeleteInvoice":
      case "confirmCancel":
      case "confirmChangeCreate":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };

  const { data: clientInvoicesInfo, isLoading } = useQuery({
    queryKey: ["clientInvoice", "info", clientInvoiceId],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          SelectedNo: clientInvoiceId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });

  const { data, isLoading: loadingData } = useQuery({
    queryKey: ["edit", "govInvoice"],
    queryFn: async () => {
      const response = await Axios.get("/StatementsManagement", {
        params: {
          SelectedNo: clientInvoiceId,
          State: "Edit",
        },
      });
      return response.data.result[0];
    },
    enabled: invoiceModal,
  });

  const { mutateAsync: handleDelete, isPending: pendingDelete } = useMutation({
    mutationFn: async () => {
      return Axios.delete("/ClientInvoices", {
        params: {
          SelectedNo: clientInvoiceId,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Invoice Deleted Successfully", {
        variant: "success",
      });
      navigate("/clientinvoices");
      queryClient.invalidateQueries({
        queryKey: ["clientInvoices"],
      });
    },
  });

  const { mutateAsync: handleCancel, isPending: pendingCancel } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientInvoices", {
        State: "Cancel",
        SelectedNo: clientInvoiceId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Status Changed to Cancel Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["clientInvoice", "info"],
      });
    },
  });

  const { mutateAsync: handleCreate, isPending: pendingCreate } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/ClientInvoices", {
        State: "Created",
        SelectedNo: clientInvoiceId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Status Changed to Created Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["clientInvoice", "info"],
      });
    },
  });

  const { mutateAsync: handleApproval, isPending: pendingApproval } =
    useMutation({
      mutationFn: async () => {
        return Axios.patch("/Quotations", {
          State: "Check Approval",
          SelectedNo: idToDelete,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("Approval Check Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["clientInvoice", "info"],
        });
      },
    });

  const { mutateAsync: handleDeleteItem, isPending: pendingDeleteItem } =
    useMutation({
      mutationFn: async () => {
        return Axios.patch("/ClientInvoices", {
          SelectedNo: clientInvoiceId,
          State: "Delete",
          WorkOrderD6No: idToDelete,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar("Deleted Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["clientInvoice", "info"],
        });
      },
    });

  const handleDownload = (data) => {
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${clientInvoiceId}.pdf`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const { mutateAsync: handleExport } = useMutation({
    mutationFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          SelectedNo: clientInvoiceId,
          State: "PDF",
        },
        responseType: "arraybuffer",
      });
      return new Blob([response.data], { type: "application/pdf" });
    },
    onSuccess: (data) => {
      handleDownload(data);
    },
  });

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const actionTypeMap = {
    deleteInvoice: {
      onClick: handleDelete,
      description: t(
        "info_modules.client_invoices.confirmation.delete_invoice"
      ),
      isPending: pendingDelete,
    },
    cancel: {
      onClick: handleCancel,
      description: t("info_modules.client_invoices.confirmation.cancel"),
      isPending: pendingCancel,
    },
    changeCreate: {
      onClick: handleCreate,
      description: t(
        "info_modules.client_invoices.confirmation.change_to_create"
      ),
      isPending: pendingCreate,
    },
    requestApproval: {
      onClick: handleApproval,
      description: t(
        "info_modules.client_invoices.confirmation.request_approval"
      ),
      isPending: pendingApproval,
    },
    deleteItem: {
      onClick: handleDeleteItem,
      description: t(
        "info_modules.client_invoices.confirmation.delete_invoice"
      ),
      isPending: pendingDeleteItem,
    },
  };

  // Handle save label
  const [saveLabel, setSaveLabel] = useState("");

  const acceptanceColumns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setD6RefNo(params.value);
              setD6Modal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 130,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 130,
    },
    {
      field: "STotal",
      headerName: t("data_grid.headers.supply"),
      width: 130,
    },
    {
      field: "ITotal",
      headerName: t("data_grid.headers.install"),
      width: 130,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      width: 100,
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Box display="flex" gap={0} key={params.id}>
          <Tooltip
            title={t("info_modules.client_invoices.buttons.view_acceptance")}
          >
            <GridActionsCellItem
              icon={<Image />}
              label="Image"
              onClick={() => {
                setSaveLabel(`${params.row.WorkOrderD6No}-Client Invoice`);
                handleImageModalOpen(params.row.WorkOrderD6No);
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("info_modules.client_invoices.buttons.request_approval")}
          >
            <GridActionsCellItem
              icon={<ReplayTwoToneIcon />}
              label="Request"
              disabled={params.row.WorkOrderD6Status !== "Approved"}
              onClick={() => {
                handleOpenModal(
                  "confirmRequestApproval",
                  params.row.WorkOrderD6No
                );
              }}
            />
          </Tooltip>
          <Tooltip title={t("info_modules.client_invoices.buttons.delete")}>
            <span>
              <GridActionsCellItem
                key={params.id}
                icon={
                  <DeleteIcon
                    color={
                      clientInvoicesInfo?.Acceptance.length === 1 ? "" : "error"
                    }
                  />
                }
                label="Delete"
                disabled={clientInvoicesInfo?.Acceptance.length === 1}
                onClick={() =>
                  handleOpenModal("confirmDeleteItem", params.row.WorkOrderD6No)
                }
              />
            </span>
          </Tooltip>
        </Box>,
      ],
    },
  ];
  const assetsColumns = [
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 130,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
    },
    {
      field: "SN",
      headerName: t("data_grid.headers.sn"),
      width: 90,
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 140,
      flex: 1,
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
      width: 100,
    },
    {
      field: "Qty",
      headerName: t("data_grid.headers.qty"),
      width: 90,
    },
    {
      field: "UnitPrice",
      headerName: t("data_grid.headers.unit_price"),
      width: 120,
    },
    {
      field: "Total",
      headerName: t("data_grid.headers.total"),
      width: 120,
    },
  ];

  const getModalDetails = () => {
    if (clientInvoicesInfo?.Actions.includes("Technical Approval")) {
      return {
        title: t("info_modules.client_invoices.buttons.technical_approval"),
        label: t(
          "info_modules.client_invoices.description.technical_approval_date"
        ),
        state: "TechnicalApproval",
      };
    } else if (clientInvoicesInfo?.Actions.includes("Price Confirmation")) {
      return {
        title: t("info_modules.client_invoices.buttons.price_confirmation"),
        label: t(
          "info_modules.client_invoices.description.price_confirmation_date"
        ),
        state: "PriceConfirmation",
      };
    } else {
      return {
        title: t("info_modules.client_invoices.buttons.signed"),
        label: t("info_modules.client_invoices.description.signed_date"),
        state: "Signed",
      };
    }
  };

  const modalDetails = getModalDetails();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t(
                "info_modules.client_invoices.description.invoice_description"
              )}
              editButton={clientInvoicesInfo?.Actions.includes("Edit")}
              exportButton={clientInvoicesInfo?.Actions.includes("Export")}
              deleteButton={clientInvoicesInfo?.Actions.includes("Delete")}
              onClickDelete={() => handleOpenModal("confirmDeleteInvoice")}
              onClickEdit={() => setIsEditable(true)}
              onClickExport={handleExport}
              content={
                <InvoiceDescriptionCard
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  clientInvoicesInfo={clientInvoicesInfo}
                  handleOpenModal={handleOpenModal}
                />
              }
            />
            <InfoCard
              title={t(
                "info_modules.client_invoices.description.acceptance_list"
              )}
              addIcon={clientInvoicesInfo?.Actions.includes("Edit")}
              onClick={() => handleOpenModal("addItems")}
              content={
                <FormsDataGrid
                  rows={clientInvoicesInfo?.Acceptance || []}
                  columns={acceptanceColumns}
                  toolbar
                  showExportButton
                  height={260}
                  getRowId={generateId()}
                />
              }
            />
            <InfoCard
              title={t("info_modules.client_invoices.description.assets")}
              content={
                <FormsDataGrid
                  rows={clientInvoicesInfo?.InvoiceAssets || []}
                  columns={assetsColumns}
                  height={260}
                  getRowId={generateId()}
                  toolbar
                  showExportButton
                />
              }
            />
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <InvoiceModal
            open={invoiceModal}
            handleClose={() => setInvoiceModal(false)}
            clientInvoicesInfo={data}
            selectedNo={clientInvoiceId}
            loading={loadingData}
          />
          <SubmitModal
            open={submitModal}
            handleClose={() => setSubmitModal(false)}
            clientInvoiceId={clientInvoiceId}
          />
          <ChangeGovInv
            open={changeGovInvModal}
            handleClose={() => setChangeGovInvModal(false)}
            clientInvoiceId={clientInvoiceId}
            clientInvoicesInfo={clientInvoicesInfo}
          />
          <AddItems
            open={addItemsModal}
            handleClose={() => setAddItemsModal(false)}
            clientInvoicesInfo={clientInvoicesInfo}
          />
          <ImageModal
            open={isImageModalOpen}
            handleClose={handleCloseImageModal}
            image={image}
            loading={isImageLoading}
            title={`${clientInvoiceId}`}
            saveLabel={saveLabel}
          />
          <InfoModal
            title={`${t(
              "info_modules.work_orders.description.quotation_details"
            )} - ${d6RefNo}`}
            content={<InvoicesSnapShot d6RefNo={d6RefNo} open={d6Modal} />}
            handleClose={() => setD6Modal(false)}
            open={d6Modal}
            width="64rem"
          />
          <StatementStatusForm
            open={statementStatusForm}
            handleClose={() => setStatementStatusForm(false)}
            title={modalDetails.title}
            label={modalDetails.label}
            clientInvoiceId={clientInvoiceId}
            state={modalDetails.state}
          />
        </>
      )}
    </>
  );
};
export default ClientInvoicesInfo;
