import { useQuery } from "@tanstack/react-query";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import ProgressBarCell from "../components/ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import { Link } from "react-router-dom";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import DateFormatGrid from "../components/DateFormatGrid";
import { WorkOrdersService } from "../api/workOrders";
import dayjs from "dayjs";
import download from "downloadjs";
import { useState } from "react";

const WorkOrders = () => {
  const [t] = useTranslation("global");

  const { newForm } = useSelector((state) => state.theme);
  const [isBulkExportClicked, setIsBulkExportClicked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedWorkOrderNos, setSelectedWorkOrderNos] = useState([]);

  const exportButtonLabel =
    selectedRows.length > 0
      ? "data_grid.buttons.export_selected"
      : "data_grid.buttons.bulk_export";

  const statusNames = [
    { name: "Hold", displayName: t("data_grid.tabs.hold") },
    { name: "Pending", displayName: t("data_grid.tabs.pending") },
    { name: "In Progress", displayName: t("data_grid.tabs.in_progress") },
    {
      name: "Complete With Reserves",
      displayName: t("data_grid.tabs.complete_with_reserves"),
    },
    {
      name: "Work Complete",
      displayName: t("data_grid.tabs.work_complete"),
    },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
  ];

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/workorders/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 70,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 135,
    },
    {
      field: "SiteType",
      headerName: t("data_grid.headers.site_type"),
    },
    {
      field: "WorkOrderDate",
      headerName: t("data_grid.headers.received_date"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "MissionsStarted",
      headerName: t("data_grid.headers.missions_started"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionsEnded",
      headerName: t("data_grid.headers.missions_ended"),
      width: 120,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 125,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 125,
    },
    {
      field: "LastUpdateDate",
      headerName: t("data_grid.headers.last_update"),
      width: 115,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Count",
      headerName: t("data_grid.headers.d6_count"),
      width: 70,
    },
    {
      field: "GovernoratesName",
      headerName: t("data_grid.headers.governorate_name"),
      width: 120,
    },
    {
      field: "RequestorsName",
      headerName: t("data_grid.headers.requested_by"),
      width: 120,
    },
    {
      field: "SubProjectsName",
      headerName: t("data_grid.headers.sub_project"),
      width: 120,
    },
    {
      field: "Progress",
      headerName: t("data_grid.headers.acceptance_progress"),
      width: 130,
      valueGetter: (params) => {
        return params.row.D6Count == 0 ? "Not Started" : params.value;
      },
      renderCell: (params) => {
        return params.row.D6Count == 0
          ? "Not Started"
          : ProgressBarCell(params.value, params.row.WorkOrderStatus);
      },
    },
    {
      field: "WorkOrderStatus",
      headerName: t("data_grid.headers.wo_status"),
      headerAlign: "center",
      width: 185,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const { refetch: refetchInvoicesFile } = useQuery({
    queryKey: ["workOrdersFile"],
    queryFn: () => WorkOrdersService.exportWorkOrders(selectedWorkOrderNos),
    enabled: false,
  });

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["workOrders"],
    queryFn: WorkOrdersService.getAllWorkOrders,
  });

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  if (isLoading) {
    return <Spinner />;
  }
  const handleRowSelection = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
    const selectedData = rows.result.filter((row) =>
      newSelectedRows.includes(row.idWorkOrder)
    );
    const workOrderNos = selectedData.map((row) => row.WorkOrderNo);
    setSelectedWorkOrderNos(workOrderNos);
  };

  const handleBulkExport = () => {
    if (!isBulkExportClicked) {
      setIsBulkExportClicked(true);
      return;
    }

    if (selectedRows.length === 0) {
      setIsBulkExportClicked(false);
      return;
    }

    // Use the extracted WorkOrderNos directly in the API call
    refetchInvoicesFile({
      queryFn: () => WorkOrdersService.exportWorkOrders(selectedWorkOrderNos),
    }).then((response) => {
      download(response.data, "Work Orders", "application/pdf");
    });
  };

  return (
    <CustomDataGrid
      rows={rows.result || []}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.idWorkOrder}
      statusColors={statusColors}
      statusNames={statusNames}
      filterField="WorkOrderStatus"
      reloadFunction={refetch}
      showReloadOverlay={isRefetching}
      bulkExportBtn={rows.Actions.includes("Bulk Export")}
      bulkExportFunction={() => handleBulkExport()}
      enableRowSelection={isBulkExportClicked}
      rowSelectionModel={selectedRows}
      onRowSelectionModelChange={handleRowSelection}
      bulkExportButtonLabel={exportButtonLabel}
      newButton
      newFunction={() => {
        if (newForm === "work_orders" || newForm === null) {
          handleOpen("work_orders");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("work_orders");
          });
        }
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            GovernoratesName: false,
            SubProjectsName: false,
            SiteType: false,
            RequestorsName: false,
            LastUpdateDate: false,
            MissionsStarted: false,
            MissionsEnded: false,
          },
        },
      }}
    />
  );
};

export default WorkOrders;
