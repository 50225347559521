import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";

const StepperButton = ({
  isPending,
  onClick,
  handleBack,
  activeStep,
  disabled,
  label,
  isLastStep,
}) => {
  const [t] = useTranslation("global");
  const { language } = useSelector((state) => state.theme);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1.5,
          marginBottom: 2,
          marginRight: 1,
        }}
      >
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="contained"
          background="primary.main"
          sx={{
            color: "white",
            fontWeight: 500,
            padding: "5px 10px",
          }}
          startIcon={
            language === "AR" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />
          }
        >
          {t("modules.buttons.back")}
        </Button>
        <LoadingButton
          variant="contained"
          background={isLastStep ? "success" : "primary.main"}
          loading={isPending}
          disabled={disabled || isPending}
          onClick={onClick}
          sx={{
            color: "white",
            fontWeight: 500,
            padding: "5px 10px",
            backgroundColor: isLastStep ? "success.main" : "primary.main",
            "&:hover": {
              backgroundColor: isLastStep ? "success.dark" : "primary.dark",
            },
          }}
          endIcon={
            !isLastStep &&
            (language === "AR" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />)
          }
        >
          {label}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default StepperButton;
