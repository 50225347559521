import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import { useParams, useNavigate, Link } from "react-router-dom";
import ChipStatus from "../../../../components/ChipStatus";
import { Box, Grid, Autocomplete } from "@mui/material";
import { autoCompleteStyles } from "../../../../utils/globalFunctions";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockResetIcon from "@mui/icons-material/LockReset";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import InfoModal from "../../../../components/Modals/InfoModal";
import SingleUserRole from "../../../UsersManagement/UserRoles/SingleUserRole";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { StyledTextField } from "../../MobileInternetLine/MobileInternetLine";

const AccountDetails = ({
  usersManagementInfo,
  handleOpenModal,
  isEditable,
  setIsEditable,
  setUseAsTemplateModal,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [disableEdit, setDisableEdit] = useState(false);

  const { data: comoBoxData } = useQuery({
    queryKey: ["usersManagement", "edit"],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          State: "Edit",
          SelectedNo: userId,
        },
      });
      return response.data;
    },
  });

  const validationSchema = Yup.object().shape({
    role: Yup.string().required(t("modules.global_schema.required")),
    priceList: Yup.string().required(t("modules.global_schema.required")),
    userName: Yup.string()
      .test("username", t("modules.global_schema.invalid_value"), (value) => {
        if (!value) return false;
        const usernameRegex = /^[a-zA-Z0-9_.]{3,16}$/;
        return usernameRegex.test(value);
      })
      .required(t("modules.global_schema.required")),
    nickName: Yup.string().required(t("modules.global_schema.required")),
    userMail: Yup.string()
      .test("email", t("modules.global_schema.invalid_value"), (value) => {
        if (!value) return false;
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
      })
      .required(t("modules.global_schema.required")),
    level: Yup.number().required(t("modules.global_schema.required")),
  });

  const initialValues = {
    userName: usersManagementInfo?.result[0]?.ERPUserName,
    nickName: usersManagementInfo?.result[0]?.ERPUserNickName,
    userMail: usersManagementInfo?.result[0]?.ERPUserMail,
    level: usersManagementInfo?.result[0]?.ERPUserLevel,
    role: usersManagementInfo?.result[0]?.ERPUserRoleName,
    priceList: usersManagementInfo?.result[0]?.PriceListsGroupName,
  };
  const {
    handleSubmit,
    values,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });

  const { mutateAsync: editUserManagement, isPending: pendingEdit } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/UsersManagement", {
          ERPUserLevel: parseInt(values.level),
          ERPUserMail: values.userMail,
          ERPUserName: values.userName,
          ERPUserNickName: values.nickName,
          ERPUserRoleName: values.role,
          EmpCode: usersManagementInfo.result[0].EmpCode,
          PriceListsGroupName: values.priceList,
          SelectedNo: userId,
        });
      },
      onSuccess: (data) => {
        enqueueSnackbar("Edited Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["usersManagement", "info"],
        });
        navigate(`/usersmanagement/${values.userName}`);
        setIsEditable(false);
        resetForm();
      },
    });

  const userManagement = [
    {
      label: t("data_grid.headers.user_name"),
      value: isEditable ? (
        <StyledTextField
          name="userName"
          value={values.userName}
          onChange={handleChange("userName")}
          error={!!touched.userName && !!errors.userName}
          label={touched.userName ? errors.userName : ""}
        />
      ) : (
        values.userName
      ),
    },
    {
      label: t("data_grid.headers.nick_name"),
      value: isEditable ? (
        <StyledTextField
          name="nickName"
          value={values.nickName}
          onChange={handleChange("nickName")}
          error={!!touched.nickName && !!errors.nickName}
          label={touched.nickName ? errors.nickName : ""}
        />
      ) : (
        values.nickName
      ),
    },
    {
      label: t("data_grid.headers.user_mail"),
      value: isEditable ? (
        <StyledTextField
          name="userMail"
          value={values.userMail}
          onChange={handleChange("userMail")}
          error={!!touched.userMail && !!errors.userMail}
          label={touched.userMail ? errors.userMail : ""}
        />
      ) : (
        values.userMail
      ),
    },
    {
      label: t("data_grid.headers.level"),
      value: isEditable ? (
        <StyledTextField
          name="level"
          value={values.level}
          onChange={handleChange("level")}
          error={!!touched.level && !!errors.level}
          label={touched.level ? errors.level : ""}
        />
      ) : (
        values.level
      ),
    },
    {
      label: t("data_grid.headers.user_role"),
      value: isEditable ? (
        <Autocomplete
          disableClearable
          disablePortal
          id="combo-box-demo"
          options={comoBoxData?.UserRoles || []}
          getOptionLabel={(option) => option.ERPUserRoleName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("role", newValue ? newValue.ERPUserRoleName : "");
          }}
          value={
            comoBoxData?.UserRoles.find(
              (option) => option.ERPUserRoleName === values.role
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.role && !!errors.role}
              label={touched.role && errors.role}
            />
          )}
        />
      ) : (
        <Link
          onClick={() => {
            setRoleName(values.role);
            setDisableEdit(true);
            setUserRoleModal(true);
          }}
          style={{
            color: "#1790FF",
          }}
        >
          {values.role}
        </Link>
      ),
    },
    {
      label: t("data_grid.headers.price_list_group"),
      value: isEditable ? (
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box-demo"
          options={comoBoxData?.PriceList || []}
          getOptionLabel={(option) => option.PriceListsGroupName}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "priceList",
              newValue ? newValue.PriceListsGroupName : ""
            );
          }}
          value={
            comoBoxData?.PriceList.find(
              (option) => option.PriceListsGroupName === values.priceList
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.priceList && !!errors.priceList}
              label={touched.priceList && errors.priceList}
            />
          )}
        />
      ) : (
        values.priceList
      ),
    },
    {
      label: t("data_grid.headers.erp_version"),
      value: usersManagementInfo.result[0].ERPUsersVersion,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={usersManagementInfo.result[0].ERPUserStatus} />,
    },
  ];

  const actions = usersManagementInfo.Actions1 || [];

  const actionsConfig = {
    Active: {
      label: t("info_modules.users_management.buttons.active"),
      icon: <CheckOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => handleOpenModal("confirmActive"),
    },
    Deactive: {
      label: t("info_modules.users_management.buttons.deactive"),
      icon: <HighlightOffIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => handleOpenModal("confirmDeactive"),
    },
    "Password Reset": {
      label: t("info_modules.users_management.buttons.password_reset"),
      icon: <LockResetIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: () => handleOpenModal("confirmResetPassword"),
    },
    "Use As Template": {
      label: t("tooltip.use_as_template"),
      icon: (
        <ChangeCircleOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      color: "primary",
      onClick: () => setUseAsTemplateModal(true),
    },
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isEditable && (
          <Box
            display="flex"
            gap="10px"
            justifyContent="flex-end"
            marginBottom="6px"
          >
            {Object.keys(actionsConfig).some((action) =>
              actions.includes(action)
            ) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ActionMenuButton
                  actions={actions}
                  actionsConfig={actionsConfig}
                />
              </Box>
            )}
          </Box>
        )}
        <Grid container spacing={1}>
          {userManagement.map((item, index) => (
            <Grid item xs={6} key={index}>
              <ChipComponent label={item.label} value={item.value} />
            </Grid>
          ))}
        </Grid>
        {isEditable && (
          <ButtonGroup
            onClickSave={() => {
              setIsCancel(false);
              if (dirty && Object.keys(errors).length === 0) {
                setConfirmModal(true);
              }
            }}
            onClickClose={() => {
              if (dirty) {
                setIsCancel(true);
                setConfirmModal(true);
              } else {
                setIsEditable(false);
              }
            }}
            saveLabel={t("modules.buttons.save")}
          />
        )}
      </form>

      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() =>
          isCancel
            ? (setIsEditable(false), setConfirmModal(false), resetForm())
            : editUserManagement()
        }
        description={
          isCancel
            ? t("info_modules.missions.description.cancel_without_saving")
            : t("info_modules.users_management.confirmation.edit")
        }
        isPending={isCancel ? null : pendingEdit}
        color="error"
      />
      <InfoModal
        title={roleName}
        open={userRoleModal}
        handleClose={() => setUserRoleModal(false)}
        content={
          <SingleUserRole disableEdit={disableEdit} userRole={roleName} />
        }
        width="65rem"
      />
    </Box>
  );
};
export default AccountDetails;
