import { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StyledTextField } from "../../../../utils/globalFunctions";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const StoreItemCard = ({
  inventoryInfo,
  handleOpenModal,
  isEditable,
  setIsEditable,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { inventoryManagementId } = useParams();
  const theme = useSelector((state) => state.theme.colorTheme);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required(t("modules.global_schema.required")),
    minimumQuantity: Yup.string().required(t("modules.global_schema.required")),
  });

  const initialValues = {
    itemName: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateName,
    itemNameEn: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateNameEn,
    itemCode: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateCode,
    description: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateDescription,
    unit: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateUnit,
    minimumQuantity: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateMinimum,
  };
  const {
    handleSubmit,
    values,
    resetForm,
    dirty,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      if (isCancel) {
        setConfirmModal(false);
      } else {
        setConfirmModal(true);
      }
    },
    enableReinitialize: true,
  });
  const { mutateAsync: editInventory, isPending: pendingEdit } = useMutation({
    mutationFn: async () => {
      return Axios.put("/InventoryManagement", {
        SelectedNo: inventoryManagementId,
        StoreItemsTemplateCode: values.itemCode,
        StoreItemsTemplateDescription: values.description,
        StoreItemsTemplateMinimum: values.minimumQuantity.toString(),
        StoreItemsTemplateName: values.itemName,
        StoreItemsTemplateUnit: values.unit,
        State: "EditItem",
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory", "info"],
      });
      navigate(`/inventorymanagement/${values.itemCode}`);
      setIsEditable(false);
      resetForm();
    },
  });
  const inventoryProperty = [
    {
      label: t("data_grid.headers.item_code"),
      value: isEditable ? (
        <StyledTextField
          name="itemCode"
          value={values.itemCode}
          onChange={handleChange("itemCode")}
        />
      ) : (
        inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateCode
      ),
    },
    {
      label: t("data_grid.headers.item_name"),
      value: isEditable ? (
        <StyledTextField
          name="itemName"
          value={values.itemName}
          onChange={handleChange("itemName")}
        />
      ) : (
        inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateName
      ),
    },
    {
      label: t("modules.inventory_management.new_form.fields.item_name_en"),
      value: isEditable ? (
        <StyledTextField
          name="itemNameEn"
          value={values.itemNameEn}
          onChange={handleChange("itemNameEn")}
        />
      ) : (
        inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateNameEn
      ),
    },
    {
      label: t("data_grid.headers.unit"),
      value: isEditable ? (
        <StyledTextField
          name="unit"
          value={values.unit}
          onChange={handleChange("unit")}
        />
      ) : (
        inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateUnit
      ),
    },
    {
      label: t("data_grid.headers.item_type"),
      value: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateType,
    },
    {
      label: t("data_grid.headers.balance"),
      value: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateQty,
    },
    {
      label: t("data_grid.headers.minimum"),
      value: isEditable ? (
        <StyledTextField
          name="minimumQuantity"
          value={values.minimumQuantity}
          onChange={handleChange("minimumQuantity")}
          onBlur={handleBlur}
          error={!!touched.minimumQuantity && !!errors.minimumQuantity}
          label={touched.minimumQuantity ? errors.minimumQuantity : ""}
        />
      ) : (
        inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateMinimum
      ),
    },
    {
      label: t("data_grid.headers.issue_freq"),
      value: inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateMinimum,
    },
  ];

  const actions =
    inventoryInfo?.Actions.filter((action) => action !== "Edit Details") || [];

  const actionsConfig = {
    "Asign To Store": {
      label: t("info_modules.inventory_management.buttons.assign_to_store"),
      icon: <MoveUpIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("assign"),
    },
    Aggregate: {
      label: t("info_modules.inventory_management.buttons.aggregate"),
      icon: <CallMergeIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("aggregate"),
    },
    Separate: {
      label: t("info_modules.inventory_management.buttons.separate"),
      icon: <CallSplitIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("separate"),
    },
    Transfer: {
      label: t("info_modules.inventory_management.buttons.transfer"),
      icon: <SyncAltOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("transfer"),
    },
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isEditable && actions.length > 0 && (
          <Box display="flex" justifyContent="flex-end" marginBottom="4px">
            {actions.length > 1 ? (
              <ActionMenuButton
                actions={actions}
                actionsConfig={actionsConfig}
              />
            ) : (
              <ActionButton
                icon={actionsConfig[actions[0]].icon}
                text={actionsConfig[actions[0]].label}
                onClick={actionsConfig[actions[0]].onClick}
                color={actionsConfig[actions[0]].color}
              />
            )}
          </Box>
        )}
        <Grid container spacing={1}>
          {inventoryProperty.map((item, index) => (
            <Grid item xs={4} key={index}>
              <ChipComponent label={item.label} value={item.value} />
            </Grid>
          ))}
          <Grid item xs={8}>
            <ChipComponent
              label={t("data_grid.headers.description")}
              value={
                isEditable ? (
                  <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange("description")}
                    onBlur={handleBlur}
                    error={!!touched.description && !!errors.description}
                    label={touched.description ? errors.description : ""}
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "9px",
                        fontSize: "0.85rem",
                        fontWeight: 600,
                        width: "36.7rem",
                      },
                      "& .MuiOutlinedInput-root": {
                        paddingTop: "5px",
                      },
                    }}
                    rows={2}
                    multiline
                  />
                ) : (
                  inventoryInfo?.ItemInfo[0]?.StoreItemsTemplateDescription
                )
              }
            />
          </Grid>
        </Grid>
        {isEditable && (
          <ButtonGroup
            onClickSave={() => {
              setIsCancel(false);
              if (dirty && Object.keys(errors).length === 0) {
                setConfirmModal(true);
              }
            }}
            onClickClose={() => {
              if (dirty) {
                setIsCancel(true);
                setConfirmModal(true);
              } else {
                setIsEditable(false);
              }
            }}
            saveLabel={t("modules.buttons.save")}
          />
        )}
      </form>

      <ConfirmationInfoModal
        open={confirmModal}
        handleClose={() => {
          setConfirmModal(false);
        }}
        onClick={() =>
          isCancel
            ? (setIsEditable(false), setConfirmModal(false), resetForm())
            : editInventory()
        }
        description={
          isCancel
            ? t("info_modules.missions.description.cancel_without_saving")
            : t(
                "info_modules.inventory_management.confirmation.edit_store_item"
              )
        }
        isPending={isCancel ? null : pendingEdit}
        color="error"
      />
    </Box>
  );
};
export default StoreItemCard;
