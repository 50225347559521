import * as yup from "yup";
import Axios from "../../network/Axios";
import { Autocomplete, TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import BasicDatePicker from "../DateAndTime/DatePicker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import useHandleForms from "../../hooks/useHandleForms";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import FormSpinner from "../Spinners/FormSpinner";

const PurchaseOrderModal = ({ onDirtyChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const [dateChange, setDateChange] = useState(dayjs());

  const { data: account, isLoading } = useQuery({
    queryKey: ["clientPurchaseOrders", "new"],
    queryFn: async () => {
      const response = await Axios.get("/CLientPurchaseOrders", {
        params: {
          State: "New",
        },
      });
      return response.data.result;
    },
  });

  const initialValues = {
    poNo: "",
    account: "",
    amount: "",
    date: dateChange.format("YYYY-MM-DD"),
    description: "",
  };

  const schema = yup.object().shape({
    poNo: yup.string().required("Required"),
    account: yup.string().required("Required"),
    amount: yup.string().required("Required"),
    date: yup.string().required("Required"),
    description: yup.string().required("Required"),
  });

  const { mutateAsync: addNewPO, isPending } = useMutation({
    mutationFn: async (values) => {
      return Axios.post("/CLientPurchaseOrders", {
        SelectedNo: values.poNo,
        ClientPODate: values.date,
        ClientPODescription: values.description,
        ClientPOAmount: values.amount,
        CompanyAccountName: values.account,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleClose();
      enqueueSnackbar("Added Successfully", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["clientPurchaseOrders"] });
    },
  });

  const handleFormSubmit = (values) => {
    addNewPO(values);
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });
  const { handleClose } = useHandleForms({ dirty, onDirtyChange });

  if (isLoading) {
    return <FormSpinner />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap="10px" margin="4px">
        <TextField
          label={t("modules.purchase_order.fields.po_no")}
          sx={{ width: "100%" }}
          size="small"
          value={values.poNo}
          onBlur={handleBlur}
          onChange={handleChange("poNo")}
          error={!!touched.poNo && !!errors.poNo}
          helperText={touched.poNo && errors.poNo}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={account}
          getOptionLabel={(option) => option.CompanyAccountName}
          sx={{ width: "100%" }}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue(
              "account",
              newValue ? newValue.CompanyAccountName : ""
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("modules.purchase_order.fields.account")}
              value={values.account}
              onBlur={handleBlur}
              error={!!touched.account && !!errors.account}
              helperText={touched.account && errors.account}
            />
          )}
        />
        <TextField
          label={t("modules.purchase_order.fields.amount")}
          sx={{ width: "100%" }}
          size="small"
          value={values.amount}
          onBlur={handleBlur}
          onChange={handleChange("amount")}
          error={!!touched.amount && !!errors.amount}
          helperText={touched.amount && errors.amount}
        />
        <BasicDatePicker
          dateChange={dateChange}
          handleDateChange={(date) => {
            setFieldValue("date", date);
          }}
          setFieldValue={setFieldValue}
          width="33.2rem"
          label={t("modules.purchase_order.fields.date")}
        />
        <TextField
          label={t("modules.purchase_order.fields.description")}
          sx={{ width: "100%" }}
          multiline
          rows={5}
          size="small"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange("description")}
          error={!!touched.description && !!errors.description}
          helperText={touched.description && errors.description}
        />
      </Box>
      <ButtonGroup
        isPending={isPending}
        onClickClose={handleClose}
        saveLabel={t("modules.buttons.save")}
        disabled={!dayjs(values.date).isValid() || isPending}
      />
    </form>
  );
};

export default PurchaseOrderModal;
