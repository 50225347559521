import { useState } from "react";
import { CompanyFleetService } from "../../../../api/companyFleet";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../../components/Modals/InfoModal";
import SelectEmployee from "./SelectEmployee";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";

const AssignCar = ({ handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("global");
  const queryClient = useQueryClient();

  const { carNumber } = useParams();
  const theme = useSelector((state) => state.theme.colorTheme);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  // Handling employees selection modal
  const [openEmployeesModal, setOpenEmployeesModal] = useState(false);

  const handleOpenEmployeeModal = () => {
    setOpenEmployeesModal(true);
  };

  // employee id
  const [empId, setEmpId] = useState([]);

  const handleEmployeeIdChange = (value) => {
    setEmpId(value);
  };

  const handleCloseEmployeeModal = () => {
    setOpenEmployeesModal(false);
  };

  // employee name
  const [empName, setEmpName] = useState("");

  const handleEmployeeNameChange = (value) => {
    setEmpName(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Handle assigning car to driver
  const { mutateAsync: assignDriver, isPending: isAssigningDriver } =
    useMutation({
      mutationFn: CompanyFleetService.patchCarInfo,
      onSuccess: (data) => {
        handleClose();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["carInfo"] });
      },
    });
  return (
    <>
      <Stack spacing={3} p={1} width={400}>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={handleOpenEmployeeModal}
            aria-label="Add Employee"
            sx={{
              borderRadius: 3,
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              margin: "4px 2px",
              ":hover": {
                backgroundColor:
                  theme === "dark"
                    ? "rgba(34, 85, 153, 0.1)"
                    : "rgba(80, 152, 236, 0.05)",
              },
            }}
          >
            <AddCircleOutlineIcon
              color="primary"
              style={{ fontSize: "18px" }}
            />
            <Typography color="primary" fontSize="13px" fontWeight="600">
              {t("modules.attendance.add_buttons.select_employee")}
            </Typography>
          </IconButton>
          <Typography>{empName}</Typography>
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={t("data_grid.headers.start_date")}
            value={startDate}
            onChange={handleStartDateChange}
            format="YYYY-MM-DD hh:mm A"
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "14px",
              },
            }}
            slotProps={{
              textField: {
                size: "small",
                label: t("data_grid.headers.start_date"),
              },
            }}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={t("data_grid.headers.end_date")}
            value={endDate}
            onChange={handleEndDateChange}
            format="YYYY-MM-DD hh:mm A"
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "14px",
              },
            }}
            slotProps={{
              textField: {
                size: "small",
                label: t("data_grid.headers.end_date"),
              },
            }}
          />
        </LocalizationProvider>

        <ButtonGroup
          onClickClose={() => {
            setEmpId([]);
            setEmpName("");
            handleClose();
          }}
          onClickSave={() =>
            assignDriver({
              AssignmentDateStart: dayjs(startDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              AssignmentDateEnd: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
              SelectedNo: carNumber,
              State: "Assign",
              idEmpInfo: empId[0],
            })
          }
          isPending={isAssigningDriver}
          disabled={!empId.length}
          saveLabel={t("modules.buttons.save")}
        />
      </Stack>
      <InfoModal
        open={openEmployeesModal}
        handleClose={handleCloseEmployeeModal}
        title={t(
          "info_modules.mobile_internet_line.description.select_employee"
        )}
        content={
          <SelectEmployee
            setValue={handleEmployeeIdChange}
            empId={empId}
            handleClose={handleCloseEmployeeModal}
            handleEmployeeNameChange={handleEmployeeNameChange}
          />
        }
      />
    </>
  );
};

export default AssignCar;
