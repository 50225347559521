import React, { useState, useRef } from "react";
import { Box, Stack, IconButton, Typography } from "@mui/material";
import Print from "@mui/icons-material/Print";
import Save from "@mui/icons-material/Save";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Close from "@mui/icons-material/Close";

const ImageViewer = ({ src, saveLabel, handleClose }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  const [height, setHeight] = useState("auto"); // auto or 100%

  // Handle image dragging on mouse down
  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent text selection or other unwanted behaviors

    const image = imageRef.current;
    if (image) {
      const initialX = e.clientX - position.x;
      const initialY = e.clientY - position.y;

      const handleMouseMove = (e) => {
        // Update the position based on mouse movement
        const x = e.clientX - initialX;
        const y = e.clientY - initialY;

        // Smoothly update position using requestAnimationFrame
        requestAnimationFrame(() => {
          setPosition({ x, y });
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.body.style.cursor = "default"; // Reset cursor
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.body.style.cursor = "grabbing"; // Change cursor to grabbing
    }
  };

  const resetView = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });

    // Scroll the container to the top-left corner
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // Optional: adds smooth scrolling
      });
    }
  };

  const zoomIn = () => {
    setHeight("auto");
    setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Increment scale up to 3
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 1)); // Decrement scale down to 1
  };

  const printImage = () => {
    const image = imageRef.current;
    if (image) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Image</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100vw;
                  height: 100vh;
                  overflow: hidden;
                }
                img {
                  max-width: 100%;
                  max-height: 100%;
                  width: auto;
                  height: auto;
                }
              }
            </style>
          </head>
          <body>
            <img src="${image.src}" />
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  };

  const saveImage = () => {
    const image = imageRef.current;
    if (image) {
      const link = document.createElement("a");
      link.href = image.src;
      link.download = "image.png"; // You can change the filename if needed
      link.click();
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <TopBar handleClose={handleClose} saveLabel={saveLabel} />
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          backgroundColor: "background.default",
          display: "grid",
          placeItems: "center",
          height: "100%",
        }}
        onMouseDown={handleMouseDown}
      >
        <img
          ref={imageRef}
          src={src}
          alt="Zoomable"
          style={{
            transform: `scale(${scale})`,
            cursor: "move",
            width: "100%", // Make image width 100% of the container
            height, // Maintain aspect ratio
            objectFit: "contain", // Ensure the image fits within its container
            position: "absolute",
            left: `${position.x}px`,
            top: `${position.y}px`,
            transition: "transform 0.1s ease", // Smooth transform transition
          }}
        />
        <BottomBar
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          resetView={resetView}
          printImage={printImage}
          saveImage={saveImage}
        />
      </Box>
    </Stack>
  );
};

const TopBar = ({ handleClose, saveLabel }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: 1,
      }}
    >
      <Typography>{saveLabel}</Typography>
      <IconButton size="small" onClick={handleClose}>
        <Close />
      </IconButton>
    </Stack>
  );
};

const BottomBar = ({ zoomIn, zoomOut, resetView, printImage, saveImage }) => {
  return (
    <Stack
      sx={{
        position: "fixed",
        bottom: 50,
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "background.main",
        borderRadius: "999px",
      }}
      direction="row"
      spacing={10}
    >
      <Stack direction="row" spacing={2}>
        <IconButton size="small" onClick={zoomIn}>
          <ZoomIn />
        </IconButton>
        <IconButton size="small" onClick={zoomOut}>
          <ZoomOut />
        </IconButton>
        <IconButton size="small" onClick={resetView}>
          <FullscreenExitIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <IconButton size="small" onClick={saveImage}>
          <Save />
        </IconButton>
        <IconButton size="small" onClick={printImage}>
          <Print />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ImageViewer;
