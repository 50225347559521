import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Box } from "@mui/material";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../components/Chips/ChipComponent";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import QuotationDetailsCard from "./InfoModals/QuotationDetailsCard";
import Spinner from "../../../components/Spinners/Spinner";
import WorkOrderDetails from "./InfoModals/WorkOrderDetails";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import UseAsTemplate from "./InfoModals/UseAsTemplate";
import ApprovalDocument from "./InfoModals/ApprovalDocument";
import ConfirmApprove from "./InfoModals/ConfirmApprove";
import AddItems from "./InfoModals/AddItems";
import EditQuantity from "./InfoModals/EditQuantity";
import SimplePopup from "../../../components/PopUp/PopUp";
import useImagePopup from "../../../hooks/useImagePopup";
import ImageModal from "../../../components/Modals/ImageModal";
import { formatCurrency } from "../../../utils/globalFunctions";
import ChipStatus from "../../../components/ChipStatus";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import CreateFinalAcceptance from "./InfoModals/CreateFinalAcceptance";
import InfoModal from "../../../components/Modals/InfoModal";
import ChangeD6Type from "./InfoModals/ChangeD6Type";

const QuotationInfo = ({ quotationInfo, isLoading, type }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.colorTheme);
  const { quotationId } = useParams();
  const [actionType, setActionType] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [useAsTemplateModal, setUseAsTemplateModal] = useState(false);
  const [createFinalAcceptanceModal, setCreateFinalAcceptanceModal] =
    useState(false);
  const [approvalCheckModal, setApprovalCheckModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [addItems, setAddItems] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [changeD6TypeModal, setChangeD6TypeModal] = useState(false);

  const handleClick = (rowData, event = null) => {
    setSelectedId(rowData);
    setAnchor(anchor ? null : event ? event.currentTarget : null);
  };
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const handleDownload = (data, fileName) => {
    // Create a URL for the Blob data
    const url = URL.createObjectURL(data);
    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const { mutateAsync: handleSeparated } = useMutation({
    mutationFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: quotationId,
          State: "Separated",
          Prices: !isThisAcceptanceModule,
        },
        responseType: "arraybuffer", // Important for receiving binary data
      });
      return new Blob([response.data], { type: "application/pdf" }); // Blob => binary large object
    },
    onSuccess: (data) => {
      handleDownload(
        data,
        isThisAcceptanceModule
          ? `${quotationInfo.Details[0].SiteName} - Acceptance in S/I separated items`
          : `${quotationInfo.Details[0].SiteName} - Quotation in S/I separated items`
      );
    },
    onError: (error) => {
      console.error(error.response);
    },
  });
  const { mutateAsync: handleConsolidated } = useMutation({
    mutationFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: quotationId,
          State: "Consolidated",
          Prices: !isThisAcceptanceModule,
        },
        responseType: "arraybuffer",
      });
      return new Blob([response.data], { type: "application/pdf" });
    },
    onSuccess: (data) => {
      handleDownload(
        data,
        isThisAcceptanceModule
          ? `${quotationInfo.Details[0].SiteName} - Acceptance with consolidated items`
          : `${quotationInfo.Details[0].SiteName} - Quotation with consolidated items`
      );
    },
  });

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmDeleteQuotation":
        return "deleteQuotation";
      case "confirmWaitingApproval":
        return "waitingApproval";
      case "confirmChangeToInProgress":
        return "changeToInProgress";
      case "confirmApprove":
        return "approve";
      case "confirmCheckApproval":
        return "checkApproval";
      case "confirmDeleteItem":
        return "deleteItem";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType, id) => {
    switch (modalType) {
      case "createFinalAcceptance":
        setCreateFinalAcceptanceModal(true);
        break;
      case "changeD6Type":
        setChangeD6TypeModal(true);
        break;
      case "useAsTemplate":
        setUseAsTemplateModal(true);
        break;
      case "approvalCheck":
        setApprovalCheckModal(true);
        break;
      case "approve":
        setApproveModal(true);
        break;
      case "addItems":
        setAddItems(true);
        break;
      case "confirmDeleteQuotation":
      case "confirmWaitingApproval":
      case "confirmChangeToInProgress":
      case "confirmApprove":
      case "confirmCheckApproval":
        setActionType(getActionType(modalType));
        setConfirmModal(true);
        break;
      case "confirmDeleteItem":
        setActionType(getActionType(modalType));
        setIdToDelete(id);
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };
  const { mutateAsync: handleDelete, isPending: pendingDelete } = useMutation({
    mutationFn: async () => {
      return Axios.delete("/Quotations", {
        params: {
          SelectedNo: quotationId,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      enqueueSnackbar("Quotation Deleted Successfully", {
        variant: "success",
      });
      navigate("/quotations");
      queryClient.invalidateQueries({
        queryKey: ["quotations"],
      });
    },
  });
  const useQuotationMutation = (State, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/Quotations", {
          SelectedNo: quotationId,
          State: State,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["quotation", "info"],
        });
      },
    });

    return { mutateAsync, isPending };
  };

  const { mutateAsync: handleApproval, isPending: pendingApproval } =
    useQuotationMutation(
      "Waiting Approval",
      "Status Changed to Waiting Approval Successfully"
    );

  const {
    mutateAsync: handleChangeToInProgress,
    isPending: pendingInProgress,
  } = useQuotationMutation(
    "In Progress",
    "Status Changed to In Progress Successfully"
  );

  const { mutateAsync: approval, isPending: approve } = useQuotationMutation(
    "Approved",
    "Approved Successfully"
  );

  const { mutateAsync: checkApproval, isPending: checkApprovePending } =
    useQuotationMutation("Check Approval", "Approval Check Successfully");

  const { mutateAsync: handleDeleteItem, isPending: pendingDeleteItem } =
    useMutation({
      mutationFn: async () => {
        return Axios.put("/Quotations", {
          State: "DeleteItems",
          SelectedNo: quotationId,
          DeleteidItems: `${idToDelete}`,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(
          t("info_modules.missions.description.deleted_successfully"),
          {
            variant: "success",
          }
        );
        queryClient.invalidateQueries({
          queryKey: ["quotation", "info"],
        });
      },
    });

  const [isBulkEditEnabled, setIsBulkEditEnabled] = useState(false);
  const [quotationsItems, setQuotationsItems] = useState([]);

  const handleBulkEdit = () => {
    if (isBulkEditEnabled) {
      editItem();
    } else {
      setIsBulkEditEnabled(true);
    }
  };

  const handleRowEdit = (params, event) => {
    // Use a Map to keep track of the last occurrence of each `idWorkOrderD6Items`

    setQuotationsItems((prev) =>
      Array.from(
        [
          ...prev,
          {
            idWorkOrderD6Items: params.id,
            ItemQTY: Number(event.target.value),
          },
        ]
          .reduce(
            (map, item) => map.set(item.idWorkOrderD6Items, item),
            new Map()
          )
          .values()
      )
    );
  };
  const handleCancelBulkEdit = () => {
    setIsBulkEditEnabled(false);
    setQuotationsItems([]);
  };

  const { mutateAsync: editItem, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Quotations", {
        State: "UpdateMultiItems",
        MultiItems: quotationsItems,
        SelectedNo: quotationId,
      });
    },
    onSuccess: (data) => {
      setIsBulkEditEnabled(false);
      setQuotationsItems([]);
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });

  const getColumns = (shouldShowAdditionalColumns) => {
    const baseColumns = [
      {
        field: "PriceListSN",
        headerName: t("data_grid.headers.code"),
        width: 80,
      },
      {
        field: "Description",
        headerName: t("data_grid.headers.description"),
        flex: 1,
      },
      {
        field: "SI",
        headerName: t("data_grid.headers.s_i"),
        width: 60,
      },
      {
        field: "Unit",
        headerName: t("data_grid.headers.unit"),
        width: 80,
      },
      {
        field: "ItemQTY",
        headerName: t("data_grid.headers.qty"),
        width: 80,
        editable: isBulkEditEnabled,
        renderCell: (params) =>
          isBulkEditEnabled ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme === "dark" ? "#333" : "#e0e0e0",
                width: "100%",
                padding: "10px ",
                border: "1px solid ",
                borderColor: theme === "dark" ? "#e0e0e0" : "#333",
              }}
            >
              {params.value}
            </Box>
          ) : (
            params.value
          ),
        type: "number",
      },
    ];

    const additionalColumns = [
      {
        field: "TheItemPrice",
        headerName: t("data_grid.headers.unit_price"),
        renderCell: (params) => formatCurrency(params.value),
      },
      {
        field: "TotalPrice",
        headerName: t("data_grid.headers.total_price"),
        renderCell: (params) => formatCurrency(params.value),
      },
      {
        field: "PriceListRefNo",
        headerName: t("data_grid.headers.price_list"),
        width: 180,
      },
    ];

    return shouldShowAdditionalColumns
      ? baseColumns.concat(additionalColumns)
      : baseColumns;
  };

  // Usage
  const isThisAcceptanceModule = type === "acceptance";
  const itemsColumns = getColumns(!isThisAcceptanceModule);

  if (quotationInfo?.Actions.includes("Edit Details") && !isBulkEditEnabled) {
    itemsColumns.push({
      field: "Actions",
      type: "actions",
      headerName: t("data_grid.headers.actions"),
      width: 110,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<Edit />}
          label="Edit"
          onClick={(event) => handleClick(params.row, event)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() =>
            handleOpenModal("confirmDeleteItem", params.row.idWorkOrderD6Items)
          }
        />,
      ],
    });
  }

  const supplyInvoices =
    quotationInfo?.Invoices !== null && quotationInfo?.Invoices[0];
  const installInvoices =
    quotationInfo?.Invoices !== null && quotationInfo?.Invoices[1];

  const quotationPropertyFirstColumn = [
    {
      label: isThisAcceptanceModule
        ? t("info_modules.quotation.description.supply_statement_ref")
        : t("info_modules.quotation.description.supply_invoice_ref"),
      value:
        quotationInfo?.Invoices === null
          ? "NA"
          : isThisAcceptanceModule
          ? supplyInvoices?.InvoiceNo
          : supplyInvoices?.GovernmentInvoice,
    },
    {
      label: isThisAcceptanceModule
        ? t("info_modules.quotation.description.install_statement_ref")
        : t("info_modules.quotation.description.install_invoice_ref"),
      value:
        quotationInfo?.Invoices === null
          ? "NA"
          : isThisAcceptanceModule
          ? installInvoices?.InvoiceNo
          : installInvoices?.GovernmentInvoice,
    },
    {
      label: t("info_modules.quotation.description.supply_inv_status"),
      value:
        quotationInfo?.Invoices === null ? (
          "NA"
        ) : (
          <ChipStatus value={supplyInvoices?.InvoiceStatus} />
        ),
    },
  ];

  const quotationPropertySecondColumn = [
    {
      label: t("info_modules.quotation.description.supply_po_no"),
      value:
        quotationInfo?.Invoices === null ? "NA" : supplyInvoices?.ClientPONo,
    },
    {
      label: t("info_modules.quotation.description.install_po_no"),
      value:
        quotationInfo?.Invoices === null ? "NA" : installInvoices?.ClientPONo,
    },
    {
      label: t("info_modules.quotation.description.install_inv_status"),
      value:
        quotationInfo?.Invoices === null ? (
          "NA"
        ) : (
          <ChipStatus value={installInvoices?.InvoiceStatus} />
        ),
    },
  ];

  const quotationPropertyThirdColumn = [
    {
      label: t("data_grid.headers.total_supply"),
      value: formatCurrency(quotationInfo?.Details[0]?.WorkOrderD6Supply),
    },
    {
      label: t("data_grid.headers.total_install"),
      value: formatCurrency(quotationInfo?.Details[0]?.WorkOrderD6Install),
    },
    {
      label: t("data_grid.headers.total_amount"),
      value: formatCurrency(quotationInfo?.Details[0]?.WorkOrderD6Amount),
    },
  ];

  const actionTypeMap = {
    deleteQuotation: {
      onClick: handleDelete,
      description: t("info_modules.quotation.description.confirmation_delete"),
      isPending: pendingDelete,
    },
    waitingApproval: {
      onClick: handleApproval,
      description: t(
        "info_modules.quotation.description.confirmation_approval"
      ),
      isPending: pendingApproval,
    },
    changeToInProgress: {
      onClick: handleChangeToInProgress,
      description: t(
        "info_modules.quotation.description.confirmation_in_progress"
      ),
      isPending: pendingInProgress,
    },
    approve: {
      onClick: approval,
      description: t("info_modules.quotation.description.confirmation_approve"),
      isPending: approve,
    },
    checkApproval: {
      onClick: checkApproval,
      description: t(
        "info_modules.quotation.description.confirm_check_approve"
      ),
      isPending: checkApprovePending,
    },
    deleteItem: {
      onClick: handleDeleteItem,
      description: t(
        "info_modules.quotation.description.confirmation_delete_item"
      ),
      isPending: pendingDeleteItem,
    },
  };

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const [outerApproveModal, setOuterApproveModal] = useState(false);
  const handleCloseOuterApproveModal = () => setOuterApproveModal(false);
  const handleOpenOuterApproveModal = () => setOuterApproveModal(true);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SimplePopup
            id={id}
            open={open}
            anchor={anchor}
            onClose={() => setAnchor(null)}
            content={
              <EditQuantity
                label={selectedId?.PriceListSN}
                setAnchor={setAnchor}
                quantity={selectedId?.ItemQTY}
                selectedId={selectedId}
              />
            }
          />
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t("info_modules.quotation.description.quotation_details")}
              editButton={quotationInfo?.Actions.includes("Edit Details")}
              deleteButton={quotationInfo?.Actions.includes("Delete")}
              viewButton={quotationInfo?.Actions.includes("View")}
              onClickDelete={() => handleOpenModal("confirmDeleteQuotation")}
              onClickExport={() => handleOpenModal("exportQuotation")}
              onClickView={() => handleImageModalOpen(quotationId)}
              onClickEdit={() => setIsEditable(true)}
              content={
                <QuotationDetailsCard
                  quotationInfo={quotationInfo}
                  handleOpenModal={handleOpenModal}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  outerModal={{
                    open: outerApproveModal,
                    handleClose: handleCloseOuterApproveModal,
                    handleOpen: handleOpenOuterApproveModal,
                  }}
                />
              }
            />
            <InfoCard
              title={t("info_modules.quotation.description.quotation_info")}
              content={
                isThisAcceptanceModule ? (
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {quotationPropertyFirstColumn
                        .slice(0, 2)
                        .map(({ label, value }) => (
                          <ChipComponent
                            label={label}
                            value={value}
                            key={label}
                          />
                        ))}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {quotationPropertySecondColumn
                        .slice(0, 2)
                        .map(({ label, value }) => (
                          <ChipComponent
                            label={label}
                            value={value}
                            key={label}
                          />
                        ))}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {[
                        quotationPropertyFirstColumn.at(-1),
                        quotationPropertySecondColumn.at(-1),
                      ].map(({ label, value }) => (
                        <ChipComponent
                          label={label}
                          value={value}
                          key={label}
                        />
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {quotationPropertyFirstColumn.map(({ label, value }) => (
                        <ChipComponent
                          label={label}
                          value={value}
                          key={label}
                        />
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      {quotationPropertySecondColumn.map(({ label, value }) => (
                        <ChipComponent
                          label={label}
                          value={value}
                          key={label}
                        />
                      ))}
                    </Grid>

                    {!isThisAcceptanceModule && (
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        {quotationPropertyThirdColumn.map(
                          ({ label, value }) => (
                            <ChipComponent
                              label={label}
                              value={value}
                              key={label}
                            />
                          )
                        )}
                      </Grid>
                    )}
                  </Grid>
                )
              }
            />
            <InfoCard
              title={t("info_modules.quotation.description.work_order_details")}
              content={<WorkOrderDetails quotationInfo={quotationInfo} />}
            />
            <InfoCard
              title={t("info_modules.quotation.description.items_list")}
              addIcon={quotationInfo?.Actions.includes("Edit Details")}
              onClick={() => handleOpenModal("addItems")}
              content={
                <FormsDataGrid
                  rows={quotationInfo?.Items || []}
                  columns={itemsColumns}
                  getRowId={(row) => row.idWorkOrderD6Items}
                  toolbar
                  showExportButton
                  thirdExport={quotationInfo?.Actions.includes("Export")}
                  fourthExport={quotationInfo?.Actions.includes("Export")}
                  thirdOptionName={t(
                    "info_modules.quotation.description.separated"
                  )}
                  fourthOptionName={t(
                    "info_modules.quotation.description.consolidated"
                  )}
                  thirdExportOption={handleSeparated}
                  fourthExportOption={handleConsolidated}
                  bulkEditBtn={
                    !isThisAcceptanceModule &&
                    quotationInfo?.Actions.includes("Edit Details") && {
                      isBulkEditEnabled,
                      bulkEditFunction: handleBulkEdit,
                      isLoading: isPending,
                      cancelBulkEdit: handleCancelBulkEdit,
                    }
                  }
                  onCellEditStop={handleRowEdit}
                />
              }
            />
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          {quotationInfo?.Actions.includes("Use As Template") && (
            <UseAsTemplate
              open={useAsTemplateModal}
              handleClose={() => setUseAsTemplateModal(false)}
              type={type}
            />
          )}

          {quotationInfo?.Actions.includes("Create Final Acceptance") && (
            <CreateFinalAcceptance
              open={createFinalAcceptanceModal}
              handleClose={() => setCreateFinalAcceptanceModal(false)}
              rows={quotationInfo?.Items || []}
              columns={itemsColumns}
              quotationInfo={quotationInfo?.Details[0]}
              quotationId={quotationId}
            />
          )}

          <ApprovalDocument
            open={approvalCheckModal}
            handleClose={() => setApprovalCheckModal(false)}
          />
          <ConfirmApprove
            open={approveModal}
            handleClose={() => setApproveModal(false)}
            quotationInfo={quotationInfo}
            outerModal={{
              open: outerApproveModal,
              handleClose: handleCloseOuterApproveModal,
              handleOpen: handleOpenOuterApproveModal,
            }}
          />
          <AddItems open={addItems} handleClose={() => setAddItems(false)} />
          <ImageModal
            open={isImageModalOpen}
            handleClose={handleCloseImageModal}
            image={image}
            loading={isImageLoading}
            saveLabel={`${quotationId} - ${
              isThisAcceptanceModule ? "Acceptance" : "Quotation"
            }`}
          />
          <InfoModal
            open={changeD6TypeModal}
            handleClose={() => setChangeD6TypeModal(false)}
            title={t("info_modules.quotation.description.change_d6_type")}
            width="28rem"
            content={
              <ChangeD6Type
                handleClose={() => {
                  setChangeD6TypeModal(false);
                }}
                quotationD6Type={quotationInfo?.Details[0]?.WorkOrderD6TypeName}
              />
            }
          />
        </>
      )}
    </>
  );
};
export default QuotationInfo;
