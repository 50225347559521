import { Box, Grid } from "@mui/material";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";

const WorkOrderDetails = ({ quotationInfo }) => {
  const [t] = useTranslation("global");

  const workOrderProperty = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: quotationInfo?.Details[0]?.WorkOrderNo,
    },
    {
      label: t("data_grid.headers.date"),
      value: quotationInfo?.Details[0]?.WorkOrderDate,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={quotationInfo?.Details[0]?.WorkOrderStatus} />,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: quotationInfo?.Details[0]?.SiteName,
    },
    {
      label: t("data_grid.headers.type"),
      value: quotationInfo?.Details[0]?.SiteType,
    },

    {
      label: t("data_grid.headers.user"),
      value: quotationInfo?.Details[0]?.WUser,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: quotationInfo?.Details[0]?.SiteCode,
    },

    {
      label: t("data_grid.headers.requestor"),
      value: quotationInfo?.Details[0]?.RequestorsName,
    },
    {
      label: t("data_grid.headers.hold_reason"),
      value: quotationInfo?.Details[0]?.WorkOrderHoldReason,
    },
    {
      label: t("data_grid.headers.authorities"),
      value: quotationInfo?.Details[0]?.RejectedByAuthorities,
    },
    {
      label: t("data_grid.headers.project"),
      value: quotationInfo?.Details[0]?.CompanyProjectsName,
    },
    {
      label: t("data_grid.headers.sub_project"),
      value: quotationInfo?.Details[0]?.SubProjectsName,
    },
    {
      label: t("data_grid.headers.team_leader"),
      value: quotationInfo?.Details[0]?.TeamLeadersName,
    },
    {
      label: t("data_grid.headers.latitude"),
      value: quotationInfo?.Details[0]?.Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: quotationInfo?.Details[0]?.Longitude,
    },
    {
      label: t("data_grid.headers.area"),
      value: quotationInfo?.Details[0]?.GovernoratesName,
    },
  ];
  return (
    <Box>
      <Grid container spacing={1}>
        {workOrderProperty.map((property, index) => (
          <Grid item xs={4} key={index}>
            <ChipComponent label={property.label} value={property.value} />
          </Grid>
        ))}
        <Grid item xs={8}>
          <ChipComponent
            label={t("data_grid.headers.site_address")}
            value={quotationInfo?.Details[0]?.SiteAddress}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkOrderDetails;
