import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import InfoModal from "../Modals/InfoModal";
import { useTranslation } from "react-i18next";
import ChartSwitch from "./ChartSwitch";
import NoData from "./NoData";
import GenericModal from "../Modals/DashboardModal";

const PieChart = ({
  chartData,
  chartData2,
  switchConfig,
  title,
  chartName,
  modalConfig,
  modalWidth = "70rem",
  colors,
  isSwitchChecked,
}) => {
  const { t } = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const currentChartData = switchConfig
    ? isSwitchChecked
      ? chartData
      : chartData2
    : chartData;

  const [currentDataSlice, setCurrentDataSlice] = useState(null);

  const options = {
    colors,
    plotOptions: {
      donut: {
        customScale: 0.8,
      },
    },
    chart: {
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const sliceName = currentChartData[config.dataPointIndex].name;
          const insuranceNotifications = {
            medical_insurance: t("notifications.not_covered"),
            social_insurance: t("notifications.not_insured"),
          };

          setCurrentDataSlice(insuranceNotifications[chartName] || sliceName);

          if (!["Medical Insured", "Social Insured"].includes(sliceName)) {
            handleOpenModal(chartName);
          }
        },
      },
    },
    labels: currentChartData.map((item) => item.name),
    legend: {
      show: true,
      position: "bottom",
      labels: {
        colors: theme === "dark" ? grey[300] : grey[700],
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const projectName = opts.w.globals.labels[opts.seriesIndex];
        const dataItem = currentChartData.find(
          (item) => item.name === projectName
        );
        return dataItem ? dataItem.data : "";
      },
    },
  };

  const series = currentChartData.map((item) => item.data);

  // Handle modal states
  const [isModalOpen, setIsModalOpen] = useState({
    open_missions: false,
    social_insurance: false,
    medical_insurance: false,
  });

  const handleOpenModal = (chartName) =>
    setIsModalOpen((prev) => ({ ...prev, [chartName]: true }));

  const handleCloseModal = () =>
    setIsModalOpen({
      open_missions: false,
      social_insurance: false,
      medical_insurance: false,
    });

  return (
    <>
      {
        // If there is no data, show a message
        currentChartData.length === 0 ? (
          <NoData />
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width="100%"
            height="100%"
            style={{ margin: "0 auto" }}
          />
        )
      }
      <InfoModal
        open={isModalOpen[chartName]}
        title={`${title} ${currentDataSlice !== null ? " - " : ""} ${
          currentDataSlice !== null ? currentDataSlice : ""
        }`}
        content={
          <GenericModal
            dataName={currentDataSlice}
            isSwitchChecked={isSwitchChecked}
            modalConfig={modalConfig}
            chartName={chartName}
          />
        }
        handleClose={handleCloseModal}
        width={modalWidth}
      />
    </>
  );
};

const PieChartContainer = ({
  chartData,
  chartData2,
  switchConfig,
  title,
  chartName,
  modalConfig,
  modalWidth = "70rem",
  colors,
  doubleChart,
}) => {
  const { i18n } = useTranslation("global");

  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleSwitchState = () => setIsSwitchChecked(!isSwitchChecked);

  return (
    <Box
      component="div"
      dir="ltr"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        dir={i18n.dir()}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "text.primary",
            textDecorationColor: theme === "dark" ? "#8a8a8a" : "#cfcfcf",
          }}
        >
          {title}
        </Typography>
        {switchConfig && (
          <ChartSwitch
            isSwitchChecked={isSwitchChecked}
            handleSwitchState={handleSwitchState}
            leftLabel={switchConfig.leftLabel}
            rightLabel={switchConfig.rightLabel}
          />
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: doubleChart ? "grid" : "block",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <PieChart
          chartData={chartData}
          chartData2={chartData2}
          switchConfig={switchConfig}
          title={title}
          chartName={chartName}
          modalConfig={modalConfig}
          modalWidth={modalWidth}
          colors={colors}
          isSwitchChecked={isSwitchChecked}
        />
        {doubleChart && (
          <PieChart
            chartData={doubleChart.chartData}
            switchConfig={doubleChart.switchConfig}
            title={doubleChart.title}
            chartName={doubleChart.chartName}
            modalConfig={doubleChart.modalConfig}
            modalWidth={doubleChart.modalWidth}
            colors={doubleChart.colors}
          />
        )}
      </Box>
    </Box>
  );
};

export default PieChartContainer;
