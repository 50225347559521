import { Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios from "../../../../../network/Axios";
import { useQuery } from "@tanstack/react-query";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import ChipStatus from "../../../../../components/ChipStatus";
import BarChart from "../../../../../components/charts/BarChart";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";

export const groupByInvoiceNo = (rows) => {
  const grouped = [];
  rows?.forEach((row) => {
    const { InvoiceNo } = row;
    let group = grouped.find((g) => g.invoiceNo === InvoiceNo);

    if (!group) {
      group = { invoiceNo: InvoiceNo, rows: [] };
      grouped.push(group);
    }

    group.rows.push(row);
  });

  return grouped;
};

export function DetailPanelContent({ rows }) {
  const { t } = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);
  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/quotations/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
    },
    {
      field: "D6InvoicedAmount",
      headerName: t("data_grid.headers.invoiced_amount"),
      width: 120,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 110,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 120,
    },

    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.acceptance_status"),
      width: 130,
      renderCell: ({ value }) => <ChipStatus value={value} />,
    },
  ];

  return (
    <Box sx={{ ml: 6.7 }}>
      <FormsDataGrid
        rows={rows || []}
        columns={columns}
        getRowId={(row) => row.WorkOrderD6No}
        hideFooter
        height="auto"
        backgroundDetails={theme === "dark" ? "#2b3238" : "#54585d"}
        titleDetails="11px"
        cellFontDetails="10.6px"
        headerHeight={24}
      />
    </Box>
  );
}

const StatementsInSignaturePhase = ({
  invoicesSignatureAmount,
  invoicesSignatureCount,
}) => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [dataNameCustom, setDataNameCustom] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["statementsInSignaturePhase", dataNameCustom],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: dataNameCustom,
          State: "InvoicesSignaturePerStatus",
        },
      });
      return response.data.InvoicesSignatureCount || [];
    },
    enabled: open,
  });

  const groupedRows = useMemo(() => groupByInvoiceNo(data), [data]);

  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]); // Return first row of each group
    }

    return groupedRows
      .flatMap((group) => group.rows) // Flatten the grouped rows for filtering
      .filter((invoice) =>
        Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      );
  }, [groupedRows, searchInput]);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows || []} />; // Default to empty array if undefined
    },
    [groupedRows]
  );

  const totalAmount = invoicesSignatureAmount.reduce(
    (acc, data) => acc + parseFloat(data.Amount.replace(/,/g, "")),
    0
  );

  const totalCount = invoicesSignatureCount.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const combinedInvoicesSignatureByAmount = [
    { projectName: "Total", Amount: totalAmount },
    ...invoicesSignatureAmount.map((invoice) => ({
      projectName: invoice.StatementStatus,
      Amount: parseFloat(invoice.Amount.replace(/,/g, "")),
    })),
  ];

  const combinedInvoicesSignatureByCount = [
    { projectName: "Total", Count: totalCount },
    ...invoicesSignatureCount.map((invoice) => ({
      projectName: invoice.StatementStatus,
      Count: invoice.Count,
    })),
  ];

  const invoicesSignatureByAmountSeries = [
    {
      name: "Amount",
      data: combinedInvoicesSignatureByAmount.map((data) => data.Amount),
    },
  ];

  const invoicesSignatureByCountSeries = [
    {
      name: "Count",
      data: combinedInvoicesSignatureByCount.map((data) => data.Count),
    },
  ];

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/clientinvoices/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 100,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 100,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 120,
      flex: 1,
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 90,
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
      width: 100,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 30,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
    },
    {
      field: "StatementStatus",
      headerName: t("data_grid.headers.statement_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => <ChipStatus value={value} />,
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="statementsInSignaturePhase"
        chartData={combinedInvoicesSignatureByAmount}
        chartData2={combinedInvoicesSignatureByCount}
        seriesData={invoicesSignatureByAmountSeries}
        seriesData2={invoicesSignatureByCountSeries}
        title={t("main_cards.statements_progress_chart")}
        horizontal
        type="bar"
        xaxisLabelFormatter={(data) => data.projectName}
        switchConfig={{
          leftLabel: t("main_cards.labels.by_count"),
          rightLabel: t("main_cards.labels.by_amount"),
        }}
        open={open}
        handleOpen={() => setOpen(true)}
        handleCloseCustom={() => setOpen(false)}
        dataNameCustom={dataNameCustom}
        setDataNameCustom={setDataNameCustom}
        content={
          <Box p={2}>
            {isLoading || isRefetching ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                rows={filteredInvoices || []}
                columns={columns}
                height={420}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => "auto"}
                getRowId={(row) => row.InvoiceNo}
                toolbar
                searchValueCustom
                customSearchValue={searchInput}
                customSearchOnChange={(e) => setSearchInput(e.target.value)}
              />
            )}
          </Box>
        }
      />
    </CardContainer>
  );
};

export default StatementsInSignaturePhase;
