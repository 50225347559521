import { Box, Grid } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../../../components/Chips/ChipComponent";
import InfoModal from "../../../../../components/Modals/InfoModal";
import EditDrivingLicense from "./EditDrivingLicense";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { employeeRecordsService } from "../../../../../api/employeeRecords";
import ImageModal from "../../../../../components/Modals/ImageModal";
import { combineImages, convertImage } from "../../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { useLabels } from "./hooks/useLabels";

const DrivingLicense = ({ data, nationalIdData }) => {
  const { t } = useTranslation("global");
  const {
    DrivingLicenseType,
    EmpInfoDrivingLicenseNo,
    LicenseEndDate,
    LicenseRegistrationDate,
    LicenseTrafficDepartment,
    LicenseTrafficUnit,
  } = data || {
    DrivingLicenseType: "NA",
    EmpInfoDrivingLicenseNo: "NA",
    LicenseEndDate: "NA",
    LicenseRegistrationDate: "NA",
    LicenseTrafficDepartment: "NA",
    LicenseTrafficUnit: "NA",
  };

  const labels = useLabels();

  const drivingLicenseDetails = [
    {
      label: labels.drivingLicenseType,
      value: DrivingLicenseType,
    },
    {
      label: labels.drivingLicenseNo,
      value: EmpInfoDrivingLicenseNo,
    },
    {
      label: labels.licenseEndDate,
      value: LicenseEndDate,
    },
    {
      label: labels.licenseRegistrationDate,
      value: LicenseRegistrationDate,
    },
    {
      label: labels.licenseTrafficDepartment,
      value: LicenseTrafficDepartment,
    },
    {
      label: labels.licenseTrafficUnit,
      value: LicenseTrafficUnit,
    },
  ];

  // Handling the edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Handling the image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const { empCode } = useParams();

  const { refetch } = useQuery({
    queryKey: ["drivingLicenseFormData", empCode],
    queryFn: () =>
      employeeRecordsService.getEmployeeDrivingLicenseData(empCode),
    enabled: false,
  });

  return (
    <>
      <InfoCard
        viewButton={data}
        onClickView={() => {
          setIsImageLoading(true);
          handleOpenImageModal();
          refetch().then((data) => {
            const frontImage =
              data.data.DrivingLicense[0].EmpInfoDrivingLicenseFront;
            const backImage =
              data.data.DrivingLicense[0].EmpInfoDrivingLicenseBack;

            const doTwoImagesExist = frontImage !== null && backImage !== null;

            if (!doTwoImagesExist) {
              const image = convertImage(
                frontImage !== null ? frontImage.data : backImage.data
              );
              setImage(image);
              setIsImageLoading(false);
              return;
            }

            const image1 = convertImage(frontImage.data);

            const image2 = convertImage(backImage.data);

            const imageOneInstance = new Image();
            const imageTwoInstance = new Image();

            imageOneInstance.src = image1;
            imageTwoInstance.src = image2;

            combineImages(image1, image2)
              .then((b64) => setImage(b64))
              .then(() => setIsImageLoading(false));
          });
        }}
        editButton
        onClickEdit={handleOpenModal}
        title={t("info_modules.employee_records.cards.driving_license.title")}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Grid container spacing={1}>
              {drivingLicenseDetails.map(({ label, value }) => (
                <Grid item xs={4} key={label}>
                  <ChipComponent label={label} value={value} />
                </Grid>
              ))}
            </Grid>
          </Box>
        }
      />
      <InfoModal
        open={isModalOpen}
        title={t(
          "info_modules.employee_records.cards.driving_license.edit_form.title"
        )}
        content={
          <EditDrivingLicense
            handleClose={handleCloseModal}
            nationalIdData={nationalIdData}
          />
        }
        handleClose={handleCloseModal}
        width="40rem"
      />
      <ImageModal
        image={image}
        image2
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        title={t(
          "info_modules.employee_records.cards.driving_license.image_titles.driving_license_images"
        )}
        loading={isImageLoading}
        saveLabel={`${empCode.replace("/", "-")}-DrivingLicense`}
      />
    </>
  );
};

export default DrivingLicense;
