import { useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import { Tooltip, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import { vacationsRequestsService } from "../../../api/vacationsRequests";
import EditVacationRequest from "../../InformationPages/EmployeeRecords/cards/Vacations/NewEditForm/EditVacationRequest";

const VacationNoAttachment = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [id, setId] = useState(null);
  const [modalType, setModalType] = useState(null);

  const handleOpenModal = (type, id) => {
    setId(id);
    setModalType(type);
  };

  const { data: vacations, isLoading: loadingVacations } = useQuery({
    queryKey: ["dashboardVacations"],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          State: "Vacation",
        },
      });
      return response.data.AllVacationNoAttachment;
    },
    enabled: open,
  });

  const { mutateAsync: handleDeleteItem, isPending: pendingDeleteItem } =
    useMutation({
      mutationFn: vacationsRequestsService.deleteVacationRecord,
      onSuccess: (data) => {
        enqueueSnackbar(data.msg, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return ["dashboardVacations", "dashboardData"].includes(
              query.queryKey[0]
            );
          },
        });
      },
    });

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 110,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/employeerecords/${params.value.replace("/", "%2F")}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.english_name"),
      width: 100,
      flex: 1,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.arabic_name"),
      width: 120,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 120,
    },
    {
      field: "VacationsTypesName",
      headerName: t("data_grid.headers.type"),
      width: 100,
    },
    {
      field: "VacationStartDate",
      headerName: t("data_grid.headers.start_date"),
      width: 100,
    },
    {
      field: "VacationEndDate",
      headerName: t("data_grid.headers.end_date"),
      width: 100,
    },

    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 100,
      getActions: (params) => [
        <>
          <Tooltip title={t("tooltip.edit")}>
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              key={params.id}
              onClick={() =>
                handleOpenModal("editVacation", params.row.idEmpVacation)
              }
            />
          </Tooltip>
          <Tooltip title={t("tooltip.delete")}>
            <GridActionsCellItem
              icon={<DeleteIcon color="error" />}
              label="Delete"
              key={params.id}
              onClick={() =>
                handleOpenModal("confirmDeleteItem", params.row.idEmpVacation)
              }
            />
          </Tooltip>
        </>,
      ],
    },
  ];

  return (
    <InfoModal
      open={open}
      handleClose={handleClose}
      title={t("hyper_links_data_grid.vacation_without_attachments")}
      width="67rem"
      content={
        loadingVacations ? (
          <FormSpinner />
        ) : (
          <>
            <FormsDataGrid
              rows={vacations || []}
              columns={columns}
              loading={loadingVacations}
              getRowId={(row) => row.idEmpVacation}
              height={420}
              toolbar
              showExportButton
              showQuickFilter
            />
            <ConfirmationInfoModal
              color="error"
              open={modalType === "confirmDeleteItem"}
              handleClose={() => setModalType(null)}
              onClick={() => handleDeleteItem(id)}
              pending={pendingDeleteItem}
              description={t(
                "hyper_links_data_grid.confirmation_delete_vacation"
              )}
            />

            <InfoModal
              open={modalType === "editVacation"}
              title={t(
                "info_modules.employee_records.cards.vacations.edit_form.title"
              )}
              content={
                <EditVacationRequest
                  handleClose={() => setModalType(null)}
                  vacationID={id}
                />
              }
              handleClose={() => setModalType(null)}
              width="30rem"
            />
          </>
        )
      }
    />
  );
};

export default VacationNoAttachment;
