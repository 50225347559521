import { useQuery } from "@tanstack/react-query";
import Axios from "../network/Axios";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { statusColors } from "./statuses";
import ProgressBarCell from "../components/ProgressBar/ProgressBar";
import Spinner from "../components/Spinners/Spinner";
import ChipStatus from "../components/ChipStatus";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useSelector } from "react-redux";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const ClientPurchaseOrders = () => {
  const { newForm } = useSelector((state) => state.theme);

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const [t] = useTranslation("global");

  const statusNames = [
    {
      name: "Open",
      displayName: t("data_grid.tabs.open"),
    },
    { name: "Closed", displayName: t("data_grid.tabs.closed") },
    { name: "NA", displayName: t("data_grid.tabs.na") },
  ];

  const columns = [
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            to={`/clientpurchaseorders/${params.value}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "ClientPODate",
      headerName: t("data_grid.headers.received_date"),
      width: 140,
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "ClientPODescription",
      headerName: t("data_grid.headers.description"),
      width: 140,
      flex: 1,
    },
    {
      field: "CompanyAccountName",
      headerName: t("data_grid.headers.account_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "ClientPOAmount",
      headerName: t("data_grid.headers.po_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "POAmount",
      headerName: t("data_grid.headers.invoiced_amount"),
      width: 140,
      flex: 1,
    },
    {
      field: "Percent",
      headerName: t("data_grid.headers.progress"),
      width: 110,
      renderCell: ({ value }) => {
        return ProgressBarCell(value);
      },
    },
    {
      field: "ClientPOStatus",
      headerName: t("data_grid.headers.status"),
      headerAlign: "center",
      width: 140,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["clientPurchaseOrders"],
    queryFn: async () => {
      const response = await Axios.get("/CLientPurchaseOrders");
      return response.data.result;
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.ClientPONo}
      statusColors={statusColors}
      statusNames={statusNames}
      filterField="ClientPOStatus"
      showReloadOverlay={isRefetching}
      reloadFunction={refetch}
      newButton
      newFunction={() => {
        if (newForm === "purchase_order" || newForm === null) {
          handleOpen("purchase_order");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("purchase_order");
          });
        }
      }}
    />
  );
};

export default ClientPurchaseOrders;
