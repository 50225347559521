import BarChart from "../../../../../components/charts/BarChart";
import CardContainer from "../../../CardContainer";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const WorkOrdersInProgress = ({
  workOrdersInProgressPerProject,
  workOrdersInProgressPerUser,
}) => {
  const { t } = useTranslation("global");

  const totalCountByProject = workOrdersInProgressPerProject.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalCountByUser = workOrdersInProgressPerUser.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const combinedWorkOrdersInProgressPerProject = [
    {
      projectName: "Total",
      Count: totalCountByProject,
    },
    ...workOrdersInProgressPerProject.map((project) => ({
      projectName: project.CompanyProjectsName,
      Count: project.Count,
    })),
  ];

  const workOrdersInProgressPerProjectSeries = [
    {
      name: "Count",
      data: combinedWorkOrdersInProgressPerProject.map((data) => data.Count),
    },
  ];

  const combinedWorkOrdersInProgressPerUser = [
    {
      projectName: "Total",
      Count: totalCountByUser,
    },
    ...workOrdersInProgressPerUser.map((user) => ({
      projectName: user.ERPUserNickName,
      Count: user.Count,
    })),
  ];

  const workOrdersInProgressPerUserSeries = [
    {
      name: "Count",
      data: combinedWorkOrdersInProgressPerUser.map((data) => data.Count),
    },
  ];

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <RouterLink
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </RouterLink>
      ),
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 140,
      flex: 1,
    },
  ];

  return (
    <CardContainer>
      <BarChart
        isClickable
        chartName="work_orders_in_progress"
        chartData={combinedWorkOrdersInProgressPerProject} // Show when Switch is true
        chartData2={combinedWorkOrdersInProgressPerUser} // Show when Switch is false
        seriesData={workOrdersInProgressPerProjectSeries}
        seriesData2={workOrdersInProgressPerUserSeries}
        title={t("main_cards.work_orders_in_progress")}
        horizontal
        type="bar"
        xaxisLabelFormatter={(data) => data.projectName}
        switchConfig={{
          leftLabel: t("main_cards.labels.per_user"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        modalConfig={{
          queryKey: "workOrdersInProgress",
          perUserState: "AllWorkOrderinProgressPerUser",
          perProjectState: "AllWorkOrderinProgress",
          columns,
          dataGridId: "WorkOrderNo",
        }}
      />
    </CardContainer>
  );
};

export default WorkOrdersInProgress;
