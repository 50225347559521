import { Box, Stack, TextField, Typography } from "@mui/material";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import ChipStatus from "../../../ChipStatus";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Save = ({ rows, statements, type, formFields }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.statement_no"),
      width: 130,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 140,
      flex: 1,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 140,
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 140,
      flex: 1,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.invoice_type"),
      width: 140,
      flex: 1,
    },
    {
      field: "StatementStatus",
      headerName: t("data_grid.headers.statement_status"),
      headerAlign: "center",
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const selectedStatements = rows.filter((row) =>
    statements.includes(row.InvoiceNo)
  );

  return (
    <Stack spacing={2} pb={1}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>
            {t("modules.statements_management.new_form.labels.date")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t(
                "modules.statements_management.new_form.fields.statement_date"
              )}
              value={formFields.date.value}
              onChange={(date) => formFields.date.setValue(date)}
              format="YYYY/MM/DD"
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "14px",
                },
              }}
              slotProps={{
                textField: {
                  size: "small",
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        </Stack>

        {type === "Invoice" && (
          <TextField
            label={t(
              "modules.statements_management.new_form.fields.gov_invoice_no"
            )}
            variant="outlined"
            value={formFields.govInvoiceNo.value}
            onChange={(e) => formFields.govInvoiceNo.setValue(e.target.value)}
            sx={{
              width: "15rem",
              "& .MuiInputBase-root": {
                fontSize: "14px",
              },
            }}
            size="small"
            required
          />
        )}
      </Box>
      <FormsDataGrid
        height={200}
        rows={selectedStatements || []}
        columns={columns}
        getRowId={(row) => row.InvoiceNo}
        hideFooter
        disableColumnMenu
        disableColumnResize
      />
    </Stack>
  );
};

export default Save;
