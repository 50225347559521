import { useState } from "react";
import { Backdrop, Box, Tooltip, CircularProgress, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { Link as RouterLink } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import InvoiceModal from "../../InformationPages/ClientInvoices/InfoModals/InvoiceModal";

const WithOutGovInv = ({ open, handleClose, data, isLoading, title }) => {
  const [t] = useTranslation("global");
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [clientInvoiceId, setClientInvoiceId] = useState(null);

  const handleOpenModal = (modalType, id) => {
    if (modalType === "invoice") {
      setOpenInvoiceModal(true);
      setClientInvoiceId(id);
    }
  };

  const handleCloseInvoiceModal = () => {
    setOpenInvoiceModal(false);
    setClientInvoiceId(null);
  };

  const { data: govInvoice, isLoading: loadingData } = useQuery({
    queryKey: ["edit", "govInvoice"],
    queryFn: async () => {
      const response = await Axios.get("/StatementsManagement", {
        params: {
          SelectedNo: clientInvoiceId,
          State: "Edit",
        },
      });
      return response.data.result[0];
    },
    enabled: openInvoiceModal && clientInvoiceId !== null,
  });

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 110,
      renderCell: (params) => (
        <Link
          component={RouterLink}
          to={`/clientinvoices/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 100,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 120,
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 120,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 100,
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 100,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 100,
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 100,
      getActions: (params) => [
        <Tooltip title={t("tooltip.invoice")} key="invoice">
          <GridActionsCellItem
            icon={<ReceiptIcon />}
            label="invoice"
            onClick={() => handleOpenModal("invoice", params.row.InvoiceNo)}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Box>
      <InfoModal
        open={open}
        handleClose={handleClose}
        title={title}
        width="67rem"
        content={
          <>
            <FormsDataGrid
              rows={data}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.idInvoices}
              height={420}
              toolbar
              showExportButton
              showQuickFilter
            />
            {loadingData ? (
              <Backdrop open={loadingData}>
                <CircularProgress color="primary" />
              </Backdrop>
            ) : (
              <InvoiceModal
                open={openInvoiceModal}
                handleClose={handleCloseInvoiceModal}
                clientInvoicesInfo={govInvoice}
                selectedNo={clientInvoiceId}
              />
            )}
          </>
        }
      />
    </Box>
  );
};

export default WithOutGovInv;
