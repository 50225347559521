import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import SelectEmployees from "./Steps/SelectEmployees";
import BasicInformation from "./Steps/BasicInformation";
import Privilege from "./Steps/Privilege/Privilege";
import DataGroup from "./Steps/DataGroup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MailService from "./Steps/MailService";
import Save from "./Steps/Save";
import { usersManagementService } from "../../../api/usersManagement";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";

const UsersManagementForm = ({ onDirtyChange }) => {
  const [t] = useTranslation("global");

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    if (activeStep === 1) {
      const areValid = await trigger(["email", "username", "nickname"]);
      if (!areValid) return;
      if ((await refetchCheckUsername()).isError) return;
      if ((await refetchCheckNickname()).isError) return;
    }

    if (activeStep === 2) {
      const areValid = await trigger([
        "userRole",
        "priceList",
        "level",
        "stores",
      ]);
      if (!areValid) return;
    }

    if (activeStep === 3) {
      const areValid = await trigger("dataGroup");
      if (!areValid) return;
    }

    if (activeStep === 5) {
      const {
        username,
        nickname,
        email,
        userRole,
        level,
        priceList,
        stores,
        dataGroup,
        mailService,
        empCode,
      } = getValues();

      const storesFormatted =
        stores.length === 1 && stores[0].id === -1 ? [] : stores;

      AddNewUser({
        State: "New",
        CompanyStores: storesFormatted,
        DataGroups: dataGroup,
        ERPUserLevel: level,
        ERPUserMail: email,
        ERPUserName: username,
        ERPUserNickName: nickname,
        ERPUserRoleName: userRole,
        EmpCode: empCode[0],
        MailServices: mailService,
        PriceListsGroupName: priceList,
      });
    }

    if (activeStep !== 5) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    t("modules.users_management.new_form.steps.select_employees"),
    t("modules.users_management.new_form.steps.basic_information"),
    t("modules.users_management.new_form.steps.privileges"),
    t("modules.users_management.new_form.steps.data_group"),
    t("modules.users_management.new_form.steps.mail_service"),
    t("modules.users_management.new_form.steps.save"),
  ];

  const initialValues = {
    isError: false,
    empCode: [],
    username: "",
    nickname: "",
    email: "",
    userRole: "",
    priceList: "",
    level: "",
    stores: [],
    dataGroup: [],
    dataGroupSelectionModel: [],
    mailService: [],
    mailServiceSelectionModel: [],
  };

  const schema = yup.object().shape({
    empCode: yup.array().required(t("modules.global_schema.select_value")),
    username: yup
      .string()
      .test("username", t("modules.global_schema.invalid_value"), (value) => {
        if (!value) return false;
        const usernameRegex = /^[a-zA-Z0-9_.]{3,16}$/;
        return usernameRegex.test(value);
      })
      .required(t("modules.global_schema.required")),
    nickname: yup.string().required(t("modules.global_schema.required")),
    email: yup
      .string()
      .test("email", t("modules.global_schema.invalid_value"), (value) => {
        if (!value) return false;
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
      })
      .required(t("modules.global_schema.required")),
    userRole: yup.string().required(t("modules.global_schema.select_value")),
    priceList: yup.string().required(t("modules.global_schema.select_value")),
    level: yup.string().required(t("modules.global_schema.required")),
    stores: yup.array().min(1, t("modules.global_schema.select_value")),
    dataGroup: yup.array().min(1, t("modules.global_schema.select_value")),
    mailService: yup.array().min(1, t("modules.global_schema.select_value")),
  });

  const {
    watch,
    register,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { isRefetching: isCheckingUsername, refetch: refetchCheckUsername } =
    useQuery({
      queryKey: ["employeeUsername"],
      queryFn: () => usersManagementService.CheckUsername(getValues().username),
      enabled: false,
      retry: false,
    });

  const { isRefetching: isCheckingNickname, refetch: refetchCheckNickname } =
    useQuery({
      queryKey: ["employeeNickname"],
      queryFn: () => usersManagementService.CheckNickname(getValues().nickname),
      enabled: false,
      retry: false,
    });

  const { handleClose } = useHandleForms({ dirty: isDirty, onDirtyChange });

  function reducer(state, action) {
    switch (action.type) {
      case "isError":
      case "mailServiceSelectionModel":
      case "dataGroupSelectionModel":
        return {
          ...state,
          [action.type]: action.payload,
        };
      default:
        throw new Error("Unknown action type: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues);

  const { mutateAsync: AddNewUser, isPending: isAddingNewUser } = useMutation({
    mutationFn: usersManagementService.addNewUser,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["usersManagement"] });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const views = {
    0: <SelectEmployees setValue={setValue} empCode={watch("empCode")} />,
    1: (
      <BasicInformation
        empCode={getValues().empCode}
        values={watch()}
        setFieldValue={setValue}
        register={register}
        errors={errors}
        isError={state.isError}
        dispatch={dispatch}
      />
    ),
    2: (
      <Privilege
        control={control}
        values={watch()}
        setValue={setValue}
        empCode={getValues().empCode}
        errors={errors}
        register={register}
      />
    ),
    3: (
      <DataGroup
        dataGroup={watch("dataGroup")}
        dataGroupSelectionModel={state.dataGroupSelectionModel}
        setValue={setValue}
        errors={errors}
        dispatch={dispatch}
      />
    ),
    4: (
      <MailService
        mailService={watch("mailService")}
        setValue={setValue}
        errors={errors}
        mailServiceSelectionModel={state.mailServiceSelectionModel}
        dispatch={dispatch}
      />
    ),
    5: <Save values={getValues()} />,
  };

  const isNextBtnDisabled =
    (activeStep === 0 && getValues().empCode.length === 0) ||
    (activeStep === 1 && (state.isError || Boolean(errors.email))) ||
    (activeStep === 3 && getValues().dataGroup.length === 0) ||
    (activeStep === 4 &&
      !getValues().mailService.every((value) => {
        return value.BCC || value.CC || value.TO;
      })) ||
    (activeStep === 5 && isAddingNewUser);

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step
              key={label}
              sx={{
                my: 3,
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        isPending={isAddingNewUser || isCheckingUsername || isCheckingNickname}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isNextBtnDisabled}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default UsersManagementForm;
