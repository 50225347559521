import { useEffect } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Box, Tooltip, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChipComponent = ({ label, total }) => {
  return (
    <Box display="flex" gap="6px" alignItems="center">
      <Chip
        size="small"
        variant="outlined"
        label={label}
        style={{
          borderWidth: 2,
          width: "7.5rem",
          borderRadius: 5,
          fontWeight: 500,
        }}
      />
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{total}</Typography>
    </Box>
  );
};
const ModifyItem = ({
  rows,
  onClick,
  setNewRows,
  totalAmount,
  totalInstall,
  totalSupply,
  setTotalAmount,
  setTotalInstall,
  setTotalSupply,
  type,
}) => {
  const handleDeleteClick = (id) => {
    onClick(id);
  };
  useEffect(() => {
    let totalAmountSum = 0;
    let totalInstallSum = 0;
    let totalSupplySum = 0;
    rows.forEach((row) => {
      const total = row.Price * (row.Quantity || 0);
      totalAmountSum += total;
      if (row.SI === "S") {
        totalSupplySum += total;
      } else {
        totalInstallSum += total;
      }
    });
    setTotalSupply(totalSupplySum.toFixed(2));
    setTotalAmount(totalAmountSum.toFixed(2));
    setTotalInstall(totalInstallSum.toFixed(2));
  }, [rows]);

  const [t] = useTranslation("global");

  const actionsColumn = {
    field: "Actions",
    headerName: t("data_grid.headers.actions"),
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        key={params.row.idPriceList}
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => handleDeleteClick(params.row.idPriceList)}
      />,
    ],
  };

  const priceColumn = {
    field: "Price",
    headerName: t("data_grid.headers.unit_price"),
  };

  const totalColumn = {
    field: "Total",
    headerName: t("data_grid.headers.total"),

    valueGetter: (params) => {
      const total = params.row.Price * (params.row.Quantity || 0);
      return total.toFixed(2);
    },
  };

  const baseColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 180,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Quantity",
      headerName: t("data_grid.headers.quantity"),

      valueGetter: (params) => params.row.Quantity || 0,
      editable: true,
      type: "number",
    },
  ];

  const columns =
    type === "acceptance"
      ? [...baseColumns, actionsColumn]
      : [...baseColumns, priceColumn, totalColumn, actionsColumn];

  return (
    <>
      <Box sx={{ margin: "12px 1px" }}>
        <FormsDataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.idPriceList}
          height={400}
          onCellEditStop={(params, event) => {
            params.row.Quantity = event.target.value;
            setNewRows([...rows]);
          }}
        />
      </Box>
      {type !== "acceptance" && (
        <Box display="flex" gap="10px" alignItems="center">
          <ChipComponent
            label={t("modules.acceptance.labels.total_amount")}
            total={totalAmount}
          />
          <ChipComponent
            label={t("modules.acceptance.labels.total_install")}
            total={totalInstall}
          />
          <ChipComponent
            label={t("modules.acceptance.labels.total_supply")}
            total={totalSupply}
          />
        </Box>
      )}
    </>
  );
};
export default ModifyItem;
