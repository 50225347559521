import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../../components/Modals/InfoModal";
import BasicDatePicker from "../../../../components/DateAndTime/DatePicker";
import ButtonGroup from "../../../../components/forms/ButtonGroup/ButtonGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import Axios from "../../../../network/Axios";
import { useSnackbar } from "notistack";

const StatementStatusForm = ({ open, handleClose, clientInvoiceId, state }) => {
  const [t] = useTranslation("global");

  const statementStatuses = {
    TechnicalApproval: {
      title: t("info_modules.client_invoices.buttons.technical_approval"),
      label: t(
        "info_modules.client_invoices.description.technical_approval_date"
      ),
    },
    PriceConfirmation: {
      title: t("info_modules.client_invoices.buttons.price_confirmation"),
      label: t(
        "info_modules.client_invoices.description.price_confirmation_date"
      ),
    },
    Signed: {
      title: t("info_modules.client_invoices.buttons.signed"),
      label: t("info_modules.client_invoices.description.signed_date"),
    },
  };

  return (
    <InfoModal
      title={statementStatuses[state].title}
      open={open}
      handleClose={handleClose}
      content={
        <StatementStatusFormModalContent
          label={statementStatuses[state].label}
          clientInvoiceId={clientInvoiceId}
          state={state}
          handleClose={handleClose}
        />
      }
    />
  );
};

const StatementStatusFormModalContent = ({
  label,
  state, // TechnicalApproval, PriceConfirmation, Signed
  clientInvoiceId,
  handleClose,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [date, setDate] = useState(dayjs());
  const [isDateValid, setIsDateValid] = useState(true);

  const handleStatements = async (data) => {
    const response = await Axios.patch("/ClientInvoices", data);

    return response.data;
  };
  const handleDateChange = (newDate) => {
    setDate(newDate);
    if (newDate && newDate.isValid()) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
  };
  const { mutateAsync: handleStatement, isPending } = useMutation({
    mutationFn: handleStatements,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      handleClose();

      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["clientInvoice", "info", "statementsManagement"].includes(
            query.queryKey[0]
          );
        },
      });
    },
  });

  return (
    <Box sx={{ margin: "10px" }}>
      <BasicDatePicker
        dateChange={date}
        width="27rem"
        handleDateChange={handleDateChange}
        label={label}
      />
      <ButtonGroup
        onClickClose={handleClose}
        isPending={isPending}
        disabled={!isDateValid || isPending}
        onClickSave={() => {
          handleStatement({
            SelectedNo: clientInvoiceId,
            State: state,
            [`${state}Date`]: date.format("YYYY-MM-DD"),
          });
        }}
        saveLabel={t("modules.buttons.save")}
      />
    </Box>
  );
};

export default StatementStatusForm;
