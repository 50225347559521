import {
  groupByInvoiceNo,
  DetailPanelContent,
} from "./StatementsInSignaturePhase";

import { Link, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Axios from "../../../../../network/Axios";
import { useQuery } from "@tanstack/react-query";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import ChipStatus from "../../../../../components/ChipStatus";
import BarChart from "../../../../../components/charts/BarChart";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";

const InvoicesProgress = ({ invoicesPerAmount, invoicesPerCount }) => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [dataNameCustom, setDataNameCustom] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["invoicesProgressChartData", dataNameCustom],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: dataNameCustom,
          State: "InvoicesProgressPerStatus",
        },
      });
      return response.data.InvoicesPerCount || [];
    },
    enabled: open,
  });

  const groupedRows = useMemo(() => groupByInvoiceNo(data), [data]);

  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]); // Return first row of each group
    }

    return groupedRows
      .flatMap((group) => group.rows) // Flatten the grouped rows for filtering
      .filter((invoice) =>
        Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      );
  }, [groupedRows, searchInput]);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows || []} />; // Default to empty array if undefined
    },
    [groupedRows]
  );

  const totalAmount = invoicesPerAmount.reduce(
    (acc, data) => acc + parseFloat(data.TotalAmount.replace(/,/g, "")),
    0
  );

  const totalCount = invoicesPerCount.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const combinedInvoicesByAmount = [
    {
      projectName: "Total",
      Amount: totalAmount,
    },
    ...invoicesPerAmount.map((invoice) => ({
      projectName: invoice.InvoiceStatus,
      Amount: parseFloat(invoice.TotalAmount.replace(/,/g, "")),
    })),
  ];

  const combinedInvoicesByCount = [
    {
      projectName: "Total",
      Count: totalCount,
    },
    ...invoicesPerCount.map((invoice) => ({
      projectName: invoice.InvoiceStatus,
      Count: invoice.Count,
    })),
  ];

  const invoicesByAmountSeries = [
    {
      name: "Amount",
      data: combinedInvoicesByAmount.map((data) => data.Amount),
    },
  ];

  const invoicesByCountSeries = [
    {
      name: "Count",
      data: combinedInvoicesByCount.map((data) => data.Count),
    },
  ];

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/clientinvoices/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 100,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 100,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 120,
      flex: 1,
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 90,
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
      width: 100,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 30,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.invoice_status"),
      headerAlign: "center",
      width: 190,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="invoicesProgressChart"
        chartData={combinedInvoicesByAmount}
        chartData2={combinedInvoicesByCount}
        seriesData={invoicesByAmountSeries}
        seriesData2={invoicesByCountSeries}
        title={t("main_cards.invoices_progress_chart")}
        horizontal
        type="bar"
        xaxisLabelFormatter={(data) => data.projectName}
        switchConfig={{
          leftLabel: t("main_cards.labels.by_count"),
          rightLabel: t("main_cards.labels.by_amount"),
        }}
        open={open}
        handleOpen={() => setOpen(true)}
        handleCloseCustom={() => setOpen(false)}
        dataNameCustom={dataNameCustom}
        setDataNameCustom={setDataNameCustom}
        content={
          <Box p={2}>
            {isLoading || isRefetching ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                rows={filteredInvoices || []}
                columns={columns}
                height={420}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => "auto"}
                getRowId={(row) => row.InvoiceNo}
                toolbar
                searchValueCustom
                customSearchValue={searchInput}
                customSearchOnChange={(e) => setSearchInput(e.target.value)}
              />
            )}
          </Box>
        }
      />
    </CardContainer>
  );
};

export default InvoicesProgress;
