import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Tooltip } from "@mui/material";
import SelectWorkOrder from "./Steps/SelectWorkOrder";
import SelectItems from "./Steps/SelectItems";
import ModifyItem from "./Steps/ModifyItems";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "../../../network/Axios";
import { useSnackbar } from "notistack";
import QuotationDetails from "./Steps/QuotationDetails";
import dayjs from "dayjs";
import Review from "./Steps/Review";
import { useFormik } from "formik";
import * as yup from "yup";
import useHandleForms from "../../../hooks/useHandleForms";
import StepperButton from "../ButtonGroup/StepperButton";
import FormSpinner from "../../Spinners/FormSpinner";
import { generateId } from "../../../utils/globalFunctions";

const AcceptanceQuotationsForm = ({
  onDirtyChange,
  isThreeStepProcess = false,
  workOrderId,
  handleCloseFromAnotherForm,
  d6Type,
  type,
}) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);
  const [D6Types, setD6Types] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newWorkOrder, setNewWorkOrder] = useState([]);
  const [newPriceList, setNewPriceList] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedNo, setSelectedNo] = useState([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [isNextDisabled, setNextDisabled] = useState(false);
  const queryClient = useQueryClient();

  const { data: formData, isLoading } = useQuery({
    queryKey: ["workOrdersQuotations"],
    queryFn: async () => {
      const response = await Axios.get("/WorkOrders", {
        params: {
          State: "Quotations",
        },
      });
      return response.data.result;
    },
  });

  const initialValues = {
    survey: "",
    powerUp: "",
    installation: "",
    projectName: "",
    selectedD6: "",
  };

  const schema = yup.object().shape({
    selectedD6: yup.string().required(t("modules.global_schema.select_value")),
  });

  const workOrders = newWorkOrder.map((order) => ({
    WorkOrderNo: order.WorkOrderNo,
  }));

  const items = newPriceList.map((item) => ({
    idPriceList: item.idPriceList,
    ItemQTY: Number(item.Quantity),
    SI: item.SI,
  }));

  const { data: comoBoxData } = useQuery({
    queryKey: ["quotation", "new"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: { State: "New" },
      });
      return response;
    },
  });

  const { mutateAsync: getItems, isPending: loadingItems } = useMutation({
    mutationFn: async () => {
      return Axios.get("/Quotations", {
        params: {
          SelectedNo: selectedNo,
          State: "PriceListItems",
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setRows(data.data.result);
    },
  });
  const { mutateAsync: getD6Types } = useMutation({
    mutationFn: async (subProjectsName) => {
      return Axios.get("/Quotations", {
        params: {
          State: "WorkOrderD6Types",
          SubProjectsName: subProjectsName,
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setD6Types(data.data.result);
    },
  });
  const workOrderRowById = [
    {
      id: generateId(),
      WorkOrderNo: workOrderId,
    },
  ];
  const { mutateAsync: addNewQuotation, isPending: refetch } = useMutation({
    mutationFn: async () => {
      return Axios.post("/Quotations", {
        WorkOrderD6End: startDate.format("YYYY-MM-DD"),
        WorkOrderD6Start: endDate.format("YYYY-MM-DD"),
        WorkOrderD6TypeName: selectedD6,
        Items: items,
        State: "New",
        WorkOrders: isThreeStepProcess ? workOrderRowById : workOrders,
        Survey: survey,
        PowerUp: powerUp,
        Installation: installation,
        ProjectName: projectName,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      isThreeStepProcess ? handleCloseFromAnotherForm() : handleClose();
      enqueueSnackbar(t("modules.error_messages.added_successfully"), {
        variant: "success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["quotations", "workOrderInfo"].includes(query.queryKey[0]);
        },
      });
    },
  });

  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedData = formData.filter((row) =>
        selectedWorkOrders.includes(row.idWorkOrder)
      );
      setNewWorkOrder(selectedData);
      getD6Types(isThreeStepProcess ? d6Type : selectedData[0].SubProjectsName);
    }

    if (isThreeStepProcess ? activeStep === 0 : activeStep === 1) {
      setNewPriceList(selectedItems);
    }
    if (isThreeStepProcess ? activeStep === 1 : activeStep === 2) {
      if (newPriceList.length === 0) setNextDisabled(true);
      if (
        newPriceList.find(
          (row) => row.Quantity <= 0 || row.Quantity == "" || !row.Quantity
        )
      ) {
        enqueueSnackbar(t("modules.error_messages.zero_or_negative_quantity"), {
          variant: "error",
        });
        return;
      }
    }
    if (isThreeStepProcess ? activeStep === 2 : activeStep === 3) {
      const data = await validateForm();
      if (Object.keys(data).length > 0) return;
    }
    if (isThreeStepProcess ? activeStep === 3 : activeStep === 4) {
      return addNewQuotation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };
  const handleDeleteRow = (id) => {
    setNewPriceList((prevRows) =>
      prevRows.filter((row) => row.idPriceList !== id)
    );
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selectedId) => selectedId.idPriceList !== id)
    );
  };

  let steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
    type === "acceptance"
      ? t("modules.acceptance.steps.quotation_details")
      : t("modules.quotation.steps.quotation_details"),
    t("modules.material_request.steps.review"),
  ];
  if (!isThreeStepProcess) {
    steps = [t("modules.missions.steps.select_work_order"), ...steps];
  }
  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    dirty,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleNext,
    validateOnChange: true,
  });

  const { handleClose } = useHandleForms({ dirty, onDirtyChange });
  const { survey, powerUp, installation, projectName, selectedD6 } = values;

  useEffect(() => {
    if (isRowSelected !== dirty) {
      onDirtyChange(isRowSelected);
    }
  }, [isRowSelected, dirty, onDirtyChange]);

  if (isLoading) {
    return <FormSpinner />;
  }
  const priceListColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Price",
      headerName: t("data_grid.headers.unit_price"),
    },
  ];

  const selectWorkOrderComponent = (
    <SelectWorkOrder
      rows={formData}
      onRowSelection={handleRowSelection}
      selectedRows={selectedWorkOrders}
      setSelectedRows={setSelectedWorkOrders}
      setNextDisabled={setNextDisabled}
    />
  );
  const selectItemsComponent = (
    <SelectItems
      onRowSelection={handleRowSelection}
      selectedRows={selectedItems}
      setSelectedRows={setSelectedItems}
      comboBoxData={comoBoxData}
      getItems={getItems}
      rows={rows}
      isPending={loadingItems}
      setSelectedNo={setSelectedNo}
      selectedNo={selectedNo}
      pricesListColumns={priceListColumns}
      type={type}
    />
  );
  const modifyItemComponent = (
    <ModifyItem
      setNewRows={setNewPriceList}
      rows={newPriceList}
      onClick={handleDeleteRow}
      totalAmount={totalAmount}
      totalInstall={totalInstall}
      totalSupply={totalSupply}
      setTotalAmount={setTotalAmount}
      setTotalInstall={setTotalInstall}
      setTotalSupply={setTotalSupply}
      type={type}
    />
  );
  const quotationDetailsComponent = (
    <QuotationDetails
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      comboBoxData={D6Types}
      errors={errors}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      touched={touched}
      values={values}
      handleSubmit={handleSubmit}
    />
  );

  const reviewComponent = (
    <Review
      workOrderRows={newWorkOrder}
      itemsRows={newPriceList}
      startDate={startDate.format("YYYY/MM/DD")}
      endDate={endDate.format("YYYY/MM/DD")}
      selectedD6={values.selectedD6}
      totalAmount={totalAmount}
      totalInstall={totalInstall}
      totalSupply={totalSupply}
      isThreeStepProcess={isThreeStepProcess}
      type={type}
    />
  );
  const views = isThreeStepProcess
    ? {
        0: selectItemsComponent,
        1: modifyItemComponent,
        2: quotationDetailsComponent,
        3: reviewComponent,
      }
    : {
        0: selectWorkOrderComponent,
        1: selectItemsComponent,
        2: modifyItemComponent,
        3: quotationDetailsComponent,
        4: reviewComponent,
      };
  const isLastStep = isThreeStepProcess ? activeStep === 3 : activeStep === 4;
  const label = isLastStep
    ? t("modules.buttons.save")
    : t("modules.buttons.next");
  return (
    <form onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {views[activeStep]}
      <StepperButton
        isPending={refetch}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={
          !isRowSelected ||
          isNextDisabled ||
          (isLastStep && !newPriceList.length)
        }
        label={label}
        isLastStep={isLastStep}
      />
    </form>
  );
};
export default AcceptanceQuotationsForm;
