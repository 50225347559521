import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Typography } from "@mui/material";
import ChipStatus from "../../../../components/ChipStatus";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import ChipComponent from "../../../../components/Chips/ChipComponent";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ActionButton from "../../../../components/forms/ButtonGroup/ActionButton";
import ActionMenuButton from "../../../../components/forms/ButtonGroup/ActionMenuButton";

const MaterialRequestCard = ({ materialRequestInfo, handleOpenModal }) => {
  const [t] = useTranslation("global");
  const materialRequestProperty = [
    {
      label: t("data_grid.headers.request_no"),
      value: materialRequestInfo?.result[0]?.MaterialsRequestNo,
    },
    {
      label: t("data_grid.headers.order_no"),
      value: materialRequestInfo?.result[0]?.WorkOrderNo,
    },
    {
      label: t("data_grid.headers.requestor"),
      value: materialRequestInfo?.result[0]?.ERPUserNickName,
    },
    {
      label: t("data_grid.headers.status"),
      value: (
        <ChipStatus
          value={materialRequestInfo?.result[0]?.MaterialsRequestStatus}
        />
      ),
    },
    {
      label: t("data_grid.headers.site_name"),
      value: materialRequestInfo?.result[0]?.SiteName,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: materialRequestInfo?.result[0]?.CSiteCode,
    },
    {
      label: t("data_grid.headers.mr_origin"),
      value: materialRequestInfo?.result[0]?.MaterialsRequestOrigin,
    },
  ];

  const actions =
    materialRequestInfo.Actions.filter(
      (action) => action !== "View" && action !== "Delete" && action !== "Edit"
    ) || [];

  const actionsConfig = {
    "Use As Template": {
      label: t("tooltip.use_as_template"),
      icon: (
        <ChangeCircleOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />
      ),
      onClick: () => handleOpenModal("useAsTemplate"),
      color: "primary",
    },
    Request: {
      label: t("info_modules.material_request.buttons.request"),
      icon: <EastIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("confirmationRequest"),
      color: "success",
    },
    "Change Request": {
      label: t("info_modules.material_request.buttons.change_request"),
      icon: <LockOpenOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      onClick: () => handleOpenModal("changeRequest"),
      color: "primary",
    },
    Cancel: {
      label: t("info_modules.material_request.buttons.cancel"),
      icon: <CloseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => handleOpenModal("confirmationCancel"),
    },
    Return: {
      label: t("info_modules.material_request.buttons.return"),
      icon: <DownloadOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: () => handleOpenModal("return"),
    },
    Issue: {
      label: t("info_modules.material_request.buttons.issued"),
      icon: <UploadOutlinedIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: () => handleOpenModal("issued"),
    },
  };
  return (
    <Box>
      {actions.length > 0 && (
        <Box
          display="flex"
          gap="10px"
          justifyContent={
            materialRequestInfo?.result[0].ChangeRequest == 1
              ? "space-between"
              : "flex-end"
          }
          alignItems="center"
          marginBottom="5px"
        >
          {materialRequestInfo?.result[0].ChangeRequest == 1 ? (
            <Typography color="error" fontSize="14px" fontWeight={500}>
              {t(
                "info_modules.material_request.description.change_requested_waiting_approval"
              )}
            </Typography>
          ) : null}
          {actions.length > 1 ? (
            <ActionMenuButton actions={actions} actionsConfig={actionsConfig} />
          ) : (
            <ActionButton
              icon={actionsConfig[actions[0]].icon}
              text={actionsConfig[actions[0]].label}
              onClick={actionsConfig[actions[0]].onClick}
              color={actionsConfig[actions[0]].color}
            />
          )}
        </Box>
      )}
      <Grid container spacing={1}>
        {materialRequestProperty.map((property, index) => (
          <Grid item xs={4} key={index}>
            <ChipComponent label={property.label} value={property.value} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default MaterialRequestCard;
