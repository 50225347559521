import { useTranslation } from "react-i18next";
import BarChart from "../../../../../components/charts/BarChart";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";

const PendingWorkOrders = ({
  workOrdersPendingPerProject,
  workOrdersPendingPerUser,
}) => {
  const { t } = useTranslation("global");

  const totalCountByProject = workOrdersPendingPerProject.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const totalCountByUser = workOrdersPendingPerUser.reduce(
    (acc, data) => acc + data.Count,
    0
  );

  const combinedWorkOrdersPendingPerProject = [
    {
      projectName: "Total",
      Count: totalCountByProject,
    },
    ...workOrdersPendingPerProject.map((project) => ({
      projectName: project.CompanyProjectsName,
      Count: project.Count,
    })),
  ];

  const combinedWorkOrdersPendingPerUser = [
    {
      projectName: "Total",
      Count: totalCountByUser,
    },
    ...workOrdersPendingPerUser.map((user) => ({
      projectName: user.ERPUserNickName,
      Count: user.Count,
    })),
  ];

  const workOrdersPendingPerProjectSeries = [
    {
      name: "Count",
      data: combinedWorkOrdersPendingPerProject.map((data) => data.Count),
    },
  ];

  const workOrdersPendingPerUserSeries = [
    {
      name: "Count",
      data: combinedWorkOrdersPendingPerUser.map((data) => data.Count),
    },
  ];

  const columns = [
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <RouterLink
            style={{
              color: "#1790FF",
            }}
            to={`/workorders/${params.value}`}
            target="_blank"
          >
            {params.value}
          </RouterLink>
        );
      },
    },
    {
      field: "ActionDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      flex: 1,
    },
    {
      field: "ERPUserNickName",
      headerName: t("data_grid.headers.created_by"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 140,
      flex: 1,
    },
    {
      field: "TeamLeadersName",
      headerName: t("data_grid.headers.team_leader"),
      width: 140,
      flex: 1,
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="pendingWorkOrders"
        chartData={combinedWorkOrdersPendingPerProject}
        chartData2={combinedWorkOrdersPendingPerUser}
        seriesData={workOrdersPendingPerProjectSeries}
        seriesData2={workOrdersPendingPerUserSeries}
        title={t("main_cards.pending_work_orders")}
        horizontal
        type="bar"
        xaxisLabelFormatter={(data) => data.projectName}
        switchConfig={{
          leftLabel: t("main_cards.labels.per_user"),
          rightLabel: t("main_cards.labels.per_project"),
        }}
        modalConfig={{
          queryKey: "pendingWorkOrders",
          perUserState: "WorkOrdersPendingPerUserData",
          perProjectState: "WorkOrdersPendingPerProjectData",
          columns,
          dataGridId: "WorkOrderNo",
        }}
      />
    </CardContainer>
  );
};

export default PendingWorkOrders;
