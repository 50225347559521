import { useState } from "react";
import SelectInvoices from "./Steps/SelectInvoices";
import { useMutation, useQuery } from "@tanstack/react-query";
import Axios from "../../../../../network/Axios";
import FormSpinner from "../../../../Spinners/FormSpinner";
import StepperButton from "../../../ButtonGroup/StepperButton";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Review from "./Steps/Review";
import ChipStatus from "../../../../ChipStatus";
import dayjs from "dayjs";
import { clientInvoicesService } from "../../../../../api/clientInvoices";

const SubmitInvoicesForm = ({ handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 120,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 130,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 100,
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 100,
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
      width: 110,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 90,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.status"),
      flex: 1,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [date, setDate] = useState(dayjs());

  // Multi step form to submit invoices
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === 1)
      return submitInvoices({
        SelectedArray: selectedRows.map((row) => ({
          InvoiceNo: row,
        })),
        State: "SubmitArray",
        SubmissionDate: date.format("YYYY-MM-DD"),
      });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const { mutateAsync: submitInvoices, isPending } = useMutation({
    mutationFn: clientInvoicesService.patchClientInvoices,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      handleClose();
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["allInvoicesNeedSubmit"],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          State: "AllInvoicesNeedSubmit",
        },
      });
      return response.data.AllInvoicesNeedSubmit;
    },
  });

  if (isLoading) return <FormSpinner />;

  const views = {
    0: (
      <SelectInvoices
        rows={data}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedData={setSelectedData}
      />
    ),
    1: (
      <Review
        selectedData={selectedData}
        columns={columns}
        formFields={{
          date: { value: date, setValue: setDate },
        }}
      />
    ),
  };

  const steps = [
    t("modules.client_invoice.steps.select_invoices"),
    t("steps.review"),
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "1rem",
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isPending || selectedRows.length === 0}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default SubmitInvoicesForm;
