import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinners/Spinner";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
  Tooltip,
} from "@mui/material";
import ChipComponent from "../../../components/Chips/ChipComponent";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import ChipStatus from "../../../components/ChipStatus";
import { forwardRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import ButtonGroup from "../../../components/forms/ButtonGroup/ButtonGroup";
import { MobileInternetService } from "../../../api/mobileInternetLines";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PauseIcon from "@mui/icons-material/Pause";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";
import InfoModal from "../../../components/Modals/InfoModal";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SelectEmployee from "./Modals/SelectEmployee";
import SimplePopup from "../../../components/PopUp/PopUp";
import PopUpsButtons from "../../../components/forms/ButtonGroup/PopUpsButtons";
import ActionMenuButton from "../../../components/forms/ButtonGroup/ActionMenuButton";
import ActionButton from "../../../components/forms/ButtonGroup/ActionButton";

const MobileInternetLine = () => {
  const theme = useSelector((state) => state.theme.colorTheme);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation("global");

  const { mobileNumber } = useParams();

  const [editMode, setEditMode] = useState(false);

  // turn off editMode when the user clicks on the cancel button
  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  // turn on editMode when the user clicks on the edit button
  const handleOpenEditMode = () => {
    setEditMode(true);
  };

  // Get Line data
  const { data, isLoading } = useQuery({
    queryKey: ["mobileInternetLineInfo", mobileNumber],
    queryFn: () =>
      MobileInternetService.GetMobileInternetLineData(mobileNumber),
  });

  // Get Line data for editing
  const { data: lineEditInfo, refetch } = useQuery({
    queryKey: ["lineEditInfo", mobileNumber],
    queryFn: () =>
      MobileInternetService.GetMobileInternetLineData(mobileNumber, "Edit"),
    enabled: false,
  });

  // Handle suspend line
  const { mutateAsync: updateMobileLineState } = useMutation({
    mutationFn: MobileInternetService.updateMobileLineState,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mobileInternetLineInfo"] });
    },
  });

  // Handle terminate line
  const { mutateAsync: terminateMobileLine } = useMutation({
    mutationFn: MobileInternetService.updateMobileLineState,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mobileInternetLineInfo"] });
    },
  });

  // Handle assigning lien to employee
  const { mutateAsync: assignLineToEmployee, isPending: isAssigningLine } =
    useMutation({
      mutationFn: MobileInternetService.updateMobileLineState,
      onSuccess: (data) => {
        handleCloseAssignEmployeeModal();
        setEmpId([]);
        setEmpName("");
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["mobileInternetLineInfo"] });
      },
    });

  const { setOpen } = useConfirmationDialog();

  // Handle assign employee to line
  const [openAssignEmployeeModal, setOpenAssignEmployeeModal] = useState(false);

  const handleOpenAssignEmployeeModal = () => {
    setOpenAssignEmployeeModal(true);
  };

  const handleCloseAssignEmployeeModal = () => {
    setOpenAssignEmployeeModal(false);
  };

  // Handling employees selection modal
  const [openEmployeesModal, setOpenEmployeesModal] = useState(false);

  const handleOpenEmployeeModal = () => {
    setOpenEmployeesModal(true);
  };

  // employee id
  const [empId, setEmpId] = useState([]);

  const handleEmployeeIdChange = (value) => {
    setEmpId(value);
  };

  const handleCloseEmployeeModal = () => {
    setOpenEmployeesModal(false);
  };

  // employee name
  const [empName, setEmpName] = useState("");

  const handleEmployeeNameChange = (value) => {
    setEmpName(value);
  };

  // Handling date change
  const [startDate, setStartDate] = useState(dayjs());

  if (isLoading) {
    return <Spinner />;
  }
  const actions = data?.Actions.filter((action) => action !== "Edit") || [];
  const actionsConfig = {
    Assign: {
      label: t("info_modules.mobile_internet_line.buttons.assign"),
      icon: <AddCircleIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: handleOpenAssignEmployeeModal,
    },
    Susp: {
      label: t("info_modules.mobile_internet_line.buttons.suspend"),
      icon: <PauseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "warning",
      onClick: () => {
        setOpen(
          t("info_modules.mobile_internet_line.confirmation.suspend"),
          () => () => {
            updateMobileLineState({
              SelectedNo: CompanyPhoneLinesNumber,
              State: "Susp",
            });
          },
          "error"
        );
      },
    },
    Terminate: {
      label: t("info_modules.mobile_internet_line.buttons.terminate"),
      icon: <HighlightOffIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => {
        setOpen(
          t("info_modules.mobile_internet_line.confirmation.terminate"),
          () => () => {
            terminateMobileLine({
              SelectedNo: CompanyPhoneLinesNumber,
              State: "Not available",
            });
          },
          "error"
        );
      },
    },
  };

  const {
    CompanyPhoneLinesNumber,
    CompanyPhoneLinesOperatorName,
    CompanyPhoneLinesPackageName,
    CompanyPhoneLinesStatus,
    CompanyPhoneLinesType,
    EmpCompanyPhoneLinesStartDate,
    EmpName,
    CompanyPhoneLinesMobInternetPackage,
  } = data.result[0];

  const mobileInternetLineDetails = [
    {
      label: t("data_grid.headers.line_number"),
      value: CompanyPhoneLinesNumber,
    },
    {
      label: t("data_grid.headers.type"),
      value: CompanyPhoneLinesType,
    },
    {
      label: t("data_grid.headers.internet_package"),
      value: CompanyPhoneLinesMobInternetPackage,
    },
    {
      label: t("data_grid.headers.operator"),
      value: CompanyPhoneLinesOperatorName,
    },
    {
      label: t("data_grid.headers.mobile_package"),
      value: CompanyPhoneLinesPackageName,
    },
    {
      label: t("data_grid.headers.start_date"),
      value: EmpCompanyPhoneLinesStartDate,
    },
    {
      label: t("data_grid.headers.employee_name_ar"),
      value: EmpName,
    },
    {
      label: t("data_grid.headers.status"),
      value: (
        <Chip color="primary" size="small" label={CompanyPhoneLinesStatus} />
      ),
    },
  ];

  return (
    <>
      {editMode ? (
        <EditForm
          data={lineEditInfo}
          handleCancelEditMode={handleCancelEditMode}
        />
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              editButton={data.Actions.includes("Edit")}
              onClickEdit={() => {
                refetch().then(() => {
                  handleOpenEditMode();
                });
              }}
              title={t("info_modules.mobile_internet_line.description.details")}
              content={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                  }}
                >
                  {actions.length > 0 && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginBottom="4px"
                    >
                      {actions.length > 1 ? (
                        <ActionMenuButton
                          actions={actions}
                          actionsConfig={actionsConfig}
                        />
                      ) : (
                        <ActionButton
                          icon={actionsConfig[actions[0]].icon}
                          text={actionsConfig[actions[0]].label}
                          onClick={actionsConfig[actions[0]].onClick}
                          color={actionsConfig[actions[0]].color}
                        />
                      )}
                    </Box>
                  )}

                  <Grid container spacing={1}>
                    {mobileInternetLineDetails.map((property) => (
                      <Grid item xs={4} key={property.label}>
                        <ChipComponent
                          label={property.label}
                          value={editMode ? property.input : property.value}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }
            />
          </Box>
          <InfoModal
            open={openAssignEmployeeModal}
            handleClose={handleCloseAssignEmployeeModal}
            title={t(
              "info_modules.mobile_internet_line.description.assign_line"
            )}
            content={
              <>
                <Stack spacing={3} p={1} width={400}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <IconButton
                      onClick={handleOpenEmployeeModal}
                      aria-label="Add Employee"
                      sx={{
                        borderRadius: 3,
                        textTransform: "capitalize",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        margin: "4px 2px",
                        ":hover": {
                          backgroundColor:
                            theme === "dark"
                              ? "rgba(34, 85, 153, 0.1)"
                              : "rgba(80, 152, 236, 0.05)",
                        },
                      }}
                    >
                      <AddCircleOutlineIcon
                        color="primary"
                        style={{ fontSize: "18px" }}
                      />
                      <Typography
                        color="primary"
                        fontSize="13px"
                        fontWeight="600"
                      >
                        {t("modules.attendance.add_buttons.add_employee")}
                      </Typography>
                    </IconButton>
                    <Typography>{empName}</Typography>
                  </Stack>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      label={t("data_grid.headers.start_date")}
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      format="YYYY/MM/DD"
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "14px",
                        },
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          label: t("data_grid.headers.start_date"),
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <ButtonGroup
                    onClickClose={() => {
                      setEmpId([]);
                      setEmpName("");
                      handleCloseAssignEmployeeModal();
                    }}
                    onClickSave={() =>
                      assignLineToEmployee({
                        EmpCompanyPhoneLinesStartDate:
                          dayjs(startDate).format("YYYY-MM-DD"),
                        SelectedNo: mobileNumber,
                        State: "Assign",
                        idEmpInfo: empId[0],
                      })
                    }
                    isPending={isAssigningLine}
                    disabled={!empId.length}
                    saveLabel={t("modules.buttons.save")}
                  />
                </Stack>
                <InfoModal
                  open={openEmployeesModal}
                  handleClose={handleCloseEmployeeModal}
                  title={t(
                    "info_modules.mobile_internet_line.description.select_employee"
                  )}
                  content={
                    <SelectEmployee
                      setValue={handleEmployeeIdChange}
                      empId={empId}
                      handleClose={handleCloseEmployeeModal}
                      handleEmployeeNameChange={handleEmployeeNameChange}
                    />
                  }
                />
              </>
            }
          />
        </>
      )}
      <LineHistoryList rows={data.linesHistory} mobileNumber={mobileNumber} />
    </>
  );
};

const LineHistoryList = ({ rows, mobileNumber }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("global");

  const { setOpen } = useConfirmationDialog();

  // Handle start/end data change
  const [empCode, setEmpCode] = useState("");
  const [idEmpCompanyPhoneLines, setIdEmpCompanyPhoneLines] = useState("");

  const [lineHistoryRecordStartDate, setLineHistoryRecordStartDate] =
    useState(null);
  const [lineHistoryRecordEndDate, setLineHistoryRecordEndDate] =
    useState(null);

  // line history state
  const [lineHistoryState, setLineHistoryState] = useState("Edit"); // Edit, Inactive

  // Is user active
  const [isActive, setIsActive] = useState(false);

  // Handle line history record change
  const handleLineHistoryRecordStartDateChange = (value) => {
    setLineHistoryRecordStartDate(value);
  };

  const handleLineHistoryRecordEndDateChange = (value) => {
    setLineHistoryRecordEndDate(value);
  };

  const handleEmpCodeChange = (value) => {
    setEmpCode(value);
  };

  const handleIdEmpCompanyPhoneLines = (value) => {
    setIdEmpCompanyPhoneLines(value);
  };

  const handleLineHistoryState = (value) => {
    setLineHistoryState(value);
  };

  const { mutateAsync: changeStartEndDate, isPending: isChangingStartEndDate } =
    useMutation({
      mutationFn: MobileInternetService.updateMobileLineState,
      onSuccess: (data) => {
        handleClosePopUp();
        enqueueSnackbar(data.msg, { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["mobileInternetLineInfo"] });
      },
    });

  // Handle delete line history record
  const { mutateAsync: deleteLineHistoryRecord } = useMutation({
    mutationFn: MobileInternetService.updateMobileLineState,
    onSuccess: (data) => {
      enqueueSnackbar(data.msg, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ["mobileInternetLineInfo"] });
    },
  });

  // Handling popup
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const handleOpenPopUp = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchor(null);
  };

  const columns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.employee_name_ar"),
      flex: 1,
    },
    {
      field: "EmpNameEn",
      headerName: t("data_grid.headers.employee_name_en"),
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 120,
    },
    {
      field: "EmpCompanyPhoneLinesStartDate",
      headerName: t("data_grid.headers.assignment_start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "EmpCompanyPhoneLinesEndDate",
      headerName: t("data_grid.headers.assignment_end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "EmpCompanyPhoneLinesStatus",
      headerName: t("data_grid.headers.status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      headerName: t("data_grid.headers.actions"),
      type: "actions",
      width: 120,
      getActions: (params) => [
        <Tooltip title={t("tooltip.start_date")} key={params.row.id}>
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={(event) => {
              const {
                EmpCode,
                EmpCompanyPhoneLinesStartDate,
                EmpCompanyPhoneLinesEndDate,
                idEmpCompanyPhoneLines,
              } = params.row;
              setIsActive(params.row.EmpCompanyPhoneLinesStatus === "Active");
              handleLineHistoryState("Edit");
              handleEmpCodeChange(EmpCode);
              handleLineHistoryRecordStartDateChange(
                EmpCompanyPhoneLinesStartDate
              );
              handleLineHistoryRecordEndDateChange(EmpCompanyPhoneLinesEndDate);

              handleIdEmpCompanyPhoneLines(idEmpCompanyPhoneLines);

              handleOpenPopUp(event);
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.end_date")} key={params.row.id}>
          <GridActionsCellItem
            icon={
              <HighlightOffIcon
                color={
                  params.row.EmpCompanyPhoneLinesStatus === "Inactive"
                    ? "secondary"
                    : "error"
                }
              />
            }
            label="Edit"
            disabled={params.row.EmpCompanyPhoneLinesStatus === "Inactive"}
            onClick={(event) => {
              const {
                EmpCode,
                EmpCompanyPhoneLinesStartDate,
                EmpCompanyPhoneLinesEndDate,
                idEmpCompanyPhoneLines,
              } = params.row;
              setIsActive(params.row.EmpCompanyPhoneLinesStatus === "Active");
              handleLineHistoryState("Inactive");
              handleEmpCodeChange(EmpCode);
              handleLineHistoryRecordStartDateChange(
                EmpCompanyPhoneLinesStartDate
              );
              handleLineHistoryRecordEndDateChange(
                EmpCompanyPhoneLinesEndDate || dayjs()
              );

              handleIdEmpCompanyPhoneLines(idEmpCompanyPhoneLines);

              handleOpenPopUp(event);
            }}
          />
        </Tooltip>,
        <Tooltip title={t("tooltip.delete")} key={params.row.id}>
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              setOpen(
                t("info_modules.mobile_internet_line.confirmation.delete"),
                () => () => {
                  deleteLineHistoryRecord({
                    idEmpCompanyPhoneLines: params.row.idEmpCompanyPhoneLines,
                    State: "Delete",
                  });
                },
                "error"
              );
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
        <InfoCard
          title={t(
            "info_modules.mobile_internet_line.description.line_history"
          )}
          content={
            <Box>
              <FormsDataGrid
                height={300}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.idEmpCompanyPhoneLines}
                hideFooter
              />
            </Box>
          }
        />
      </Box>
      <SimplePopup
        id={id}
        open={open}
        anchor={anchor}
        onClose={handleClosePopUp}
        content={
          <Stack spacing={1.5} p={0.4} width={320}>
            <Chip
              size="small"
              label={empCode}
              sx={{ borderRadius: 1, marginBottom: 1.8 }}
            />
            {((!isActive && lineHistoryState === "Inactive") ||
              lineHistoryState === "Edit") && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  label={t("data_grid.headers.start_date")}
                  value={dayjs(lineHistoryRecordStartDate)}
                  onChange={handleLineHistoryRecordStartDateChange}
                  format="YYYY/MM/DD"
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "14px",
                    },
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      label: t("data_grid.headers.start_date"),
                    },
                  }}
                />
              </LocalizationProvider>
            )}
            {((lineHistoryRecordEndDate &&
              (lineHistoryState === "Inactive" ||
                lineHistoryState === "Edit")) ||
              (isActive && lineHistoryState === "Inactive")) && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  label={t("data_grid.headers.end_date")}
                  value={dayjs(lineHistoryRecordEndDate)}
                  onChange={handleLineHistoryRecordEndDateChange}
                  format="YYYY/MM/DD"
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "14px",
                    },
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      label: t("data_grid.headers.end_date"),
                    },
                  }}
                />
              </LocalizationProvider>
            )}
            <PopUpsButtons
              onClickClose={handleClosePopUp}
              onClickSave={() =>
                changeStartEndDate({
                  EmpCompanyPhoneLinesStartDate: dayjs(
                    lineHistoryRecordStartDate
                  ).format("YYYY-MM-DD"),
                  EmpCompanyPhoneLinesEndDate: dayjs(
                    lineHistoryRecordEndDate
                  ).format("YYYY-MM-DD"),

                  SelectedNo: mobileNumber,
                  State: lineHistoryState,
                  idEmpCompanyPhoneLines,
                })
              }
              isPending={isChangingStartEndDate}
              disabled={
                lineHistoryState === "Inactive" && !lineHistoryRecordEndDate
              }
              saveLabel={t("modules.buttons.save")}
            />
          </Stack>
        }
      />
    </>
  );
};

// Forward ref through the styled component
const StyledTextFieldComponent = forwardRef((props, ref) => (
  <TextField
    ref={ref} // Pass the ref down to the TextField
    size="small"
    InputLabelProps={{
      sx: {
        "&.Mui-error:not(.Mui-focused)": {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
    }}
    {...props}
  />
));

// Use styled to create the styled component
export const StyledTextField = styled(StyledTextFieldComponent)(
  ({ disableWidth }) => ({
    ...(disableWidth ? {} : { width: "12.4rem" }),
    "& .MuiInputBase-input": {
      height: "10px",
      fontSize: "0.85rem",
      fontWeight: 600,
    },
  })
);

export const autoCompleteStyles = {
  width: "100%",
  "&:not(.Mui-focused) label.Mui-error": {
    transform: "translate(14px, -9px) scale(0.75)",
    backgroundColor: "background.default",
    padding: "0 6px",
  },
};

const EditForm = ({ data, handleCancelEditMode }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");

  const [companyPhoneLinesOperatorName, setCompanyPhoneLinesOperatorName] =
    useState(data.result[0].CompanyPhoneLinesOperatorName);

  const handleCompanyPhoneLinesOperatorNameChange = (value) => {
    setCompanyPhoneLinesOperatorName(value);
  };

  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t("modules.global_schema.required"))
      .length(
        10,
        t("modules.mobile_internet_lines.new_form.schema.phone_number_length")
      )
      .matches(
        /^(11|12|10)\d{8}$/,
        t("modules.mobile_internet_lines.new_form.schema.valid_phone_number")
      ),

    type: yup.string().required(t("modules.global_schema.required")),
    accountNumber: yup.string().required(t("modules.global_schema.required")),
    operatorName: yup.string().required(t("modules.global_schema.required")),
    package: yup.string().required(t("modules.global_schema.required")),
    mobileInternet: yup
      .string()
      .required(t("modules.global_schema.required"))
      .matches(/^\d+$/, "Mobile internet value must contain numbers only"),
  });

  const {
    CompanyPhoneLinesAccountNo,
    CompanyPhoneLinesMobInternetPackage,
    CompanyPhoneLinesNumber,
    CompanyPhoneLinesOperatorName,
    CompanyPhoneLinesPackageName,
    CompanyPhoneLinesType,
  } = data.result[0];

  const handleFormSubmit = (values) => {
    const {
      phoneNumber,
      type,
      accountNumber,
      package: mobilePackage,
      mobileInternet,
    } = values;

    updateMobileLine({
      CompanyPhoneLinesAccountNo: accountNumber,
      CompanyPhoneLinesMobInternetPackage: mobileInternet,
      CompanyPhoneLinesNumber: phoneNumber,
      CompanyPhoneLinesPackageName: mobilePackage,
      CompanyPhoneLinesType: type,
      SelectedNo: CompanyPhoneLinesNumber,
    });
  };

  const initialValues = {
    phoneNumber: CompanyPhoneLinesNumber,
    type: CompanyPhoneLinesType,
    accountNumber: CompanyPhoneLinesAccountNo,
    operatorName: CompanyPhoneLinesOperatorName,
    package: CompanyPhoneLinesPackageName,
    mobileInternet: CompanyPhoneLinesMobInternetPackage,
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const typeOptions = ["Line", "Susp", "Data", "ETIT", "Unknown"];

  const accountNumberOptions = data?.companyphonelinesaccount.map(
    (item) => item.CompanyPhoneLinesAccountNo
  );

  const operatorNameOptions = data?.CompanyPhoneLinesOperatorName.map(
    (item) => item.CompanyPhoneLinesOperatorName
  );

  const { data: packages } = useQuery({
    queryKey: ["mobileInternetLinesPackages", companyPhoneLinesOperatorName],
    queryFn: () =>
      MobileInternetService.GetPhoneLinesPackages(
        companyPhoneLinesOperatorName
      ),
    enabled: Boolean(companyPhoneLinesOperatorName),
    initialData: data.Packages,
  });

  const lineDetails = [
    {
      label: t("data_grid.headers.line_number"),
      input: (
        <StyledTextField
          name="phoneNumber"
          value={values.phoneNumber}
          onBlur={handleBlur}
          onChange={handleChange("phoneNumber")}
          error={!!touched.phoneNumber && !!errors.phoneNumber}
          label={touched.phoneNumber ? errors.phoneNumber : ""}
          autoFocus
        />
      ),
    },
    {
      label: t("data_grid.headers.type"),
      input: (
        <Autocomplete
          disablePortal
          options={typeOptions || []}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("type", newValue || "");
          }}
          value={typeOptions.find((option) => option === values.type) || null}
          disableClearable
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.type && !!errors.type}
              label={touched.type ? errors.type : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("modules.mobile_internet_lines.new_form.fields.account_number"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          options={accountNumberOptions || []}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("accountNumber", newValue || "");
          }}
          value={
            accountNumberOptions.find(
              (option) => option === values.accountNumber
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.accountNumber && !!errors.accountNumber}
              label={touched.accountNumber ? errors.accountNumber : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("data_grid.headers.operator"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          options={operatorNameOptions || []}
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("operatorName", newValue || "");
            setFieldValue("package", "");
            handleCompanyPhoneLinesOperatorNameChange(newValue);
          }}
          value={
            operatorNameOptions.find(
              (option) => option === values.operatorName
            ) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.operatorName && !!errors.operatorName}
              label={touched.operatorName ? errors.operatorName : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("data_grid.headers.mobile_package"),
      input: (
        <Autocomplete
          disableClearable
          disablePortal
          options={
            packages.result?.map((item) => item.CompanyPhoneLinesPackageName) ||
            []
          }
          getOptionLabel={(option) => option}
          sx={autoCompleteStyles}
          size="small"
          onChange={(event, newValue) => {
            setFieldValue("package", newValue);
          }}
          value={
            packages.result
              ?.map((item) => item.CompanyPhoneLinesPackageName)
              .find((option) => option === values.package) || null
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onBlur={handleBlur}
              error={!!touched.package && !!errors.package}
              label={touched.package ? errors.package : ""}
            />
          )}
        />
      ),
    },
    {
      label: t("data_grid.headers.internet_package"),
      input: (
        <StyledTextField
          size="small"
          name="mobileInternet"
          value={values.mobileInternet}
          onBlur={handleBlur}
          onChange={handleChange("mobileInternet")}
          error={!!touched.mobileInternet && !!errors.mobileInternet}
          label={touched.mobileInternet ? errors.mobileInternet : ""}
        />
      ),
    },
  ];

  const { mutateAsync: updateMobileLine, isPending } = useMutation({
    mutationFn: MobileInternetService.updateMobileLine,
    onSuccess: (data) => {
      if (!data) return;
      handleCancelEditMode();
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["mobileInternetLineInfo"],
      });
      navigate(`/mobile&internetlines/${values.phoneNumber}`, {
        replace: true,
      });
    },
  });

  return (
    <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
      <InfoCard
        title={t("info_modules.mobile_internet_line.description.details")}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} columnSpacing={3}>
                {lineDetails.map((property) => (
                  <Grid
                    item
                    xs={4}
                    key={property.label}
                    sx={{
                      display: "flex",
                      "& > div": {
                        width: "100%",
                      },
                    }}
                  >
                    <ChipComponent
                      label={property.label}
                      value={property.input}
                    />
                  </Grid>
                ))}
              </Grid>
              <ButtonGroup
                saveLabel={t("modules.buttons.save")}
                isPending={isPending}
                onClickClose={handleCancelEditMode}
              />
            </form>
          </Box>
        }
      />
    </Box>
  );
};

export default MobileInternetLine;
