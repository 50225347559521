import {
  groupByInvoiceNo,
  DetailPanelContent,
} from "./StatementsInSignaturePhase";
import dayjs from "dayjs";
import { Link, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import Axios from "../../../../../network/Axios";
import { useQuery } from "@tanstack/react-query";
import CardContainer from "../../../CardContainer";
import { Link as RouterLink } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import ChipStatus from "../../../../../components/ChipStatus";
import BarChart from "../../../../../components/charts/BarChart";
import FormSpinner from "../../../../../components/Spinners/FormSpinner";
import FormsDataGrid from "../../../../../components/DataGrid/FormsDataGrid";

const AllPOs = ({ allPO }) => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [dataNameCustom, setDataNameCustom] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["allOpenPOs", dataNameCustom],
    queryFn: async () => {
      const response = await Axios.get("/UsersManagement", {
        params: {
          SelectedNo: dataNameCustom,
          State: "OpenPOData",
        },
      });
      return response.data.OpenPO || [];
    },
    enabled: open,
  });

  const groupedRows = useMemo(() => groupByInvoiceNo(data), [data]);

  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]); // Return first row of each group
    }

    return groupedRows
      .flatMap((group) => group.rows) // Flatten the grouped rows for filtering
      .filter((invoice) =>
        Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      );
  }, [groupedRows, searchInput]);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows || []} />; // Default to empty array if undefined
    },
    [groupedRows]
  );

  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const combinedOPs = allPO.map((po) => ({
    projectName: po.ClientPONo,
    Count: parseFloat(po.Percent),
  }));

  const series = [
    {
      name: "Percentage",
      data: combinedOPs.map((data) => data.Count),
    },
  ];

  const descriptions = allPO.map((po) => po.ClientPODescription);

  const POAmounts = allPO.map((po) => po.POAmount);

  const ClientPOAmounts = allPO.map((po) => po.ClientPOAmount);

  const options = {
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        colors: {
          ranges: [
            {
              from: 0,
              to: 25,
              color: "#26A0FC", // Red for values <= 25
            },
            {
              from: 26,
              to: 50,
              color: "#ffc107", // Yellow for values <= 50
            },
            {
              from: 51,
              to: 75,
              color: "#ef6c00", // Orange for values <= 75
            },
            {
              from: 76,
              to: 100,
              color: "#388e3c", // Blue for values between 76 and 100
            },
            {
              from: 100.01,
              to: Infinity,
              color: "#f00", // Red for values above 100
            },
          ],
        },
      },
    },
    xaxis: {
      min: 0,
      max: 130,
      categories: allPO.map((po) => po.ClientPONo),
      labels: {
        style: {
          fontSize: "11px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
      },
    },
    markers: {
      size: 6,
      colors: ["red"],
      strokeColor: "#fff",
      strokeWidth: 2,
      offsetY: 0,
      offsetX: 100, // This places the marker at value 100
      markerShape: "circle",
    },
    annotations: {
      xaxis: [
        {
          x: 100, // Marker at 100
          borderColor: "red",
          strokeDashArray: 0, // Make it a solid line
          strokeWidth: 4, // Make the marker bolder
          label: {
            borderColor: "red",
            borderWidth: "2px",
            style: {
              color: "#fff",
              background: "red",
            },
            text: "%100",
            position: "top", // Position the text above the marker
            offsetY: -10, // Offset the label upwards
            orientation: "horizontal", // Ensure the text is horizontal
          },
        },
      ],
    },
    tooltip: {
      theme,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const description = descriptions[dataPointIndex];
        return `<Box style="padding:10px; max-width: 300px; word-wrap: break-word;">
                  <Typography style="font-weight: 600;">${w.globals.labels[dataPointIndex]}</Typography><br />
                  <Typography style="display: block; white-space: pre-wrap; word-wrap: break-word;">${description}</Typography>
                  <Typography><Typography style="font-weight: 600;">Client PO Amount:</Typography> ${ClientPOAmounts[dataPointIndex]}</Typography><br />
                  <Typography>
                  <Typography style="font-weight: 600;">Invoices Amount:</Typography>
                  ${POAmounts[dataPointIndex]}</Typography><br />
                </Box>`;
      },
    },
  };

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={`/clientinvoices/${params.value}`}
            target="_blank"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 110,
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 100,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 90,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      type: "date",
      width: 90,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.invoices_amount"),
      type: "number",
      width: 110,
      flex: 1,
      valueFormatter: ({ value }) => value.toLocaleString(),
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 30,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 80,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.status"),
      width: 190,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <CardContainer>
      <BarChart
        chartName="allOpenPOs"
        chartData={combinedOPs}
        seriesData={series}
        title={t("main_cards.open_po")}
        horizontal
        type="bar"
        xaxisLabelFormatter={(data) => data.projectName}
        chartOptionsConfig={{
          plotOptions: options.plotOptions,
          annotations: options.annotations,
          xaxis: options.xaxis,
          markers: options.markers,
          tooltip: options.tooltip,
          dataLabels: options.dataLabels,
        }}
        open={open}
        handleOpen={() => setOpen(true)}
        handleCloseCustom={() => setOpen(false)}
        dataNameCustom={dataNameCustom}
        setDataNameCustom={setDataNameCustom}
        content={
          <Box p={2}>
            {isLoading || isRefetching ? (
              <FormSpinner />
            ) : (
              <FormsDataGrid
                rows={filteredInvoices || []}
                columns={columns}
                height={420}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => "auto"}
                getRowId={(row) => row.InvoiceNo}
                toolbar
                searchValueCustom
                customSearchValue={searchInput}
                customSearchOnChange={(e) => setSearchInput(e.target.value)}
              />
            )}
          </Box>
        }
        className="all-po-chart"
        modalWidth="73rem"
      />
    </CardContainer>
  );
};

export default AllPOs;
