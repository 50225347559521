import { useTranslation } from "react-i18next";
import FormSpinner from "../../../Spinners/FormSpinner";
import { useQuery } from "@tanstack/react-query";
import { usersManagementService } from "../../../../api/usersManagement";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const DataGroup = ({ setValue, dataGroupSelectionModel, dispatch }) => {
  const [t] = useTranslation("global");

  const columns = [
    {
      field: "idGroups",
      headerName: t("data_grid.headers.emp_code"),
      width: 130,
    },
    {
      field: "GroupsName",
      headerName: t("data_grid.headers.groups_name"),
      width: 120,
      flex: 1,
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["usersManagementFormData"],
    queryFn: usersManagementService.GetUsersManagementFormData,
  });

  const handleRowSelectionModelChange = (newSelectedRows) => {
    // Update selectedRows state with the updatedSelectionModel
    dispatch({ type: "dataGroupSelectionModel", payload: newSelectedRows });

    const selectedRows = newSelectedRows.map((row) => ({
      id: row,
      GroupsName: data?.DataGroups.find((group) => group.idGroups === row)
        .GroupsName,
    }));

    setValue("dataGroup", selectedRows);
  };

  if (isLoading) return <FormSpinner />;

  return (
    <FormsDataGrid
      columnVisibilityModel={{
        idGroups: false,
      }}
      rows={data?.DataGroups || []}
      columns={columns}
      getRowId={(row) => row.idGroups}
      disableColumnMenu
      checkboxSelection
      onRowSelectionModelChange={handleRowSelectionModelChange}
      rowSelectionModel={dataGroupSelectionModel}
      height={400}
    />
  );
};

export default DataGroup;
