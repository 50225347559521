import { Box, Grid } from "@mui/material";
import InfoCard from "../../../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../../../components/Chips/ChipComponent";
import { useState } from "react";
import EditForm from "./EditForm";
import InfoModal from "../../../../../components/Modals/InfoModal";
import ImageModal from "../../../../../components/Modals/ImageModal";
import { convertImage } from "../../../../../utils/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { employeeRecordsService } from "../../../../../api/employeeRecords";

const SocialInsurance = ({ data }) => {
  const { empCode } = useParams();
  const [t] = useTranslation("global");
  const { EmpInfoSocialInsuranceNo, EmpInfoSocialInsuranceStartDate } =
    data || {
      EmpInfoSocialInsuranceNo: "NA",
      EmpInfoSocialInsuranceStartDate: "NA",
    };

  const socialInsuranceDetails = [
    {
      label: t(
        "info_modules.employee_records.cards.social_insurance.fields.insurance_no"
      ),
      value: EmpInfoSocialInsuranceNo,
    },
    {
      label: t(
        "info_modules.employee_records.cards.social_insurance.fields.insurance_start_date"
      ),
      value: EmpInfoSocialInsuranceStartDate,
    },
  ];

  // Handling the edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Handling the image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const { refetch } = useQuery({
    queryKey: ["socialInsuranceImage", empCode],
    queryFn: () =>
      employeeRecordsService.getEmployeeSocialInsuranceImage(empCode),
    enabled: false,
  });

  return (
    <>
      <InfoCard
        viewButton={data}
        onClickView={() => {
          setIsImageLoading(true);
          handleOpenImageModal();
          refetch()
            .then((data) => {
              setImage(
                convertImage(
                  data?.data?.SocialInsurance[0].EmpInfoSocialInsuranceDocument
                    .data
                )
              );
            })
            .then(() => setIsImageLoading(false));
        }}
        editButton
        onClickEdit={handleOpenModal}
        title={t("info_modules.employee_records.cards.social_insurance.title")}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Grid container spacing={1}>
              {socialInsuranceDetails.map(({ label, value }) => (
                <Grid item xs={6} key={label}>
                  <ChipComponent label={label} value={value} />
                </Grid>
              ))}
            </Grid>
          </Box>
        }
      />
      <InfoModal
        open={isModalOpen}
        title={t("info_modules.employee_records.cards.social_insurance.edit")}
        content={<EditForm handleClose={handleCloseModal} />}
        handleClose={handleCloseModal}
        width="40rem"
      />
      <ImageModal
        image={image}
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        title={t("info_modules.employee_records.cards.social_insurance.title")}
        saveLabel={`${empCode.replace("/", "-")}-SocialInsurance`}
        loading={isImageLoading}
      />
    </>
  );
};

export default SocialInsurance;
