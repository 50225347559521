import { useEffect, useState } from "react";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";
import { Autocomplete, Box, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectItem = ({
  onRowSelection,
  selectedRows,
  setSelectedRows,
  comboBoxData,
  getItems,
  rows,
  isPending,
  setSelectedNo,
  selectedNo,
  type,
}) => {
  useEffect(() => {
    onRowSelection(selectedRows);
  }, [selectedRows, onRowSelection]);

  const [t] = useTranslation("global");
  const priceList = comboBoxData?.data?.PriceListRefNo || comboBoxData;
  const [selectionModel, setSelectionModel] = useState([]);

  const baseColumns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
  ];

  const columns =
    type === "acceptance"
      ? baseColumns
      : [
          ...baseColumns,
          {
            field: "Price",
            headerName: t("data_grid.headers.unit_price"),
          },
        ];

  const handleSelectionChange = (value) => {
    setSelectedNo(value?.PriceListRefNo);
    getItems();
  };
  const handleRowSelectionModelChange = (ids) => {
    setSelectionModel(ids);
    const selectedIDs = new Set(ids);
    const accumulatedSelectedRows = [...selectedRows];
    rows.forEach((row) => {
      if (
        selectedIDs.has(row.idPriceList) &&
        !accumulatedSelectedRows.includes(row)
      ) {
        accumulatedSelectedRows.push(row);
      }
    });
    setSelectedRows(accumulatedSelectedRows);
  };

  return (
    <Box sx={{ margin: "12px 1px" }}>
      <Autocomplete
        id="combo-box-demo"
        options={priceList || []}
        getOptionLabel={(option) => option.PriceListRefNo}
        sx={{ width: "100%", margin: "8px 0" }}
        size="small"
        value={
          (priceList || []).find(
            (option) => option.PriceListRefNo === selectedNo
          ) || null
        }
        onChange={(event, value) => handleSelectionChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("modules.acceptance.labels.select_price_list")}
          />
        )}
      />
      <FormsDataGrid
        rows={rows}
        columns={columns}
        loading={isPending}
        getRowId={(row) => row.idPriceList}
        height={380}
        showQuickFilter
        checkboxSelection
        disableSelectionOnClick
        toolbar
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
};
export default SelectItem;
