import { Stack, Typography, Chip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormsDataGrid from "../../../DataGrid/FormsDataGrid";

const Save = ({ values }) => {
  const {
    username,
    nickname,
    email,
    userRole,
    level,
    priceList,
    stores,
    dataGroup,
    mailService,
  } = values;

  const [t] = useTranslation("global");

  const storeNamesColumns = [
    {
      field: "CompanyStoresName",
      headerName: t("data_grid.headers.store_name"),
      flex: 1,
      minWidth: 150,
    },
  ];

  const dataGroupColumns = [
    {
      field: "GroupsName",
      headerName: t("data_grid.headers.groups_name"),
      flex: 1,
      minWidth: 150,
    },
  ];

  const mailServiceColumns = [
    {
      field: "MailServicesName",
      headerName: t("data_grid.headers.mail_services_name"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "BCC",
      headerName: "BCC",
      width: 130,
      renderCell: ({ value }) => {
        return value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
    {
      field: "CC",
      headerName: "CC",
      width: 130,
      renderCell: ({ value }) => {
        return value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
    {
      field: "TO",
      headerName: "TO",
      width: 130,
      renderCell: ({ value }) => {
        return value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
  ];

  return (
    <Stack spacing={2} p={"8px"}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 3,
        }}
      >
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.fields.username")}
          </Typography>
          <Chip label={username} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.fields.display_name")}
          </Typography>
          <Chip label={nickname} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.fields.user_email")}
          </Typography>
          <Chip label={email} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.labels.user_role")}
          </Typography>
          <Chip label={userRole} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.labels.price_list")}
          </Typography>
          <Chip label={priceList} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant={"body1"}>
            {t("modules.users_management.new_form.labels.level")}
          </Typography>
          <Chip label={level} />
        </Stack>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 3,
        }}
      >
        {!(stores.length === 1 && stores[0].id === -1) ? (
          <FormsDataGrid
            height={200}
            rows={stores || []}
            columns={storeNamesColumns}
            getRowId={(row) => row.id}
            hideFooter
            disableColumnMenu
            disableColumnResize
          />
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 2,
            }}
          >
            <Typography>
              {t("modules.users_management.new_form.labels.no_store")}
            </Typography>
          </Box>
        )}
        <FormsDataGrid
          height={200}
          rows={dataGroup || []}
          columns={dataGroupColumns}
          getRowId={(row) => row.id}
          hideFooter
          disableColumnMenu
          disableColumnResize
        />
      </Box>
      <FormsDataGrid
        height={200}
        rows={mailService || []}
        columns={mailServiceColumns}
        getRowId={(row) => row.id}
        hideFooter
        disableColumnMenu
        disableColumnResize
      />
    </Stack>
  );
};

export default Save;
