import { useState } from "react";
import SelectStatement from "./Steps/SelectStatement";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import StepperButton from "../ButtonGroup/StepperButton";
import Save from "./Steps/Save";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { StatementsManagementService } from "../../../api/statementsManagement";
import FormSpinner from "../../Spinners/FormSpinner";
import dayjs from "dayjs";
import Axios from "../../../network/Axios";
import useHandleForms from "../../../hooks/useHandleForms";

const NewStatementForm = ({ type, onDirtyChange }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const [statements, setStatements] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [govInvoiceNo, setGovInvoiceNo] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const { handleClose } = useHandleForms({
    dirty: statements.length > 0 || govInvoiceNo !== "" || date !== dayjs(),
    onDirtyChange,
  });

  const { mutateAsync: addNewStatement, isPending } = useMutation({
    mutationFn: async () => {
      const datesObject = {
        "Technical Approval": "TechnicalApprovalDate",
        "Price Confirmation": "PriceConfirmationDate",
        Signed: "SignedDate",
        Invoice: "InvoiceDate",
      };

      const states = {
        "Technical Approval": "TechnicalApprovalArray",
        "Price Confirmation": "PriceConfirmationArray",
        Signed: "SignedArray",
        Invoice: "InvoiceArray",
      };

      const invoices = statements.map((statement) => {
        return { InvoiceNo: statement };
      });

      return Axios.patch("/ClientInvoices", {
        Invoices: invoices,
        State: states[type],
        [datesObject[type]]: date.format("YYYY-MM-DD"),
        GovernmentInvoice: type === "Invoice" ? govInvoiceNo : undefined,
      });
    },
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.data.msg, {
        variant: "success",
      });
    },
  });

  const handleNext = () => {
    if (activeStep === 1) {
      addNewStatement();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isNextBtnDisabled =
    (activeStep === 0 && statements.length === 0) ||
    (activeStep === 1 && type === "Invoice" && govInvoiceNo === "") ||
    !dayjs(date).isValid();
  const steps = [
    t("modules.statements_management.new_form.steps.select_statements"),
    t("modules.statements_management.new_form.steps.review"),
  ];

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["statementsManagementNewForm", type],
    queryFn: () => StatementsManagementService.getStatementsByState(type),
  });

  if (isLoading || isRefetching) return <FormSpinner />;

  const views = {
    0: (
      <SelectStatement
        rows={data}
        setValue={setStatements}
        statements={statements}
        type={type}
      />
    ),
    1: (
      <Save
        rows={data}
        statements={statements}
        type={type}
        formFields={{
          date: { value: date, setValue: setDate },
          govInvoiceNo: { value: govInvoiceNo, setValue: setGovInvoiceNo },
        }}
      />
    ),
  };

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step
              key={label}
              sx={{
                my: 3,
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {views[activeStep]}
      <StepperButton
        isPending={isPending}
        onClick={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disabled={isNextBtnDisabled}
        label={
          activeStep === steps.length - 1
            ? t("modules.buttons.save")
            : t("modules.buttons.next")
        }
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default NewStatementForm;
