import { useState } from "react";
import CarHistory from "./CarHistory";
import { useSnackbar } from "notistack";
import EditCarInfo from "./EditCarInfo";
import { Box, Grid } from "@mui/material";
import Axios from "../../../network/Axios";
import AssignCar from "./Modals/AssignCar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ChipStatus from "../../../components/ChipStatus";
import Spinner from "../../../components/Spinners/Spinner";
import InfoModal from "../../../components/Modals/InfoModal";
import ImageModal from "../../../components/Modals/ImageModal";
import { CompanyFleetService } from "../../../api/companyFleet";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import ChipComponent from "../../../components/Chips/ChipComponent";
import { combineImages, convertImage } from "../../../utils/utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ActionButton from "../../../components/forms/ButtonGroup/ActionButton";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialogContext";
import ActionMenuButton from "../../../components/forms/ButtonGroup/ActionMenuButton";

const CarInfo = () => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { setOpen } = useConfirmationDialog();
  const { carNumber } = useParams();

  // Get Car data
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["carInfo", carNumber],
    queryFn: () => CompanyFleetService.getCarInfo(carNumber),
  });

  const useFleetMutation = (State, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/CompanyFleet", {
          SelectedNo: carNumber,
          State: State,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["carInfo"],
        });
      },
    });

    return { mutateAsync, isPending };
  };
  const { mutateAsync: handleDeactivate } = useFleetMutation(
    "Deactive",
    "Car has been deactivated successfully"
  );
  const { mutateAsync: handleActivate } = useFleetMutation(
    "Active",
    "Car has been activated successfully"
  );

  // Handle assign employee to a car
  const [openAssignEmployeeModal, setOpenAssignEmployeeModal] = useState(false);

  const handleOpenAssignEmployeeModal = () => {
    setOpenAssignEmployeeModal(true);
  };

  const handleCloseAssignEmployeeModal = () => {
    setOpenAssignEmployeeModal(false);
  };

  const actions =
    data?.Actions.filter(
      (action) =>
        action !== "Edit" && action !== "Delete" && action !== "Unassign"
    ) || [];

  const actionsConfig = {
    Activate: {
      label: t("info_modules.company_fleet.car_information.activate"),
      icon: <CheckIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "success",
      onClick: () => {
        setOpen(
          t("info_modules.company_fleet.car_information.confirmation.activate"),
          () => () => handleActivate(),
          "error"
        );
      },
    },

    Deactivate: {
      label: t("info_modules.company_fleet.car_information.deactivate"),
      icon: <CloseIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "error",
      onClick: () => {
        setOpen(
          t(
            "info_modules.company_fleet.car_information.confirmation.deactivate"
          ),
          () => () => handleDeactivate(),
          "error"
        );
      },
    },
    Assign: {
      label: t("info_modules.company_fleet.car_information.assign"),
      icon: <AddCircleOutlineIcon sx={{ width: "1.1rem", height: "1.1rem" }} />,
      color: "primary",
      onClick: handleOpenAssignEmployeeModal,
    },
  };
  // Handle edit mode
  const [editMode, setEditMode] = useState(false);

  const handleOpenEditMode = () => {
    setEditMode(true);
  };

  const handleCloseEditMode = () => {
    setEditMode(false);
  };

  // Handling the image modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleOpenImageModal = () => setIsImageModalOpen(true);
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  if (isLoading) return <Spinner />;

  const {
    CarsNumber,
    CarsOwnership,
    CarsType,
    CarsModelName,
    CarsModelYear,
    CarsColor,
    CarsMotorNo,
    CarsBodyNo,
    CarsLicenseEndDate,
    CarsInfoCarsNumbersLicenseBack,
    CarsInfoCarsNumbersLicenseFront,
    CarsStatus,
  } = data.result[0];

  const labels = {
    car_number: t(
      "info_modules.company_fleet.car_information.fields.car_number"
    ),
    ownership: t("info_modules.company_fleet.car_information.fields.ownership"),
    car_type: t("info_modules.company_fleet.car_information.fields.car_type"),
    car_model: t("info_modules.company_fleet.car_information.fields.car_model"),
    model_year: t(
      "info_modules.company_fleet.car_information.fields.model_year"
    ),
    color: t("info_modules.company_fleet.car_information.fields.color"),
    motor_number: t(
      "info_modules.company_fleet.car_information.fields.motor_number"
    ),
    body_number: t(
      "info_modules.company_fleet.car_information.fields.body_number"
    ),
    license_end_date: t(
      "info_modules.company_fleet.car_information.fields.license_end_date"
    ),
    car_status: t(
      "info_modules.company_fleet.car_information.fields.car_status"
    ),
    car_start_date: t(
      "info_modules.company_fleet.car_information.fields.car_start_date"
    ),
    car_end_date: t(
      "info_modules.company_fleet.car_information.fields.car_end_date"
    ),
  };

  const carInfoDetails = [
    {
      label: labels.car_number,
      value: CarsNumber,
    },
    {
      label: labels.ownership,
      value: CarsOwnership,
    },
    {
      label: labels.car_type,
      value: CarsType,
    },
    {
      label: labels.car_model,
      value: CarsModelName,
    },
    {
      label: labels.model_year,
      value: CarsModelYear,
    },
    {
      label: labels.color,
      value: CarsColor,
    },
    {
      label: labels.motor_number,
      value: CarsMotorNo,
    },
    {
      label: labels.body_number,
      value: CarsBodyNo,
    },
    {
      label: labels.license_end_date,
      value: CarsLicenseEndDate,
    },
    {
      label: labels.car_status,
      value: <ChipStatus value={CarsStatus} />,
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
        <InfoCard
          viewButton={
            CarsInfoCarsNumbersLicenseFront !== null &&
            CarsInfoCarsNumbersLicenseBack !== null
          }
          onClickView={() => {
            const image1 = convertImage(CarsInfoCarsNumbersLicenseFront.data);

            const image2 = convertImage(CarsInfoCarsNumbersLicenseBack.data);

            const imageOneInstance = new Image();
            const imageTwoInstance = new Image();

            imageOneInstance.src = image1;
            imageTwoInstance.src = image2;

            combineImages(image1, image2)
              .then((b64) => setImage(b64))
              .then(() => handleOpenImageModal());
          }}
          editButton={data.Actions.includes("Edit")}
          onClickEdit={handleOpenEditMode}
          title={t("info_modules.company_fleet.car_information.title")}
          content={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
              }}
            >
              {actions.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginBottom="4px"
                >
                  {actions.length > 1 ? (
                    <ActionMenuButton
                      actions={actions}
                      actionsConfig={actionsConfig}
                    />
                  ) : (
                    <ActionButton
                      icon={actionsConfig[actions[0]].icon}
                      text={actionsConfig[actions[0]].label}
                      onClick={actionsConfig[actions[0]].onClick}
                      color={actionsConfig[actions[0]].color}
                    />
                  )}
                </Box>
              )}
              <Grid container spacing={1}>
                {carInfoDetails.map(({ label, value }) => (
                  <Grid item xs={4} key={label}>
                    <ChipComponent label={label} value={value || "NA"} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          }
        />
      </Box>
      <CarHistory rows={data.CarsHistory} />
      <InfoModal
        open={editMode}
        handleClose={handleCloseEditMode}
        title={t("info_modules.company_fleet.car_information.edit_form.title")}
        content={<EditCarInfo handleClose={handleCloseEditMode} />}
      />
      <ImageModal
        image={image}
        image2
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        title={t(
          "info_modules.company_fleet.car_information.license_images_title"
        )}
        loading={isLoading || isRefetching}
      />

      <InfoModal
        open={openAssignEmployeeModal}
        handleClose={handleCloseAssignEmployeeModal}
        title={t("info_modules.company_fleet.car_history.assign_car.title")}
        content={<AssignCar handleClose={handleCloseAssignEmployeeModal} />}
      />
    </>
  );
};

export default CarInfo;
