import Axios from "../network/Axios";

const AddNewFleetCar = async (data) => {
  const res = await Axios.post("/CompanyFleet", data);

  return res.data;
};

const GetCompanyFleetFormData = async () => {
  const response = await Axios.get("/Mobile&CompanyFleet", {
    params: {
      State: "New",
    },
  });
  return response.data;
};

const GetCompanyFleet = async () => {
  const response = await Axios.get("/CompanyFleet");
  return response.data.result;
};

const getCarInfo = async (carNumber) => {
  const response = await Axios.get("/CompanyFleet", {
    params: {
      State: "CardDetailed",
      SelectedNo: carNumber,
    },
  });
  return response.data;
};

// Get car edit info
const getCarEditInfo = async (carNumber) => {
  const response = await Axios.get("/CompanyFleet", {
    params: {
      State: "Edit",
      SelectedNo: carNumber,
    },
  });
  return response.data.result;
};

const patchCarInfo = async (data) => {
  const response = await Axios.patch("/CompanyFleet", data);
  return response.data;
};

// Update car info
const updateCarInfo = async (data) => {
  const response = await Axios.put("/CompanyFleet", data);
  return response.data;
};

export const CompanyFleetService = {
  GetCompanyFleet,
  GetCompanyFleetFormData,
  AddNewFleetCar,
  getCarInfo,
  patchCarInfo,
  getCarEditInfo,
  updateCarInfo,
};
