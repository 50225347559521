import { Backdrop, Box, Modal } from "@mui/material";
import FormSpinner from "../Spinners/FormSpinner";
import ImageViewer from "../ImageViewer";

const ImageModal = ({ open, handleClose, loading, image, saveLabel }) => {
  return (
    <Modal
      aria-labelledby="image-modal"
      aria-describedby="image-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormSpinner />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box
            sx={{
              width: "80vw",
              height: "90vh",
              zIndex: 999,
              borderRadius: "10px",
              backgroundColor: "background.default",
            }}
          >
            <ImageViewer
              src={image}
              saveLabel={saveLabel}
              handleClose={handleClose}
            />
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default ImageModal;
