import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormsDataGrid from "../DataGrid/FormsDataGrid";
import { Chip, Stack, Typography } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CustomAccordion = ({
  title,
  rows,
  columns,
  expanded,
  onChange,
  getRowId,
  height,
  hideFooter,
  toolbar,
  showExportButton,
  getDetailPanelHeight,
  getDetailPanelContent,
}) => {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="caption">{title}</Typography>
          <Chip label={rows.length} size="small" />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <FormsDataGrid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          height={height}
          hideFooter={hideFooter}
          toolbar={toolbar}
          showExportButton={showExportButton}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
