import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepLabel, Box, Tooltip } from "@mui/material";
import InfoModal from "../../../../components/Modals/InfoModal";
import StepperButton from "../../../../components/forms/ButtonGroup/StepperButton";
import SelectItems from "../../../../components/forms/Acceptance/Steps/SelectItems";
import ModifyItem from "../../../../components/forms/Acceptance/Steps/ModifyItems";
import ConfirmationInfoModal from "../../../../components/Modals/ConfirmationInfoModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import Axios from "../../../../network/Axios";
import { useParams } from "react-router-dom";

const AddItems = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { quotationId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedNo, setSelectedNo] = useState([]);
  const [newPriceList, setNewPriceList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalInstall, setTotalInstall] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { data: priceListData } = useQuery({
    queryKey: ["quotations", "priceList"],
    queryFn: async () => {
      const response = await Axios.get("/Quotations", {
        params: {
          State: "PriceLists",
        },
      });
      return response.data.result;
    },
    enabled: open,
  });
  const { mutateAsync: getItems, isPending: loadingItems } = useMutation({
    mutationFn: async () => {
      return Axios.get("/Quotations", {
        params: {
          SelectedNo: selectedNo,
          State: "PriceListItems",
        },
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      setRows(data.data.result);
    },
  });

  const handleNext = async () => {
    if (activeStep === 0) {
      setNewPriceList(selectedItems);
    }
    if (activeStep === 1) {
      if (
        newPriceList.find(
          (row) => row.Quantity <= 0 || row.Quantity == "" || !row.Quantity
        )
      ) {
        enqueueSnackbar("Quantity should be greater than 0", {
          variant: "error",
        });
        return;
      }
      setConfirmModal(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleRowSelection = (selectedItems) => {
    setIsRowSelected(selectedItems.length > 0);
  };
  const steps = [
    t("modules.material_request.steps.select_item"),
    t("modules.material_request.steps.modify_item"),
  ];
  const handleDeleteRow = (id) => {
    setNewPriceList((prevRows) =>
      prevRows.filter((row) => row.idPriceList !== id)
    );
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selectedId) => selectedId.idPriceList !== id)
    );
  };

  const items = newPriceList.map((item) => ({
    idPriceList: item.idPriceList,
    ItemQTY: Number(item.Quantity),
    SI: item.SI,
  }));
  const handleCloseSave = () => {
    handleClose();
    setActiveStep(0);
    setIsRowSelected(false);
    setSelectedItems([]);
    setSelectedNo([]);
    setRows([]);
  };
  const { mutateAsync: addItems, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.put("/Quotations", {
        Items: items,
        State: "AddWorkOrderD6Items",
        SelectedNo: quotationId,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Added Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["quotation", "info"],
      });
    },
  });
  const columns = [
    {
      field: "PriceListSN",
      headerName: t("data_grid.headers.code"),
    },
    {
      field: "Description",
      headerName: t("data_grid.headers.description"),
      width: 210,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} arrow>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Unit",
      headerName: t("data_grid.headers.unit"),
    },
    {
      field: "SI",
      headerName: t("data_grid.headers.s_i"),
    },
    {
      field: "Price",
      headerName: t("data_grid.headers.unit_price"),
    },
  ];

  const views = {
    0: (
      <SelectItems
        onRowSelection={handleRowSelection}
        selectedRows={selectedItems}
        setSelectedRows={setSelectedItems}
        comboBoxData={priceListData}
        getItems={getItems}
        rows={rows}
        isPending={loadingItems}
        setSelectedNo={setSelectedNo}
        selectedNo={selectedNo}
        pricesListColumns={columns}
      />
    ),
    1: (
      <ModifyItem
        setNewRows={setNewPriceList}
        rows={newPriceList}
        onClick={handleDeleteRow}
        totalAmount={totalAmount}
        totalInstall={totalInstall}
        totalSupply={totalSupply}
        setTotalAmount={setTotalAmount}
        setTotalInstall={setTotalInstall}
        setTotalSupply={setTotalSupply}
      />
    ),
  };

  return (
    <InfoModal
      open={open}
      handleClose={() => {
        handleCloseSave();
      }}
      width="60rem"
      title={t("info_modules.quotation.description.add_items")}
      content={
        <Box margin="10px">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {views[activeStep]}
          <StepperButton
            onClick={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            disabled={!isRowSelected}
            label={
              activeStep === 1
                ? t("modules.buttons.save")
                : t("modules.buttons.next")
            }
            isLastStep={activeStep === 1}
          />
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => {
              setConfirmModal(false);
            }}
            onClick={() => {
              addItems();
            }}
            description={t(
              "info_modules.material_request.confirmation.add_item"
            )}
            isPending={isPending}
            color="error"
          />
        </Box>
      }
    />
  );
};
export default AddItems;
