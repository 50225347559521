import { useState } from "react";
import { Stack, Grid, Chip, TextField, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Axios from "../../../../../../network/Axios";
import { MissionService } from "../../../../../../api/missions";
import ChipStatus from "../../../../../../components/ChipStatus";
import ChipComponent from "../../../../../../components/Chips/ChipComponent";
import CustomAccordion from "../../../../../../components/Accordion/CustomAccordion";
import FormSpinner from "../../../../../../components/Spinners/FormSpinner";
import InfoModal from "../../../../../../components/Modals/InfoModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ButtonGroup from "../../../../../../components/forms/ButtonGroup/ButtonGroup";
import DateFormatGrid from "../../../../../../components/DateFormatGrid";

const AddNumber = ({ open, handleClose, missionNo }) => {
  const [t] = useTranslation("global");
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [documentNo, setDocumentNo] = useState("");

  const handleCloseSave = () => {
    handleClose();
    setDocumentNo("");
  };

  const { mutateAsync: addNumber, isPending } = useMutation({
    mutationFn: async () => {
      return Axios.patch("/Missions", {
        DocumentNo: documentNo,
        SelectedNo: missionNo,
      });
    },
    onSuccess: (data) => {
      if (!data) return;
      handleCloseSave();
      enqueueSnackbar("Document No Edited Successfully", {
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["missionInfo"],
      });
    },
  });
  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("info_modules.work_orders.description.add_document_no")}
      width="30rem"
      content={
        <Box padding={1} display="flex" flexDirection="column">
          <Chip
            label={missionNo}
            sx={{ borderRadius: 1, fontSize: "0.9rem" }}
          />
          <TextField
            label={t("info_modules.work_orders.description.document_no")}
            sx={{ width: "100%", marginTop: "10px" }}
            value={documentNo}
            size="small"
            onChange={(e) => setDocumentNo(e.target.value)}
          />
          <ButtonGroup
            isPending={isPending}
            onClickSave={addNumber}
            onClickClose={handleCloseSave}
            saveLabel={t("modules.buttons.save")}
          />
        </Box>
      }
    />
  );
};

const MissionSnapshot = ({ missionRefNo, documentEdit }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["missionInfo", missionRefNo],
    queryFn: () => MissionService.getMissionInfo(missionRefNo),
  });

  if (isLoading) return <FormSpinner />;

  return <MissionSnapshotData data={data} documentEdit={documentEdit} />;
};

const MissionSnapshotData = ({ data, documentEdit }) => {
  const [t] = useTranslation("global");
  const [addDocumentModal, setAddDocumentModal] = useState(false);

  // WorkOrder data
  const {
    SiteName,
    SiteCode,
    SiteAddress,
    GovernoratesName,
    Latitude,
    Longitude,
    WorkOrderNo,
  } = data.WorkOrders[0];

  // Mission data
  const {
    MissionDescription,
    MissionStart,
    MissionEnd,
    Status,
    MissionsPaperNo,
    ERPUserNickName,
  } = data.result[0];

  const missionInfoList = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: WorkOrderNo,
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={Status} />,
    },
    {
      label: t("data_grid.headers.user"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.document_no"),
      value:
        documentEdit && MissionsPaperNo === null ? (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => setAddDocumentModal(true)}
          >
            Add Number
          </Link>
        ) : (
          MissionsPaperNo
        ),
    },
    {
      label: t("data_grid.headers.start_date"),
      value: MissionStart,
    },
    {
      label: t("data_grid.headers.end_date"),
      value: MissionEnd,
    },
    {
      label: t("data_grid.headers.site_code"),
      value: SiteCode,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: SiteName,
    },
    {
      label: t("data_grid.headers.governorate_name"),
      value: GovernoratesName,
    },
    {
      label: t("data_grid.headers.latitude"),
      value: Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: Longitude,
    },
    {
      label: t("data_grid.headers.site_address"),
      value: SiteAddress,
    },
    {
      label: t("data_grid.headers.description"),
      value: MissionDescription,
    },
  ];

  const employeeColumns = [
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 140,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.emp_name"),
      width: 180,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.emp_title"),
      width: 140,
    },
    {
      field: "MissionEmpStart",
      headerName: t("data_grid.headers.start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionEmpEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];

  const carsColumns = [
    {
      field: "CarsNumber",
      headerName: t("data_grid.headers.cars"),
      width: 120,
    },
    {
      field: "CarsType",
      headerName: t("data_grid.headers.type"),
      width: 120,
    },
    {
      field: "EmpCode",
      headerName: t("data_grid.headers.emp_code"),
      width: 120,
    },
    {
      field: "EmpName",
      headerName: t("data_grid.headers.driver_name"),
      width: 130,
      flex: 1,
    },
    {
      field: "EmpTitle",
      headerName: t("data_grid.headers.title"),
      width: 130,
    },
    {
      field: "MissionCarStart",
      headerName: t("data_grid.headers.start_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
    {
      field: "MissionCarEnd",
      headerName: t("data_grid.headers.end_date"),
      width: 160,
      renderCell: ({ value }) => {
        return <> {value ? <DateFormatGrid value={value} /> : <> </>}</>;
      },
    },
  ];

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Stack spacing={1.2} p={1.2}>
        <Grid container spacing={1}>
          {missionInfoList.map(({ label, value }, index) => (
            <Grid
              item
              xs={index === missionInfoList.length - 1 ? 8 : 4}
              key={label}
            >
              <ChipComponent label={label} value={value} />
            </Grid>
          ))}
        </Grid>

        <CustomAccordion
          title={t("modules.missions.label.team")}
          rows={data.Employees}
          columns={employeeColumns}
          getRowId={(row) => row.idMissionEmp}
          hideFooter
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          height={300}
        />
        <CustomAccordion
          title={t("modules.missions.label.cars")}
          rows={data.Cars}
          columns={carsColumns}
          getRowId={(row) => row.idMissionCars}
          hideFooter
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          height={300}
        />
      </Stack>
      <AddNumber
        open={addDocumentModal}
        handleClose={() => setAddDocumentModal(false)}
        missionNo={data?.result[0].MissionNo}
      />
    </>
  );
};

export default MissionSnapshot;
