import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { statusColors } from "./statuses";
import { Tooltip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import ChipStatus from "../components/ChipStatus";
import Receipt from "@mui/icons-material/Receipt";
import Spinner from "../components/Spinners/Spinner";
import useOpenNewForm from "../hooks/useOpenNewForm";
import { useCallback, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import FormsDataGrid from "../components/DataGrid/FormsDataGrid";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import { StatementsManagementService } from "../api/statementsManagement";
import { useConfirmationDialog } from "../contexts/ConfirmationDialogContext";
import StatementStatusForm from "./InformationPages/ClientInvoices/InfoModals/StatementStatusForms";

const groupByInvoiceNo = (rows) => {
  const grouped = [];

  rows?.forEach((row) => {
    const { InvoiceNo } = row;
    let group = grouped.find((g) => g.invoiceNo === InvoiceNo);

    if (!group) {
      group = {
        invoiceNo: InvoiceNo,
        rows: [],
      };
      grouped.push(group);
    }

    group.rows.push(row);
  });

  return grouped;
};

export function DetailPanelContent({ rows }) {
  const [t] = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme);
  const columns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 140,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 120,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 110,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 130,
    },
    {
      field: "WorkOrderD6TypeName",
      headerName: t("data_grid.headers.d6_type"),
      width: 120,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.acceptance_status"),
      width: 130,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];

  return (
    <Box sx={{ ml: 6.7 }}>
      <FormsDataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.WorkOrderD6No}
        hideFooter
        height="auto"
        backgroundDetails={theme === "dark" ? "#2b3238" : "#54585d"}
        titleDetails="11px"
        cellFontDetails="10.6px"
        headerHeight={24}
      />
    </Box>
  );
}

const StatementsManagement = () => {
  const [t] = useTranslation("global");
  const [searchInput, setSearchInput] = useState("");
  const { newForm } = useSelector((state) => state.theme);

  const statusNames = [
    { name: "Created", displayName: t("data_grid.tabs.created") },
    {
      name: "Technical Approval",
      displayName: t("data_grid.tabs.technical_approval"),
    },
    {
      name: "Price Confirmation",
      displayName: t("data_grid.tabs.price_confirmation"),
    },
    { name: "Signed", displayName: t("data_grid.tabs.signed") },
    { name: "Cancelled", displayName: t("data_grid.tabs.cancelled") },
  ];

  // Handle Selected Invoice or statement
  const [selectedNo, setSelectedNo] = useState(null);

  // Handling Statement Status Form
  const [statementState, setStatementState] = useState("TechnicalApproval");
  const [statementStatusForm, setStatementStatusForm] = useState(false);

  const handleOpenStatementStatusForm = () => setStatementStatusForm(true);
  const handleCloseStatementStatusForm = () => setStatementStatusForm(false);

  // Statement Statuses
  const statementStatuses = {
    Created: {
      title: t("info_modules.client_invoices.buttons.technical_approval"),
      state: "TechnicalApproval",
    },
    "Technical Approval": {
      title: t("info_modules.client_invoices.buttons.price_confirmation"),
      state: "PriceConfirmation",
    },
    "Price Confirmation": {
      title: t("info_modules.client_invoices.buttons.signed"),
      state: "Signed",
    },
  };

  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.statement_no"),
      width: 120,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.governorate_invoice"),
      width: 140,
      flex: 1,
    },
    {
      field: "ClientPONo",
      headerName: t("data_grid.headers.client_po"),
      width: 140,
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      width: 140,
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 140,
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 140,
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 140,
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SignedDate",
      headerName: t("data_grid.headers.signed_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "PriceConfirmationDate",
      headerName: t("data_grid.headers.price_confirmation_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "TechnicalApprovalDate",
      headerName: t("data_grid.headers.technical_approval_date"),
      width: 110,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.invoice_type"),
      width: 95,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.invoice_status"),
      headerAlign: "center",
      width: 135,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "StatementStatus",
      headerName: t("data_grid.headers.statement_status"),
      headerAlign: "center",
      width: 170,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      width: 60,
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip
          title={
            params.row.StatementStatus === "Signed"
              ? ""
              : statementStatuses[params.row.StatementStatus].title
          }
          key="statement"
        >
          <GridActionsCellItem
            disabled={params.row.StatementStatus === "Signed"}
            icon={<Receipt />}
            label="Statement"
            onClick={() => {
              if (params.row.StatementStatus === "Signed") return;
              setSelectedNo(params.row.InvoiceNo);
              setStatementState(
                statementStatuses[params.row.StatementStatus].state
              );
              handleOpenStatementStatusForm();
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const {
    data: rows,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["statementsManagement"],
    queryFn: StatementsManagementService.getStatements,
  });

  const groupedRows = useMemo(() => groupByInvoiceNo(rows), [rows]);

  // Filter invoices by search input across all columns
  const filteredInvoices = useMemo(() => {
    if (!searchInput) {
      return groupedRows.map((group) => group.rows[0]);
    }

    return groupedRows
      .map((group) => group.rows[0])
      .filter((invoice) => {
        // Check if any of the fields contain the search input
        return Object.keys(invoice).some((key) =>
          invoice[key]
            ?.toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
  }, [groupedRows, searchInput]);

  // Define detail panel content for grouped rows
  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const invoiceRows = groupedRows.find(
        (group) => group.invoiceNo === row.InvoiceNo
      )?.rows;
      return <DetailPanelContent rows={invoiceRows} />;
    },
    [groupedRows]
  );

  // Handling new form logic
  const { handleOpen } = useOpenNewForm();
  const { setOpen } = useConfirmationDialog();

  const actions = [
    "Technical Approval",
    "Price Confirmation",
    "Signed",
    "Invoice",
  ];

  const actionsConfig = {
    "Technical Approval": {
      label: t("data_grid.buttons.tech_approval"),
      onClick: () => {
        if (
          newForm === "statements_management_tech_approval" ||
          newForm === null
        ) {
          handleOpen("statements_management_tech_approval");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("statements_management_tech_approval");
          });
        }
      },
    },
    "Price Confirmation": {
      label: t("data_grid.buttons.price_confirmation"),
      onClick: () => {
        if (
          newForm === "statements_management_price_confirmation" ||
          newForm === null
        ) {
          handleOpen("statements_management_price_confirmation");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("statements_management_price_confirmation");
          });
        }
      },
    },
    Signed: {
      label: t("data_grid.buttons.signed"),
      onClick: () => {
        if (newForm === "statements_management_signed" || newForm === null) {
          handleOpen("statements_management_signed");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("statements_management_signed");
          });
        }
      },
    },
    Invoice: {
      label: t("data_grid.buttons.invoice"),
      onClick: () => {
        if (newForm === "statements_management_invoice" || newForm === null) {
          handleOpen("statements_management_invoice");
        } else {
          setOpen(t("modules.new_form_confirmation_message"), () => () => {
            handleOpen("statements_management_invoice");
          });
        }
      },
    },
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <CustomDataGrid
        rows={filteredInvoices}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.InvoiceNo}
        statusColors={statusColors}
        statusNames={statusNames}
        filterField="StatementStatus"
        reloadFunction={refetch}
        showReloadOverlay={isRefetching}
        searchValueCustom
        customSearchValue={searchInput}
        customSearchOnChange={(e) => setSearchInput(e.target.value)}
        builtInSearch={true}
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={getDetailPanelContent}
        initialState={{
          columns: {
            columnVisibilityModel: {
              SubmissionDate: false,
              InvoiceDate: false,
              D6Date: false,
              InvoiceStatus: false,
            },
          },
        }}
        newButtonList={{
          actions,
          actionsConfig,
        }}
      />

      <StatementStatusForm
        open={statementStatusForm}
        handleClose={handleCloseStatementStatusForm}
        state={statementState}
        clientInvoiceId={selectedNo}
      />
    </>
  );
};

export default StatementsManagement;
