import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import InfoModal from "../Modals/InfoModal";
import { useTranslation } from "react-i18next";
import ChartSwitch from "./ChartSwitch";
import "./BarChart.css";
import NoData from "./NoData";
import GenericModal from "../Modals/DashboardModal";

const BarChart = ({
  switchConfig,
  chartData,
  chartData2,
  title,
  horizontal,
  xaxisLabelFormatter,
  seriesData,
  seriesData2,
  type,
  chartName,
  modalConfig,
  className,
  chartOptionsConfig,
  secondView,
  switch2Config,
  modalWidth = "70rem",
  content,
  open,
  handleOpen,
  handleCloseCustom,
  dataNameCustom,
  setDataNameCustom,
}) => {
  const { i18n } = useTranslation("global");
  const theme = useSelector((state) => state.theme.colorTheme); // light | dark

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleSwitchState = () => setIsSwitchChecked(!isSwitchChecked);

  // Second switch state
  const [isSwitchChecked2, setIsSwitchChecked2] = useState(false);

  const handleSwitchState2 = () => setIsSwitchChecked2(!isSwitchChecked2);

  const [currentDataSlice, setCurrentDataSlice] = useState(null);

  let currentSeriesData;
  if (switchConfig) {
    if (switch2Config) {
      if (isSwitchChecked && isSwitchChecked2) {
        currentSeriesData = secondView.seriesData;
      } else if (isSwitchChecked && !isSwitchChecked2) {
        currentSeriesData = seriesData;
      } else if (!isSwitchChecked && isSwitchChecked2) {
        currentSeriesData = secondView.seriesData2;
      } else if (!isSwitchChecked && !isSwitchChecked2) {
        currentSeriesData = seriesData2;
      }
    } else {
      currentSeriesData = isSwitchChecked ? seriesData : seriesData2;
    }
  } else {
    currentSeriesData = seriesData;
  }

  let currentChartData;
  if (switchConfig) {
    if (switch2Config) {
      if (isSwitchChecked && isSwitchChecked2) {
        currentChartData = secondView.chartData;
      } else if (isSwitchChecked && !isSwitchChecked2) {
        currentChartData = chartData;
      } else if (!isSwitchChecked && isSwitchChecked2) {
        currentChartData = secondView.chartData2;
      } else if (!isSwitchChecked && !isSwitchChecked2) {
        currentChartData = chartData2;
      }
    } else {
      currentChartData = isSwitchChecked ? chartData : chartData2;
    }
  } else {
    currentChartData = chartData;
  }

  let options = {
    chart: {
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const projectName =
            currentChartData[config.dataPointIndex]?.projectName || null;

          if (chartName === "allOpenPOs" || config.dataPointIndex !== 0) {
            content
              ? setDataNameCustom(projectName)
              : setCurrentDataSlice(projectName);
          } else {
            content ? setDataNameCustom(null) : setCurrentDataSlice(null);
          }

          content ? handleOpen() : handleOpenModal(chartName);
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      pan: {
        enabled: false,
      },
    },
    colors: Array.from({ length: currentChartData.length }, (_, i) => {
      return i === 0 ? "#fb8500" : "#ffb703";
    }),
    xaxis: {
      categories: currentChartData.map((data) => xaxisLabelFormatter(data)),
      labels: {
        style: {
          fontSize: "11px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
        formatter: (value) => {
          if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + "M"; // Convert to millions
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + "k"; // Convert to thousands
          } else {
            return value; // Keep small numbers as is
          }
        },
      },
    },
    plotOptions: {
      line: {
        columnWidth: "40%",
        borderRadius: 1,
        endingShape: "rounded",
      },
      bar: {
        horizontal,
        columnWidth: "45%",
        barHeight: "25%",
        borderRadius: 1,
        borderRadiusApplication: "end",
        distributed: true,
      },
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      show: false,
      width: 0,
    },

    legend: {
      show: false,
      position: "bottom",
      labels: {
        colors: theme === "dark" ? grey[300] : grey[700],
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "10px",
          colors: theme === "dark" ? grey[300] : grey[700],
        },
      },
    },
    tooltip: {
      theme,
      y: {
        formatter: function (val) {
          const formattedCurrency = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "EGP",
          }).format(val);

          return currentSeriesData[0].name === "Amount"
            ? formattedCurrency
            : val;
        },
      },
    },
  };

  options = chartOptionsConfig
    ? {
        ...options,
        ...chartOptionsConfig,
      }
    : options;

  // Handle modal states
  const [isModalOpen, setIsModalOpen] = useState({
    work_orders_in_progress: false,
    completed_work_without_acceptance: false,
    finalAcceptancesThatNeedCreation: false,
    statementsInSignaturePhase: false,
    pendingWorkOrders: false,
    pendingQuotations: false,
    allOpenPOs: false,
    acceptedWorkNeedInvoicing: false,
    invoicesProgressChart: false,
  });

  const handleOpenModal = (chartName) =>
    setIsModalOpen((prev) => ({ ...prev, [chartName]: true }));

  const handleCloseModal = () =>
    setIsModalOpen({
      work_orders_in_progress: false,
      completed_work_without_acceptance: false,
      finalAcceptancesThatNeedCreation: false,
      statementsInSignaturePhase: false,
      pendingWorkOrders: false,
      pendingQuotations: false,
      allOpenPOs: false,
      acceptedWorkNeedInvoicing: false,
      invoicesProgressChart: false,
    });

  return (
    <>
      <Box
        backgroundColor="background.innerCard"
        component="div"
        dir="ltr"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          dir={i18n.dir()}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "text.primary",
              textDecorationColor: theme === "dark" ? "#8a8a8a" : "#cfcfcf",
            }}
          >
            {title}
          </Typography>
          <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
            {switchConfig && (
              <ChartSwitch
                isSwitchChecked={isSwitchChecked}
                handleSwitchState={handleSwitchState}
                leftLabel={switchConfig.leftLabel}
                rightLabel={switchConfig.rightLabel}
              />
            )}
            {switch2Config && (
              <ChartSwitch
                isSwitchChecked={isSwitchChecked2}
                handleSwitchState={handleSwitchState2}
                leftLabel={switch2Config.leftLabel}
                rightLabel={switch2Config.rightLabel}
              />
            )}
          </Stack>
        </Box>
        {
          // If there is no data, show a message
          currentChartData.length === 0 ||
          (currentChartData.length === 1 &&
            currentChartData[0].projectName === "Total") ? (
            <NoData />
          ) : (
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <ReactApexChart
                key={`${chartName}-${Number(isSwitchChecked)}-${Number(
                  isSwitchChecked2
                )}`}
                options={options}
                series={currentSeriesData}
                type={type}
                height="100%"
                width="100%"
                className={`apex-chart ${className}`}
              />
            </Box>
          )
        }
      </Box>

      <InfoModal
        open={open || isModalOpen[chartName]}
        title={`${title} ${currentDataSlice ? " - " : ""} ${
          currentDataSlice || ""
        }`}
        content={
          content || (
            <GenericModal
              dataName={open ? dataNameCustom : currentDataSlice}
              isSwitchChecked={isSwitchChecked}
              modalConfig={modalConfig}
              chartName={chartName}
            />
          )
        }
        handleClose={open ? handleCloseCustom : handleCloseModal}
        width={modalWidth}
      />
    </>
  );
};

export default BarChart;
